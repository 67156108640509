import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Input,
  InputBase,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { captureException } from '@sentry/react';
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useAlert } from '#root/hooks/use-alert';
import { useConfirm } from '#root/hooks/use-confirm';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { createFakeDelayPromise } from '#root/utils/wait';

export const MassImport: FC<{ onImport: () => void; onClose: () => void }> = ({
  onImport,
  onClose,
}) => {
  const t = useTranslation();
  const importField = useRef<HTMLInputElement>();
  const [importing, setImporting] = useState(false);
  const [deleteOnImport, setDeleteOnImport] = useState(false);
  const alert = useAlert();
  const { isConfirmed } = useConfirm();
  const party = useStoreState(state => state.party.party);

  const doImport = async () => {
    if (!party) return;

    const csvInput = importField.current?.value ?? '';
    if (csvInput.length > 0) {
      if (
        deleteOnImport &&
        !(await isConfirmed(
          Keys.admin.rsvp.import.delete_on_import_confirm,
          Keys.generic.delete,
          'error'
        ))
      ) {
        return;
      }
      setImporting(true);
      // Ensure the loading is shown for at least xx seconds.
      const fakeDelay = createFakeDelayPromise(3000);

      try {
        const response = await getService('contact').import(party.id, csvInput, deleteOnImport);
        await fakeDelay;
        if (response.errors > 0) {
          alert({ title: t(Keys.generic.error), message: t(Keys.admin.rsvp.import.error) });
        } else {
          alert(t(Keys.admin.rsvp.import.success, { count: response.created }));
        }

        setTimeout(() => {
          if (response.missing && importField.current) {
            importField.current.value = response.missing;
          } else {
            onClose();
          }
          onImport();
        }, 1000);
      } catch (error) {
        captureException(error);
        alert({ title: t(Keys.generic.error), message: t(Keys.generic.error_try_again) });
      } finally {
        setImporting(false);
        setDeleteOnImport(false);
      }
    }
  };

  const onSelectFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target?.result && importField.current) {
          importField.current.value = e.target.result as string;
        }
      };

      reader.readAsText(file);
    }
  }, []);
  return (
    <>
      <DialogContent>
        <Flex gap column className={css.spaceTopS}>
          <InputBase
            type="file"
            inputProps={{ accept: 'text/csv' }}
            onChange={onSelectFile}
            className={css.spaceBottomM}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={10}
            inputRef={importField}
            label={t(Keys.admin.rsvp.import.label)}
            helperText={t(Keys.admin.rsvp.import.helper_text)}
            placeholder={t(Keys.admin.rsvp.import.placeholder)}
            fullWidth
          />
        </Flex>
      </DialogContent>
      <DialogActions>
        <Flex gap>
          <FormControlLabel
            labelPlacement="start"
            label={t(Keys.admin.rsvp.import.delete_on_import)}
            control={
              <Checkbox
                checked={deleteOnImport}
                onChange={e => setDeleteOnImport(e.target.checked)}
              />
            }
          />
          <Button
            disableElevation
            variant="contained"
            disabled={importing}
            color="primary"
            onClick={doImport}
          >
            {importing ? <Loading /> : t(Keys.admin.rsvp.import.do_button)}
          </Button>
        </Flex>
      </DialogActions>
    </>
  );
};
