import { Button, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Box } from '#root/Components/Box';
import { useTranslation } from '#root/hooks/use-translation';
import { api } from '#root/utils/api';

export interface PasswordForm {
  currentPassword: string;
  password: string;
  password2: string;
}

export interface IProps {
  withCurrentPassword?: boolean;
  onChange?: () => void;
  onCurrentPasswordRequired?: () => void;
}

export const PasswordForm: FC<IProps> = ({
  withCurrentPassword,
  onChange,
  onCurrentPasswordRequired,
}) => {
  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    reset,
  } = useForm<PasswordForm>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<PasswordForm> = data => {
    if (submitting) return;
    setMessage(undefined);

    setSubmitting(true);

    api
      .post('/api/account/change-password', {
        json: {
          ...data,
        },
      })
      .then(() => {
        reset();
        setMessage('Password succesfully changed');
        onChange?.();
      })
      .catch(async error => {
        if (error.response) {
          const { type, message } = await error.response.json();
          setMessage(message);
          if (type === 'current_password_required' && onCurrentPasswordRequired) {
            onCurrentPasswordRequired();
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const disabled = submitting || !isValid;

  return (
    <Box bordered marginBottom>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>{t(keys => keys.page.account.password.title)}</h2>
        {withCurrentPassword ? (
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            label={t(keys => keys.page.account.password.current_password)}
            inputProps={register('currentPassword', { required: true })}
          />
        ) : null}
        <TextField
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          label={t(keys => keys.page.account.password.new_password)}
          inputProps={register('password', {
            required: true,
            minLength: {
              value: 8,
              message: t(keys => keys.signup.password.error),
            },
          })}
          color={errors.password ? 'error' : 'primary'}
          helperText={
            errors.password ? errors.password.message : t(keys => keys.signup.password.helper)
          }
        />
        <TextField
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          label={t(keys => keys.page.account.password.confirm_new_password)}
          inputProps={register('password2', {
            required: true,
            validate: value => getValues('password') === value,
          })}
        />
        {message ? <p>{message}</p> : null}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          disableElevation
        >
          {t(keys => keys.page.account.password.action)}
        </Button>
      </form>
    </Box>
  );
};
