import { Button, DialogActions, DialogContent } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Flex } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { Link } from '#root/Components/Link';
import { Loading } from '#root/Components/Loading';
import { VerifyPhone } from '#root/Components/VerifyPhone';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IMessageTemplate } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { DoneIcon, SendIcon } from '#root/utils/icons';
import { wait } from '#root/utils/wait';

export const SendTest: FC<{
  template: IMessageTemplate;
  onClose: () => void;
}> = ({ template, onClose }) => {
  const user = useStoreState(state => state.app.currentUser);
  const party = useEditableParty();

  const [isSending, setIsSending] = useState(false);
  const [sendingSuccessfull, setSendingSuccessfull] = useState(false);

  const t = useTranslation();
  const doSend = useCallback(async () => {
    if (isSending) return;
    setIsSending(true);
    // Ensure the loading is shown for at least xx seconds.
    const startTime = performance.now();
    await getService('messages').test(party.id, template.id, template);
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    if (elapsedTime < 3000) {
      await wait(3000 - elapsedTime);
    }
    setIsSending(false);
    setSendingSuccessfull(true);
  }, [party, template, isSending]);

  if (!user?.email_verified) {
    return (
      <>
        <DialogContent>
          <Flex column gap align="center" className={clsx(css.spaceBottomL, css.spaceTopL)}>
            <InfoBox className={css.spaceTopM} type="error">
              {t(Keys.admin.message.test.verify_account)}{' '}
              <Link to="/account">{t(Keys.admin.message.test.account_link)}</Link>
            </InfoBox>
          </Flex>
        </DialogContent>
      </>
    );
  }
  if (isSending) {
    return (
      <>
        <DialogContent>
          <Flex column gap align="center" className={clsx(css.spaceBottomL, css.spaceTopL)}>
            <Loading size="large" />
            {t(Keys.admin.message.test.sending_message)}
          </Flex>
        </DialogContent>
      </>
    );
  }

  if (sendingSuccessfull) {
    return (
      <>
        <DialogContent>
          <Flex column gap align="center" className={clsx(css.spaceBottomL, css.spaceTopL)}>
            <DoneIcon color="primary" fontSize="large" />
            <InfoBox type="success">{t(Keys.admin.message.test.success)}</InfoBox>
          </Flex>
        </DialogContent>

        <DialogActions>
          <Button disableElevation variant="contained" onClick={onClose}>
            {t(Keys.generic.ok)}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <DialogContent>
      <Flex column gap align="center" className={clsx(css.spaceBottomL, css.spaceTopL)}>
        <Flex column>
          {t(Keys.admin.message.test.description)}
          {!user?.phone_verified ? (
            <Flex gap align="center">
              <br />
              {t(Keys.admin.message.test.description_verify_phone)}
              {user.phone?.length ? (
                <ButtonDialog
                  header={t(Keys.page.account.verify_number)}
                  buttonText={t(Keys.page.account.verify_number)}
                  withCloseButton
                  ButtonProps={{ size: 'small', variant: 'outlined', color: 'secondary' }}
                >
                  {onClose => <VerifyPhone phoneNumber={user.phone ?? ''} onClose={onClose} />}
                </ButtonDialog>
              ) : null}
            </Flex>
          ) : null}
        </Flex>
        <Button disableElevation onClick={doSend} variant="contained">
          <Flex gap align="center">
            {t(Keys.admin.message.test.action)} <SendIcon />
          </Flex>
        </Button>
      </Flex>
    </DialogContent>
  );
};
