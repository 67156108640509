import { Feature, IParty } from '#root/store/types';

export const supports = (
  partyOrFeatures: IParty | IParty['features'] | undefined,
  feature: Feature
) => {
  if (!partyOrFeatures) return false;

  const features = Array.isArray(partyOrFeatures) ? partyOrFeatures : partyOrFeatures.features;

  return features.includes(feature) ?? false;
};
