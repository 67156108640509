import { Person } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, List, ListItem } from '@mui/material';
import React, { FC } from 'react';

import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useFetch } from '#root/hooks/use-fetch';
import { useTranslation } from '#root/hooks/use-translation';
import { IParty } from '#root/store/types';
import { css } from '#root/utils/css';

export const MissingRsvps: FC<{ onClose: () => void; partyId: IParty['id'] }> = ({
  partyId,
  onClose,
}) => {
  const t = useTranslation();
  const { data: contacts } = useFetch<{ id: string; name: string; maxPeople: number }[] | null>(
    `/api/party/${partyId}/missing-rsvps`,
    null
  );

  return (
    <>
      <DialogContent>
        {contacts === null ? (
          <Flex column align="center" className={css.spaceTopL}>
            <Loading size="large" />
          </Flex>
        ) : contacts.length ? (
          <List>
            {contacts.map(contact => (
              <ListItem key={contact.id}>
                <Flex gap>
                  <span>{contact.name}</span>
                  {contact.maxPeople && contact.maxPeople > 1 ? (
                    <Item pushRight>
                      <Flex gap>
                        <Person />
                        {contact.maxPeople}
                      </Flex>
                    </Item>
                  ) : null}
                </Flex>
              </ListItem>
            ))}
          </List>
        ) : (
          <Flex column align="center" className={css.spaceTopL}>
            {t(keys => keys.admin.rsvp.no_missing)}
          </Flex>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disableElevation onClick={onClose}>
          {t(keys => keys.generic.close)}
        </Button>
      </DialogActions>
    </>
  );
};
