import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import { RsvpForm } from './RsvpForm';
import { RsvpReplyForm } from './RsvpReplyForm';

import { InfoBox } from '#root/Components/InfoBox';
import { Loading } from '#root/Components/Loading';
import { useIsRsvpActive } from '#root/hooks/use-is-rsvp-active';
import { usePageType } from '#root/hooks/use-page-type';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';

export const Rsvp: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const currentlyLookingContact = useStoreState(state => state.party.currentlyViewingContact);
  const currentlyLookingRsvp = useStoreState(state => state.party.currentlyViewingRsvp);
  const rsvpRestricted = useStoreState(state => state.party.party?.rsvpRestricted);
  const t = useTranslation();
  const rsvpActive = useIsRsvpActive();
  const pageType = usePageType();

  const render = () => {
    if (currentlyLookingRsvp) {
      return <RsvpReplyForm rsvp={currentlyLookingRsvp} />;
    }

    if (
      (currentlyLookingContact === undefined || currentlyLookingRsvp === undefined) &&
      !['party', 'new'].includes(pageType)
    ) {
      return <Loading size="large" />;
    }

    if (rsvpActive) {
      if (!rsvpRestricted || currentlyLookingContact) {
        if (rsvpActive === 'afterSu') {
          return <InfoBox type="warning">{t(keys => keys.party.blocks.rsvp.closed)}</InfoBox>;
        } else {
          return <RsvpForm contact={currentlyLookingContact ?? null} />;
        }
      }
    }
  };

  return <BaseBlock className={className}>{render()}</BaseBlock>;
};
