import MenuIcon from '@mui/icons-material/Menu';
import { Button, DialogContent, List, ListSubheader, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import { ButtonDialog } from '../ButtonDialog';
import { DropdownMenu } from '../DropdownMenu';
import { Flex } from '../Flex';
import { Help } from '../Help';
import { Loading } from '../Loading';
import { Logo } from '../Logo';

import { PartyMenu } from './PartyMenu';
import s from './index.module.scss';

import { PartyActions } from '#root/Entry/inviime/Routes/Party/Components/PartyActions';
import { useIsMobile } from '#root/hooks/use-breakpoint';
import { useLanguage } from '#root/hooks/use-language';
import { useNavigate } from '#root/hooks/use-navigate';
import { usePageType } from '#root/hooks/use-page-type';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { parameters } from '#root/store/parameters';
import { css } from '#root/utils/css';
export interface IProps {
  isLoading?: boolean;
}

export const Menu: FC<IProps> = ({ isLoading }) => {
  const language = useLanguage();
  const user = useStoreState(store => store.app.currentUser);
  const editMode = useStoreState(state => state.party.editMode);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const t = useTranslation();
  const pageType = usePageType();

  const renderMenuItems = (close: (anything?: any) => void) => {
    if (isMobile) {
      return (
        <Flex>
          <PartyMenu onClose={close} />
          <MenuItems withHeader close={close} />
        </Flex>
      );
    } else {
      return <MenuItems close={close} />;
    }
  };

  return (
    <>
      <div className={clsx(s.appBar)}>
        <div className={s.container}>
          <a href={`/${language}/`} className={s.logo}>
            <Logo />
          </a>
          {isLoading ? <Loading size={20} /> : null}
          <div className={s.user}>
            <PartyActions />
            {(!editMode || pageType === 'admin') && (
              <>
                {!isMobile && <PartyMenu asDropdown />}
                {user ? (
                  <DropdownMenu
                    buttonText={
                      <div className={s.menuButton}>
                        <span>{t(keys => keys.menu.title)}</span>
                        <MenuIcon />
                      </div>
                    }
                  >
                    {({ closeDropdown }) => renderMenuItems(closeDropdown)}
                  </DropdownMenu>
                ) : (
                  <>
                    <Button onClick={() => navigate('/login')}>{t(keys => keys.menu.login)}</Button>
                    {!['new', 'wizard'].includes(pageType) ? (
                      <Button
                        disableElevation
                        variant="contained"
                        onClick={() => navigate(`/new/${parameters.defaultCreateType ?? 'party'}`)}
                      >
                        <span className={css.showOnTablet}>
                          {t(keys => keys.menu.create.desktop)}
                        </span>
                        <span className={css.showOnMobile}>
                          {t(keys => keys.menu.create.mobile)}
                        </span>
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const MenuItems: FC<{ withHeader?: boolean; close: (anything?: any) => void }> = ({
  close,
  withHeader,
}) => {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <List
      subheader={
        withHeader ? (
          <ListSubheader className={s.subheader} component="div">
            Menu
          </ListSubheader>
        ) : undefined
      }
    >
      <MenuItem onClick={() => close(navigate(`/new/${parameters.defaultCreateType ?? 'party'}`))}>
        {t(keys => keys.menu.create.desktop)}
      </MenuItem>
      <MenuItem onClick={() => close(navigate('/parties'))}>
        {t(keys => keys.menu.parties)}
      </MenuItem>
      <MenuItem onClick={() => close(navigate('/account'))}>
        {t(keys => keys.menu.account)}
      </MenuItem>

      <ButtonDialog
        withCloseButton
        menuItem={t(keys => keys.menu.help)}
        header={t(keys => keys.menu.help)}
      >
        {() => (
          <DialogContent>
            <Help />
          </DialogContent>
        )}
      </ButtonDialog>
      <MenuItem onClick={() => window.location.replace('/logout')}>
        {t(keys => keys.menu.logout)}
      </MenuItem>
    </List>
  );
};
