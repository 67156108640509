import { Button, ButtonBase, Fab, Select, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { Loading } from '#root/Components/Loading';
import { PartyTypes } from '#root/Components/PartyWizard/Types';
import { useFetch } from '#root/hooks/use-fetch';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';
import { CloseIcon, EditIcon } from '#root/utils/icons';

// The keys of this objects are reffering to the folder in the hero image directory.
const groups = {
  party: Keys.type.party,
  wedding: Keys.type.wedding,
  baptism: Keys.type.baptism,
  confirmation: Keys.type.confirmation,
  birthday: Keys.type.birthday,
  christmas: Keys.type.christmas,
  gradient: Keys.heroImage.group.colors,
} as const;

type GroupType = keyof typeof groups;
type HeroImageResponse = { [group: string]: string[] };

const ImageEditor: FC<{
  value: string | undefined;
  onChange: (image: string | null) => void;
  onClose: () => void;
  defaultCategory: PartyTypes;
}> = ({ value, onChange, onClose, defaultCategory }) => {
  const { data: stockImageGroups } = useFetch<HeroImageResponse | null>(
    '/api/party/hero-images',
    null
  );
  const [selectedGroup, setSelectedGroup] = useState<GroupType>(
    defaultCategory === 'anniversary' ? 'party' : defaultCategory
  );
  useEffect(() => {
    if (value && stockImageGroups) {
      // Find the current category we have chosen an image from.
      for (const group of Object.keys(stockImageGroups)) {
        if (stockImageGroups[group].includes(value)) {
          setSelectedGroup(group as GroupType);
        }
      }
    }
  }, [stockImageGroups]);
  const t = useTranslation();
  const onChangeGroup = useCallback((e: SelectChangeEvent<GroupType>) => {
    setSelectedGroup(e.target.value as GroupType);
  }, []);

  if (null === stockImageGroups) {
    return (
      <div className={s.loaderContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={s.imageEditor}>
      <div className={s.topBar}>
        <div className={s.groups}>
          <Select
            className={s.dropdownChooser}
            native
            variant="outlined"
            value={selectedGroup}
            onChange={onChangeGroup}
          >
            {Object.keys(groups).map((key: GroupType) => (
              <option value={key} key={key}>
                {t(groups[key])}
              </option>
            ))}
          </Select>
          {Object.keys(groups).map((key: GroupType) => (
            <Button
              variant={selectedGroup === key ? 'contained' : 'text'}
              onClick={() => setSelectedGroup(key)}
              className={clsx(s.group, selectedGroup === key && s.currentGroup)}
              key={key}
            >
              {t(groups[key])}
            </Button>
          ))}
          <Button onClick={() => onChange(null)} color="error">
            {t(keys => keys.party.blocks.hero.remove)}
          </Button>
        </div>
        <Fab onClick={onClose} size="small">
          <CloseIcon />
        </Fab>
      </div>
      <div className={s.images}>
        {stockImageGroups[selectedGroup]?.map(image => (
          <ButtonBase
            key={image}
            onClick={() => onChange(image)}
            className={clsx(s.image, image === value && s.currentImage)}
          >
            <img src={image.replace('/hero-images/', '/hero-images/thumbs/')} loading="lazy" />
          </ButtonBase>
        ))}
      </div>
    </div>
  );
};

type ChildrenFunction = (hasHeroImage: boolean) => ReactNode;
export const HeroImage: FC<
  IBaseBlockWrapperProps & { children: ChildrenFunction; clickToEdit?: boolean }
> = ({ className, children, clickToEdit }) => {
  const heroImage = useStoreState(state => state.party.party?.heroImage);
  const type = useStoreState(state => state.party.party?.type ?? 'party');
  const [openEditor, setOpenEditor] = useState(false);

  return (
    <BaseBlock editable={clickToEdit} className={className}>
      {({ editable }) => (
        <div
          className={clsx(s.wrapper, heroImage && s.withHeroImage, editable && s.editable)}
          style={heroImage ? { backgroundImage: `url(${heroImage})` } : undefined}
        >
          {openEditor && editable ? (
            <ImageEditor
              value={heroImage}
              defaultCategory={type}
              onChange={image => {
                getService('party').updateField('heroImage', image);
                setOpenEditor(false);
              }}
              onClose={() => setOpenEditor(false)}
            />
          ) : (
            <>
              {children(!!heroImage || editable)}
              {editable ? (
                <div className={s.editButton}>
                  <Fab onClick={() => setOpenEditor(true)}>
                    <EditIcon />
                  </Fab>
                </div>
              ) : null}
            </>
          )}
        </div>
      )}
    </BaseBlock>
  );
};
