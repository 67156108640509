import { ContentCopy } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import React, { FC } from 'react';

export interface IProps {
  value: string;
  IconButtonProps?: IconButtonProps;
  onClick?: () => void;
}

export const CopyButton: FC<IProps> = ({ value, IconButtonProps, onClick }) => {
  const copy = () => {
    try {
      navigator.clipboard.writeText(value);
    } catch (e) {
      console.log(e);
    }
    onClick?.();
  };
  return (
    <IconButton {...IconButtonProps} onClick={copy} color="primary" size="small">
      <ContentCopy />
    </IconButton>
  );
};
