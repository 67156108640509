import React, { FC } from 'react';

import { NotificationsForm } from './NotificationsForm';
import { PasswordForm } from './PasswordForm';
import { RemoveUser } from './RemoveUser';
import { UserForm } from './UserForm';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Container } from '#root/Components/Container';
import { Flex } from '#root/Components/Flex';
import { useRedirectIfLoggedOut } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export interface AccountType {
  email: string;
  name: string;
}

export const Account: FC = () => {
  useRedirectIfLoggedOut();
  const t = useTranslation();

  return (
    <Container withPadding marginTop>
      <Flex gap stackOnMobile>
        <UserForm />
        <PasswordForm withCurrentPassword />
      </Flex>
      <NotificationsForm />

      <ButtonDialog
        withCloseButton
        buttonText={t(Keys.page.account.remove_user.title)}
        ButtonProps={{ color: 'error' }}
      >
        {onClose => <RemoveUser onClose={onClose} />}
      </ButtonDialog>
    </Container>
  );
};
