import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import Party from './../Party';

import { PartyTypes } from '#root/Components/PartyWizard/Types';
import { useNavigate } from '#root/hooks/use-navigate';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

export const NewParty: FC = () => {
  const { type } = useParams<{ type: PartyTypes }>();
  const wizardDone = useStoreState(state => state.party.party?.id === 'new');
  const navigate = useNavigate();
  const t = useTranslation();

  useBeforeUnload(true, t(Keys.generic.unsaved_changes_loss));

  useEffect(() => {
    return () => {
      getService('party').cancelWizard();
    };
  }, []);

  useEffect(() => {
    if (!wizardDone) {
      navigate(`/new/${type}`);
    }
  }, [wizardDone]);

  return <Party />;
};
