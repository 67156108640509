import clsx from 'clsx';
import React, { FC, MouseEvent, ReactNode } from 'react';

import s from './BaseBlock.module.scss';

import { getService, useStoreState } from '#root/store';

type ChildrenFunction = (arg: { editable: boolean }) => ReactNode;
export interface IBaseBlockWrapperProps {
  className?: string;
}

export interface IProps {
  editable?: boolean;
  children: ReactNode | ChildrenFunction;
  className?: string;
}

const isFunction = (value: ReactNode | ChildrenFunction): value is ChildrenFunction =>
  typeof value === 'function';

export const BaseBlock: FC<IProps> = ({ children, editable, className }) => {
  const userCanEdit = useStoreState(state => state.party.canEdit);
  const editModeIsActive = useStoreState(state => state.party.editMode);

  const renderedChild = isFunction(children) ? children({ editable: editModeIsActive }) : children;
  const onEditClick = (e: MouseEvent) => {
    e.stopPropagation();
    getService('party').toggleEditMode();
  };
  if (!renderedChild) {
    return <></>;
  }

  return (
    <div
      className={clsx(
        className,
        s.baseBlock,
        !editModeIsActive && userCanEdit && editable && s.canEdit
      )}
      onClick={editable && userCanEdit && !editModeIsActive ? onEditClick : undefined}
    >
      {renderedChild}
    </div>
  );
};
