import React, { FC } from 'react';

import { SettingsForm } from './SettingsForm';

import { RedirectIfNotOwner } from '#root/Components/Redirect';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useTranslation } from '#root/hooks/use-translation';

const PartySettings: FC = () => {
  const t = useTranslation();
  usePartyTitle(t(keys => keys.titles.settings));

  return (
    <RedirectIfNotOwner>
      <SettingsForm />
    </RedirectIfNotOwner>
  );
};

export default PartySettings;
