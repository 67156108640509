import { Tooltip } from '@mui/material';
import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';

import s from './SmsField.module.scss';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';
import { emojiRegex } from '#root/utils/string';

export interface IProps {
  onChange: (value: string) => void;
  onBlur: () => void;
  defaultValue: string;
  suffix?: string | ReactNode;
}

export const SmsField: React.FC<IProps> = ({ onChange, defaultValue, suffix, onBlur }) => {
  const t = useTranslation();
  const [string, setString] = useState<string>(defaultValue);
  const changed = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    const safeValue = e.target.value.replace(emojiRegex, '');
    setString(safeValue);
    onChange(safeValue);
  }, []);

  return (
    <div className={s.wrapper} data-content={string}>
      <textarea onChange={changed} value={string} onBlur={onBlur} />
      {suffix ? (
        <Tooltip placement="top" title={t(Keys.admin.message.text_link_tooltip)}>
          <span className={s.suffix}>{suffix}</span>
        </Tooltip>
      ) : null}
    </div>
  );
};
