import { IconButton } from '@mui/material';
import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { AddressAutocomplete } from '#root/Components/AddressAutocomplete';
import { Flex } from '#root/Components/Flex';
import { LinkA } from '#root/Components/Link';
import { Map } from '#root/Components/Map';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { css } from '#root/utils/css';
import { CloseIcon } from '#root/utils/icons';

export const Location: FC<IBaseBlockWrapperProps & { mapClassName?: string }> = ({
  className,
  mapClassName,
}) => {
  const location = useStoreState(state => state.party.party?.location);
  const language = useStoreState(state => state.party.party?.language);
  const t = useTranslation();
  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <>
            {location ? (
              <Map location={location} className={mapClassName} />
            ) : (
              <p>{t(keys => keys.party.blocks.map.description)}</p>
            )}
            <Flex gap align="center" className={css.spaceTopL}>
              <AddressAutocomplete
                inputProps={{ fullWidth: true, label: t(keys => keys.party.blocks.map.label) }}
                language={language}
                defaultValue={location?.address}
                onChange={location => getService('party').updateField('location', location)}
              />
              {location ? (
                <IconButton
                  color="error"
                  title={t(keys => keys.party.blocks.map.remove)}
                  onClick={() => getService('party').updateField('location', null)}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Flex>
          </>
        ) : location ? (
          <>
            <Map location={location} className={mapClassName} />
            <div className={s.location}>
              <span>
                {t(keys => keys.party.blocks.map.label)}: {location.address}
              </span>
              <LinkA
                target="_blank"
                href={`https://www.google.com/maps/dir/Current+Location/${location.address.replace(
                  ' ',
                  '+'
                )}`}
              >
                {t(keys => keys.party.blocks.map.route)}
              </LinkA>
            </div>
          </>
        ) : null
      }
    </BaseBlock>
  );
};
