import React, { FC } from 'react';

import { Container } from '#root/Components/Container';
import { PartyPlanChooser } from '#root/Components/PartyPlanChooser';
import { RedirectIfNotOwner } from '#root/Components/Redirect';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export const PlanChooser: FC = () => {
  const t = useTranslation();
  usePartyTitle(t(Keys.chooser.title));

  return (
    <RedirectIfNotOwner>
      <Container>
        <PartyPlanChooser />
      </Container>
    </RedirectIfNotOwner>
  );
};
