import { Button, ButtonProps, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from './use-translation';

import { Keys } from '#root/translations-keys';

export const ConfirmContext = createContext<Dispatch<SetStateAction<IConfirm>>>(undefined);

interface IConfirm {
  messageKey: string;
  confirmKey?: string;
  color: ButtonProps['color'];
  isOpen: boolean;
  accept: (value: unknown) => void;
  reject: (reason?: any) => void;
}

const initialState: IConfirm = {
  messageKey: '',
  confirmKey: undefined,
  color: 'primary',
  isOpen: false,
  accept: undefined,
  reject: undefined,
};

export const ConfirmProvider: FC<PropsWithChildren> = ({ children }) => {
  const t = useTranslation();

  const [confirm, setConfirm] = useState<IConfirm>(initialState);

  return (
    <ConfirmContext.Provider value={setConfirm}>
      {children}

      {confirm.isOpen && (
        <Dialog open={true}>
          <DialogContent>
            <p dangerouslySetInnerHTML={{ __html: t(confirm.messageKey) }}></p>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" disableElevation onClick={() => confirm.reject()}>
              {t(Keys.generic.cancel)}
            </Button>
            <Button
              variant="contained"
              disableElevation
              color={confirm.color}
              onClick={() => confirm.accept('')}
            >
              {t(confirm.confirmKey ?? Keys.generic.ok)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const setConfirm = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const reset = useCallback(() => {
    setConfirm(initialState);
    setNeedsCleanup(false);
  }, [initialState]);

  const isConfirmed = useCallback(
    (messageKey: string, confirmKey?: string, color: ButtonProps['color'] = 'primary') => {
      return new Promise((resolve, reject) => {
        setConfirm({ messageKey, confirmKey, color, isOpen: true, accept: resolve, reject });
        setNeedsCleanup(true);
      })
        .then(
          () => true,
          () => false
        )
        .finally(reset);
    },
    [reset]
  );

  useEffect(() => {
    return () => {
      if (confirm.reject && needsCleanup) {
        confirm.reject();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    isConfirmed,
  };
};
