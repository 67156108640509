import React, { FC } from 'react';

import { PasswordForm } from './PasswordForm';

import { Container } from '#root/Components/Container';
import { useNavigate } from '#root/hooks/use-navigate';
import { useRedirectIfLoggedOut } from '#root/hooks/use-redirect-if-logged-in';
import { useStoreState } from '#root/store';

export const ChangePassword: FC = () => {
  useRedirectIfLoggedOut();
  const user = useStoreState(state => state.app.currentUser);
  const navigate = useNavigate();

  const onChange = () => navigate('/account');

  if (!user) {
    return <></>;
  }

  return (
    <Container withPadding marginTop>
      <PasswordForm onChange={onChange} onCurrentPasswordRequired={onChange} />
    </Container>
  );
};
