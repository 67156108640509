import { Button, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import s from './UrlField.module.scss';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useDebounce } from '#root/hooks/use-debounce';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { ISettingsCheckedSlugResult } from '#root/store/types';
import { CloseIcon, DoneIcon } from '#root/utils/icons';

export interface IProp {
  sid: string;
  slug?: string;
  onChange: (slug: string | undefined) => void;
}

export const UrlField: FC<IProp> = ({ sid, slug, onChange }) => {
  const t = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [editable, setEditable] = useState(false);
  const [changedUrlValue, setChangedUrlValue] = useState<string>(slug ?? '');
  useEffect(() => setChangedUrlValue(slug ?? ''), [slug]);

  const [isChecking, setIsChecking] = useState<boolean>(false);
  const [checkedResult, setCheckedResult] = useState<ISettingsCheckedSlugResult>();

  const acceptFreeSlug = useCallback(() => {
    if (checkedResult) {
      onChange(changedUrlValue);
    }
  }, [checkedResult]);

  const cancel = () => {
    setChangedUrlValue(slug ?? '');
    setEditable(false);
    setCheckedResult(undefined);
  };

  const debouncedCheckUrlFunc = useDebounce(async () => {
    console.log(`Checking slug (${changedUrlValue})`);
    if (changedUrlValue && changedUrlValue.length > 3 && slug !== changedUrlValue) {
      setIsChecking(true);
      try {
        const checkResult = await getService('settings').checkSlug(changedUrlValue);

        setIsChecking(false);
        setCheckedResult(checkResult);
      } catch (error) {
        console.error(error);
        setIsChecking(false);
        setCheckedResult(false);
      }
    }
  }, 1000);

  const onSlugChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      setChangedUrlValue(e.target.value.toLowerCase().replace(/[^a-zA-Z0-9-_]/g, ''));
      setCheckedResult(undefined);
      debouncedCheckUrlFunc();
    },
    [debouncedCheckUrlFunc]
  );

  const onEdit = useCallback(() => {
    setEditable(true);
  }, []);

  useEffect(() => {
    if (editable) {
      ref.current?.focus();
    }
  }, [editable]);

  useEffect(() => {
    setEditable(false);
    setCheckedResult(undefined);
    setChangedUrlValue(slug ?? '');
  }, [slug]);

  return (
    <>
      <Flex gap align="center">
        <Tooltip
          title={!editable ? t(keys => keys.admin.settings.custom_url.click_change) : ''}
          placement={'bottom'}
        >
          <span className={s.container}>
            {!editable ? <div onClick={onEdit} className={s.editableLayer}></div> : null}
            <TextField
              label={t(keys => keys.admin.settings.custom_url.label)}
              name="url"
              inputRef={ref}
              InputProps={{
                startAdornment: <InputAdornment position="start">invii.me/</InputAdornment>,
                endAdornment:
                  editable && slug && checkedResult === undefined ? (
                    <InputAdornment position="start">
                      <IconButton onClick={() => onChange('')}>
                        <CloseIcon color="error" />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
              }}
              placeholder={sid}
              onChange={onSlugChange}
              value={changedUrlValue}
              error={checkedResult === false}
              color="primary"
              helperText={
                checkedResult === false ? t(keys => keys.admin.settings.custom_url.error) : null
              }
              disabled={!editable}
            />
          </span>
        </Tooltip>
        {isChecking ? (
          <Loading />
        ) : checkedResult === true ? (
          <>
            <Button disableElevation variant="contained" onClick={acceptFreeSlug}>
              <Flex gap>
                <DoneIcon color="inherit" />
                <span>{t(keys => keys.generic.save)}</span>
              </Flex>
            </Button>
            <IconButton onClick={cancel}>
              <CloseIcon color="error" />
            </IconButton>
          </>
        ) : null}
      </Flex>
    </>
  );
};
