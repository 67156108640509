import clsx from 'clsx';
import React, {
  FC,
  KeyboardEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { usePrevious } from 'react-use';

import s from './index.module.scss';

import { CaretDownIcon } from '#root/utils/icons';

export interface IAccordionProps {
  preTitle?: ReactNode;
  title: ReactNode;
  disabled?: boolean;
  className?: string;
  compact?: boolean;
  unmountOnClosed?: boolean;
  defaultExpanded?: boolean;
  expandOnEnabled?: boolean;
}

export const Accordion: FC<PropsWithChildren<IAccordionProps>> = ({
  preTitle,
  title,
  children,
  disabled,
  className,
  compact,
  unmountOnClosed,
  defaultExpanded,
  expandOnEnabled,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [isExpanding, setIsExpanding] = useState(false);
  const [isPreCollapsing, setIsPreCollapsing] = useState(false);
  const [isCollapsing, setIsCollapsing] = useState(false);
  const prevDisabled = usePrevious(disabled);
  const toggle = (val?: boolean) => {
    setExpanded(prev => {
      const next = typeof val === 'undefined' ? !prev : val;
      if (next === true) {
        setIsExpanding(true);
        setTimeout(() => setIsExpanding(false), 400);
      } else {
        setIsPreCollapsing(true);
      }
      return next;
    });
  };
  useEffect(() => {
    if (disabled && expanded) {
      toggle(false);
    }
  }, [disabled, expanded]);

  useEffect(() => {
    if (!disabled && prevDisabled && expandOnEnabled) {
      toggle(true);
    }
  }, [disabled]);

  useEffect(() => {
    if (isPreCollapsing) {
      setTimeout(() => {
        setIsPreCollapsing(false);
        setIsCollapsing(true);
        setTimeout(() => setIsCollapsing(false), 400);
      }, 100);
    }
  }, [isPreCollapsing]);

  useEffect(() => {
    if (defaultExpanded) {
      setTimeout(toggle, 100);
    }
  }, []);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    if (!disabled && (e.code === 'Enter' || e.code === 'Space')) {
      toggle();
      e.preventDefault();
    }
  };

  const height = () => {
    if (isExpanding) {
      return expanded ? contentRef.current?.scrollHeight + 'px' : '10px';
    }

    if (isPreCollapsing) {
      return contentRef.current?.scrollHeight + 'px';
    }

    return expanded ? 'auto' : '0px';
  };

  return (
    <div
      className={clsx(
        s.root,
        disabled && s.disabled,
        compact && s.compact,
        expanded ? s.expanded : s.collapsed,
        isCollapsing && s.isCollapsing,
        isPreCollapsing && s.isPreCollapsing,
        className
      )}
    >
      <div className={s.button}>
        {preTitle ? <div className={s.preTitle}>{preTitle}</div> : null}
        <div
          className={s.title}
          role="button"
          tabIndex={0}
          onKeyDown={onKeyDown}
          onClick={() => !disabled && toggle()}
        >
          {title}
          <div className={s.caret}>
            <CaretDownIcon />
          </div>
        </div>
      </div>
      <div
        className={s.contentWrapper}
        style={{
          height: height(),
        }}
        {...{ 'aria-hidden': !expanded }}
      >
        <div className={s.content} ref={contentRef}>
          {(expanded || !unmountOnClosed) && children}
        </div>
      </div>
    </div>
  );
};
