import { ButtonBase, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isAfter, parseISO } from 'date-fns';
import React, { FC, useState } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import { DateTimeView } from '#root/Components/DateTimeView';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { CloseIcon } from '#root/utils/icons';
import { isMobile } from '#root/utils/is-mobile';

const Editor: FC<{ date: string; onChange: (newDate: Date) => void }> = ({ date, onChange }) => {
  const t = useTranslation();
  const [open, setOpen] = useState(false);

  const canChangeDate = isAfter(parseISO(date), new Date());

  return (
    <>
      <ButtonBase disableRipple onClick={() => setOpen(true)}>
        <DateTimeView date={date} />
      </ButtonBase>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="dialog-title"
        fullWidth
        fullScreen={isMobile()}
      >
        <DialogTitle id="dialog-title">
          {t(keys => keys.party.blocks.date.title)}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!canChangeDate ? (
            <p>{t(keys => keys.party.blocks.date.notice)}</p>
          ) : (
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={getService('translation').getLocale()}
            >
              <p>{t(keys => keys.party.blocks.date.notice_deletion)}</p>
              <StaticDateTimePicker
                ampm={false}
                value={date ? parseISO(date) : null}
                disablePast
                slotProps={{ actionBar: { actions: ['accept'] } }}
                minDate={new Date()}
                onAccept={date => {
                  if (date && !isNaN(date.getTime())) {
                    onChange(date);
                    setOpen(false);
                  }
                }}
              />
            </LocalizationProvider>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const DateTime: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const date = useStoreState(state => state.party.party?.date);

  if (!date) {
    return null;
  }

  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <Editor
            date={date}
            onChange={date => getService('party').updateField('date', date.toISOString())}
          />
        ) : (
          <DateTimeView date={date} />
        )
      }
    </BaseBlock>
  );
};
