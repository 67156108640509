import { Groups2 } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import s from './styles.module.scss';

import { Container } from '#root/Components/Container';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useNavigate } from '#root/hooks/use-navigate';
import { useParty } from '#root/hooks/use-party';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useRedirectIfOwner } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

export const Cohost: FC = () => {
  useRedirectIfOwner();
  const t = useTranslation();
  usePartyTitle(t(Keys.chooser.title));
  const { id, secret } = useParams<{ idOrLang: string; id: string; secret: string }>();
  const party = useParty();
  const user = useStoreState(state => state.app.currentUser);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const acceptCohostInvitation = useCallback(async () => {
    setSubmitting(true);
    // Need to be logged in in order to accept a cohost invite
    if (party && id && secret) {
      if (!user) {
        if (!(await getService('app').promptLogin())) {
          alert(t(Keys.admin.settings.cohosts.needs_login));
          setSubmitting(false);
          return;
        }
      }
      try {
        await getService('settings').acceptCohost(party.id, id, secret);
        navigate(`/${party.sid}`, true);
      } catch (e) {
        alert(t(Keys.admin.settings.cohosts.error_token));
        navigate(`/${party.sid}`, true);
      }
    }
    setSubmitting(false);
  }, [user]);

  return (
    <Container small marginTop>
      <Flex gap column align="center">
        <span className={s.icon}>
          <Groups2 fontSize="inherit" color="inherit" />
        </span>
        <p className={s.text}>
          {t(Keys.admin.settings.cohosts.confirm_description, { title: party.title })}
        </p>
        <Button
          disableElevation
          onClick={acceptCohostInvitation}
          disabled={submitting}
          variant="contained"
        >
          {submitting ? <Loading /> : t(Keys.admin.settings.cohosts.confirm)}
        </Button>
      </Flex>
    </Container>
  );
};
