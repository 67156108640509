import { createBreakpoint } from 'react-use';

const breakpoints = {
  small: 0,
  medium: 600,
  large: 900,
  xlarge: 1200,
  xxlarge: 1536,
} as const;

export const useBreakpoint = createBreakpoint(breakpoints) as () => keyof typeof breakpoints;

export const useIsBreakpoint = (breakpoint: keyof typeof breakpoints) => {
  const currentBreakpoint = useBreakpoint();

  return breakpoint === currentBreakpoint;
};
export const useIsBreakpoints = (breakpointsToTest: Array<keyof typeof breakpoints>) => {
  const currentBreakpoint = useBreakpoint();

  return breakpointsToTest.includes(currentBreakpoint);
};

export const useIsMobile = () => {
  return useIsBreakpoint('small');
};
export const useIsTablet = () => {
  return useIsBreakpoint('medium');
};
export const useIsDesktop = () => {
  const breakpoint = useBreakpoint();

  return ['large', 'xlarge'].includes(breakpoint);
};
export const useIsDesktopLarge = () => {
  return useIsBreakpoint('xlarge');
};
