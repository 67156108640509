import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import React, { FC, useCallback, useEffect } from 'react';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { Pager } from '#root/Components/Pager';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IContact } from '#root/store/types';
import { CloseIcon, MailIcon, PhoneIcon } from '#root/utils/icons';

const ContactSelect: FC<{ contact: IContact }> = ({ contact }) => {
  const t = useTranslation();
  const isChecked = useStoreState(state => !!state.messages.manualSelection[contact.id]);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        getService('messages').check(contact);
      } else {
        getService('messages').uncheck(contact.id);
      }
    },
    [contact]
  );

  useEffect(() => {
    getService('messages').clearManualSelection();
  }, []);

  const isDisabled = !contact.receiveEmail && !contact.receiveSms;
  return (
    <Flex align="center" justify="space-between">
      <FormControlLabel
        control={<Checkbox disabled={isDisabled} checked={isChecked} onChange={onChange} />}
        label={contact.name}
      />
      <Flex>
        {contact.receiveEmail ? (
          <Tooltip title={t(keys => keys.admin.selection.receives_email)} placement="left">
            <MailIcon fontSize="small" color="primary" />
          </Tooltip>
        ) : null}
        {contact.receiveSms ? (
          <Tooltip title={t(keys => keys.admin.selection.receives_text)} placement="left">
            <PhoneIcon fontSize="small" color="primary" />
          </Tooltip>
        ) : null}
        {!contact.receiveEmail && !contact.receiveSms ? (
          <Tooltip title={t(keys => keys.admin.selection.missing_method)} placement="left">
            <CloseIcon fontSize="small" color="error" />
          </Tooltip>
        ) : null}
      </Flex>
    </Flex>
  );
};

export const ManualSelection: FC = () => {
  const party = useEditableParty();

  const t = useTranslation();
  return (
    <>
      <Pager
        actionPlacement="bottom"
        url={`/api/party/${party.id}/contacts`}
        idField="id"
        rowRenderer={(item: IContact) => <ContactSelect contact={item} />}
        defaultSort={['name', 'asc']}
        noResultsMessage={<p>{t(keys => keys.admin.contacts.empty)}</p>}
        loadingMessage={
          <Flex column align="center">
            <Loading size="large" />
          </Flex>
        }
      />
    </>
  );
};
