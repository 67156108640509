import { Backdrop } from '@mui/material';
import React, { FC } from 'react';

import { Loading } from '../Loading';

import { useStoreState } from '#root/store';

export const GlobalLoading: FC<{ className?: string }> = ({ className }) => {
  const isLoading = useStoreState(state => state.party.globalLoading);

  return (
    <Backdrop className={className} open={isLoading}>
      <Loading size="large" />
    </Backdrop>
  );
};
