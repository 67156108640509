import { Button, IconButton, Tooltip } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { usePageType } from '#root/hooks/use-page-type';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';
import { EditIcon, SaveIcon } from '#root/utils/icons';

export const PartyActions: FC = () => {
  const editMode = useStoreState(state => state.party.editMode);
  const t = useTranslation();
  const pageType = usePageType();
  const [isSaving, setIsSaving] = useState(false);

  const onSave = useCallback(async () => {
    setIsSaving(true);
    try {
      await getService('party').save();
    } catch (e) {
      // no-op
    }
    setIsSaving(false);
  }, []);

  if (!['new', 'party'].includes(pageType)) {
    return <></>;
  }

  if (editMode) {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onSave}
          disabled={isSaving}
          size="small"
        >
          {isSaving ? (
            <Loading />
          ) : (
            <Flex gap>
              <SaveIcon />
              {t(keys => keys.generic.save)}
            </Flex>
          )}
        </Button>
        {pageType !== 'new' ? (
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="small"
            onClick={() => getService('party').cancelUpdates()}
          >
            {t(keys => keys.generic.cancel)}
          </Button>
        ) : null}
      </>
    );
  }

  return (
    <>
      <Tooltip title={t(Keys.generic.edit)}>
        <IconButton color="secondary" onClick={() => getService('party').toggleEditMode()}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
};
