import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Flex } from '../Flex';
import { InfoBox } from '../InfoBox';
import { Loading } from '../Loading';

import { useLanguage } from '#root/hooks/use-language';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IHelpEntry } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { createFakeDelayPromise } from '#root/utils/wait';

type Data = IHelpEntry & { zip: string };

export const Help: FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const language = useLanguage();
  const [submitting, setSubmitting] = useState(false);
  const user = useStoreState(state => state.app.currentUser);
  const parties = useStoreState(state => state.app.parties);
  useEffect(() => {
    if (user) {
      getService('app').loadParties();
    }
  }, [user]);
  const currentPartyId = useStoreState(state => state.party.party?.id);
  const t = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<Data>({
    defaultValues: {
      userId: user?.id,
      userEmail: user?.email,
      partyId: currentPartyId ?? '',
      zip: '',
    },
  });

  const onSubmit: SubmitHandler<Data> = useCallback(
    data => {
      const { zip, ...submittedData } = data;
      if (zip.length) return;
      if (submitting || !isDirty) return;
      setSubmitting(true);
      const fakeDelay = createFakeDelayPromise(1500);
      getService('app')
        .submitHelpEntry(submittedData, language)
        .then(async () => {
          await fakeDelay;
          setIsSubmitted(true);
          reset();
        })
        .catch(async error => {
          if (error.response) {
            const body = await error.response.json();
            if (body.errors) {
              for (const [field, message] of Object.entries<string>(body.errors)) {
                setError(field as any, { type: 'custom', message });
              }
            } else if (body.message) {
              alert(body.message);
            }
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [reset, submitting, isDirty, language]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap column className={css.spaceM}>
        {isSubmitted ? (
          <InfoBox type="success">{t(Keys.help.success)}</InfoBox>
        ) : (
          <>
            <p>{t(Keys.help.description)}</p>
            <TextField
              variant="outlined"
              fullWidth
              label={t(Keys.generic.email)}
              error={!!errors.userEmail}
              helperText={errors.userEmail?.message}
              inputProps={register('userEmail', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t(keys => keys.generic.email_error),
                },
              })}
            />
            {parties && Object.keys(parties ?? {}).length ? (
              <Controller
                control={control}
                name="partyId"
                render={({ field }) => (
                  <FormControl size="small">
                    <InputLabel>{t(Keys.help.party)}</InputLabel>
                    <Select size="small" label={t(Keys.help.party)} {...field}>
                      <MenuItem value="">{t(Keys.help.party_no_choice)}</MenuItem>
                      {Object.keys(parties).map(partySid => (
                        <MenuItem key={parties[partySid].id} value={parties[partySid].id}>
                          {parties[partySid].title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            ) : null}
            <TextField
              variant="outlined"
              fullWidth
              multiline
              minRows={5}
              label={t(Keys.help.comment)}
              inputProps={register('entry', {
                required: true,
              })}
            />
            <TextField
              inputProps={register('zip')}
              style={{ visibility: 'hidden', height: 0 }}
              fullWidth={true}
              autoComplete="off"
              tabIndex={-1}
            />
            <Button
              disabled={!isValid || submitting}
              type="submit"
              disableElevation
              variant="contained"
            >
              {submitting ? <Loading /> : t(Keys.generic.submit)}
            </Button>
          </>
        )}
      </Flex>
    </form>
  );
};
