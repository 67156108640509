import { useCallback } from 'react';

import { useFetch } from './use-fetch';

const array_move = (arr: unknown[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};
export const useDataList = <T>(
  path: string,
  defaultData: T[] = [],
  extraParams: { [key: string]: boolean | string | number } = {}
) => {
  const { setData, ...rest } = useFetch<T[]>(path, defaultData, extraParams);

  const append = useCallback((item: T) => {
    setData(prev => [...prev, item]);
  }, []);
  const prepend = useCallback((item: T) => {
    setData(prev => [item, ...prev]);
  }, []);
  const remove = useCallback((item: T) => {
    setData(prev => prev.filter(i => i !== item));
  }, []);
  const move = useCallback((from: number, to: number) => {
    setData(data => {
      const newData = data.slice();
      array_move(newData, from, to);
      return [...newData];
    });
  }, []);

  return {
    set: setData,
    append,
    prepend,
    remove,
    move,
    ...rest,
  };
};
