import React, { FC, useEffect } from 'react';

import { parameters } from '#root/store/parameters';

export const PaymentWindow: FC = () => {
  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage({
        source: 'payment-window',
        payment: parameters.payment ?? false,
      });
      setTimeout(() => {
        window.close();
      }, 1000);
    }
  });
  // some text to show the user
  return <p>Please wait...</p>;
};
