import { BookmarkAdded } from '@mui/icons-material';
import { MenuItem, MenuList, Tooltip } from '@mui/material';
import React, { FC, useCallback } from 'react';

import s from './Reserve.module.scss';

import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Item } from '#root/Components/Flex';
import { LoadingIconButton } from '#root/Components/LoadingButton';
import { useIsMobile } from '#root/hooks/use-breakpoint';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IWish } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { ThreeDotsIcon } from '#root/utils/icons';

export const Reserve: FC<{ item: IWish; onChange: (wish: IWish) => void; isOwner: boolean }> = ({
  item,
  onChange,
  isOwner,
}) => {
  const t = useTranslation();
  const user = useStoreState(state => state.app.currentUser);
  const reservationsEnabled = useStoreState(
    state => state.party.party?.wishlistReservationsEnabled
  );
  const isMobile = useIsMobile();

  const onReserve = useCallback(async () => {
    if (!user) {
      const loggedInAs = await getService('app').promptLogin();
      if (!loggedInAs) {
        return;
      }
    }
    return getService('wishlist')
      .reserve(item.id)
      .then(updatedWish => {
        onChange(updatedWish);
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          alert(body.message);
        }
      });
  }, [user, onChange, Item]);

  const onUnreserve = useCallback(() => {
    return getService('wishlist')
      .unreserve(item.id)
      .then(updatedWish => {
        onChange(updatedWish);
      });
  }, [onChange, item]);

  if (!reservationsEnabled) {
    return <></>;
  }

  if (item.reservedBy) {
    if (item.reservedBy.id === user?.id) {
      return (
        <Tooltip title={t(Keys.party.wishlist.remove_reservation)}>
          <LoadingIconButton size="small" onClick={onUnreserve} color="primary">
            <BookmarkAdded fontSize="small" />
          </LoadingIconButton>
        </Tooltip>
      );
    }

    if (!isOwner) {
      return (
        <Tooltip title={t(Keys.party.wishlist.reserved_by_tooltip)}>
          <span className={s.reservedBy}>{t(Keys.party.wishlist.reserved_by)}</span>
        </Tooltip>
      );
    }
  }

  if (isOwner) return <></>;

  if (isMobile) {
    return (
      <DropdownMenu icon={<ThreeDotsIcon fontSize="small" />} IconButtonProps={{ size: 'small' }}>
        {() => (
          <MenuList>
            <MenuItem onClick={onReserve}>
              {user ? t(Keys.party.wishlist.reserve) : t(Keys.party.wishlist.login_and_reserve)}
            </MenuItem>
          </MenuList>
        )}
      </DropdownMenu>
    );
  }

  return (
    <Tooltip
      title={user ? t(Keys.party.wishlist.reserve) : t(Keys.party.wishlist.login_and_reserve)}
    >
      <LoadingIconButton size="small" onClick={onReserve} className="__show_on_hover">
        <BookmarkAdded fontSize="small" />
      </LoadingIconButton>
    </Tooltip>
  );
};
