import { Button } from '@mui/material';
import React, { FC, useState } from 'react';

import { ThemeProvider } from './ThemeProvider';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { Logo } from '#root/Components/Logo';
import { parameters } from '#root/store/parameters';
import { api } from '#root/utils/api';

export const UnsubscribeHandler: FC = () => {
  const isAlreadyUnsubscribed = parameters.isUnsubscribed;
  const hash = parameters.hash;
  const signature = parameters.signature;
  const [isUnsubscribed, setIsUnsubscribed] = useState(isAlreadyUnsubscribed);
  const [loading, setLoading] = useState(false);
  const onUnsubscribe = async () => {
    setLoading(true);
    await api.get(`/api/unsubscribe/${hash}/${signature}`);
    setLoading(false);
    setIsUnsubscribed(true);
  };
  const onDeunsubscribe = async () => {
    setLoading(true);
    await api.get(`/api/deunsubscribe/${hash}/${signature}`);
    setLoading(false);
    setIsUnsubscribed(false);
  };

  return (
    <ThemeProvider>
      <Flex align="center" justify="center" column style={{ height: '100vh' }} gap>
        <Logo />
        <p>
          Unsubscribe from any future invitations from invii.me. You will not be able to receive any
          invitations from our system again.
        </p>
        {!isUnsubscribed ? (
          <Button disableElevation variant="contained" onClick={onUnsubscribe} disabled={loading}>
            {loading ? <Loading /> : 'Unsubscribe'}
          </Button>
        ) : (
          <Button disableElevation variant="contained" onClick={onDeunsubscribe} disabled={loading}>
            {loading ? <Loading /> : 'Receive invitations again'}
          </Button>
        )}
        <a href="/">invii.me</a>
      </Flex>
    </ThemeProvider>
  );
};
