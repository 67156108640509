import { ButtonBase, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import s from './index.module.scss';

import { CaretDownIcon, CaretUpIcon } from '#root/utils/icons';

export interface IProps {
  infinit?: boolean;
  maxValue?: number;
  compact?: boolean;
  className?: string;
  onChange?: (value: number | undefined) => void;
  value?: number;
  label?: string | ReactNode;
  name?: string;
}

const INFINITY_CHAR = '∞';

export const BigNumberField: FC<IProps> = ({
  name,
  label,
  value: outerValue,
  onChange,
  className,
  compact,
  infinit,
  maxValue,
  ...rest
}) => {
  const minValue = infinit ? Infinity : 1;
  const ensureMinValue = (v: number) => (v < 1 ? minValue : v);
  const ensureMaxValue = (v: number) => (maxValue && !isNaN(maxValue) ? Math.min(v, maxValue) : v);
  const [value, setValue] = useState<number | undefined>(outerValue || minValue);

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (onChange && typeof prevValue !== 'undefined' && prevValue !== value) {
      onChange(value);
    }
  }, [prevValue, value, name]);
  useEffect(() => setValue(prev => (prev ? ensureMaxValue(prev) : prev)), [maxValue]);
  const onDirectChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    if (e.target.value === '') {
      // Be able to delete content.
      setValue(undefined);
      return;
    }
    const newVal = Number(e.target.value);
    if (!isNaN(newVal)) {
      setValue(ensureMinValue(ensureMaxValue(newVal)));
    }
  }, []);
  const onUp = useCallback(
    () =>
      setValue(prev => {
        const prevButNotInfinity = !prev || prev === Infinity ? 0 : prev;
        return ensureMaxValue(prevButNotInfinity + 1);
      }),
    []
  );
  const onDown = useCallback(() => setValue(prev => ensureMinValue((prev || 0) - 1)), []);
  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.key === 'ArrowUp') onUp();
      if (e.key === 'ArrowDown') onDown();
    },
    [onDown, onUp]
  );

  return (
    <>
      <div className={clsx(s.root, className, compact && s.compact, !!label && s.withLabel)}>
        <TextField
          type="text"
          label={label}
          value={value === Infinity ? INFINITY_CHAR : value ?? ''}
          onChange={onDirectChange}
          onKeyDown={onKeyPress}
          name={name}
          {...rest}
        />
        <div className={s.buttons}>
          <ButtonBase tabIndex={-1} className={s.up} onClick={onUp} title="Forøg med 1">
            <CaretUpIcon />
          </ButtonBase>
          <ButtonBase tabIndex={-1} className={s.down} onClick={onDown} title="Formindsk med 1">
            <CaretDownIcon />
          </ButtonBase>
        </div>
      </div>
    </>
  );
};
