import { Button, Checkbox, FormControlLabel, List, MenuItem, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import s from './MessageTemplateEditor.module.scss';
import { Preview } from './Preview';
import { SendMessage } from './SendMessage';
import { SendTest } from './SendTest';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Flex, Item } from '#root/Components/Flex';
import { IphoneEditor } from '#root/Components/IphoneEditor';
import { Loading } from '#root/Components/Loading';
import { MacEditor } from '#root/Components/MacEditor';
import { useAutoSubmit } from '#root/hooks/use-auto-submit';
import { useConfirm } from '#root/hooks/use-confirm';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { IMessageTemplate } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { MailIcon, PhoneIcon, ThreeDotsIcon } from '#root/utils/icons';
import { createFakeDelayPromise } from '#root/utils/wait';

export interface IProps {
  template: IMessageTemplate;
  onCreate: (id: IMessageTemplate['id']) => void;
  className?: string;
}

// Little fix to remember the current tab between templates and also when saving a brand new one.
let staticRememberedTab: 'email' | 'text' = 'email';

const detectIfNameHasBeenRemoved = (original: IMessageTemplate, changed: IMessageTemplate) => {
  if (
    original.email.toLowerCase().includes('((name))') &&
    !changed.email.toLowerCase().includes('((name))')
  ) {
    return true;
  }
  if (
    original.text.toLowerCase().includes('((name))') &&
    !changed.text.toLowerCase().includes('((name))')
  ) {
    return true;
  }

  return false;
};

export const MessageTemplateEditor: FC<IProps> = ({ template, onCreate, className }) => {
  const t = useTranslation();
  const party = useEditableParty();
  const [shouldAutoSubmit, setShouldAutoSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { isConfirmed } = useConfirm();

  const [tab, setTab] = useState<'email' | 'text'>(staticRememberedTab);
  staticRememberedTab = tab;

  const {
    setError,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    getValues,
  } = useForm<IMessageTemplate>({
    defaultValues: template,
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IMessageTemplate> = async data => {
    if ((!data.id.startsWith('new-') && !isDirty) || submitting || !party.id) return;
    if (
      detectIfNameHasBeenRemoved(template, data) &&
      (await isConfirmed(Keys.admin.message.warning_name_removed, Keys.generic.save)) === false
    ) {
      setSubmitting(false);
      return;
    }
    const fakeDelay = createFakeDelayPromise(1000);
    setSubmitting(true);
    getService('messageTemplates')
      .save(party.id, data)
      .then(responseTemplate => {
        reset(responseTemplate);
        onCreate(responseTemplate.id);
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          if (body.errors) {
            for (const [field, message] of Object.entries<string>(body.errors)) {
              setError(field as any, { type: 'custom', message });
            }
          } else if (body.message) {
            alert(body.message);
          }
        }
      })
      .finally(async () => {
        await fakeDelay;
        setSubmitting(false);
      });
  };
  const templateId = template.id;
  const onDelete = useCallback(async () => {
    if (
      party.id &&
      templateId !== 'new' &&
      (await isConfirmed(Keys.admin.message.delete, Keys.generic.delete, 'error'))
    ) {
      await getService('messageTemplates').delete(party.id, templateId);
    }
  }, [party.id, templateId]);

  useAutoSubmit(watch, handleSubmit, onSubmit, shouldAutoSubmit && !template.id.startsWith('new-'));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={clsx(className, s.form)}>
      <div className={s.stackReverseMobile}>
        <Tabs
          variant="scrollable"
          value={tab}
          onChange={(e, value) => setTab(value)}
          className={s.tabs}
        >
          <Tab
            label={
              <Flex align="center" gap>
                <MailIcon />
                {t(keys => keys.admin.message.tab.email)}
              </Flex>
            }
            value="email"
          />
          <Tab
            label={
              <Flex align="center" gap>
                <PhoneIcon />
                {t(keys => keys.admin.message.tab.text)}
              </Flex>
            }
            value="text"
          />
        </Tabs>
        <div className={s.actions}>
          <div>
            <DropdownMenu
              icon={<ThreeDotsIcon />}
              IconButtonProps={{ disabled: template.id.startsWith('new-'), color: 'primary' }}
            >
              {({ closeDropdown }) => (
                <List>
                  <ButtonDialog
                    menuItem={t(keys => keys.admin.message.test.menu)}
                    onClose={closeDropdown}
                    withCloseButton
                    header={t(keys => keys.admin.message.test.title)}
                  >
                    {closeDialog => <SendTest template={getValues()} onClose={closeDialog} />}
                  </ButtonDialog>
                  {tab === 'email' ? (
                    <ButtonDialog
                      menuItem={t(keys => keys.admin.message.preview)}
                      onClose={closeDropdown}
                      withCloseButton
                      header={t(keys => keys.admin.message.preview)}
                      maxWidth={'lg'}
                    >
                      {() => <Preview party={party} templateGetter={getValues} />}
                    </ButtonDialog>
                  ) : null}
                  <MenuItem onClick={() => closeDropdown(onDelete())}>
                    {t(keys => keys.generic.delete)}
                  </MenuItem>
                </List>
              )}
            </DropdownMenu>
          </div>
          {!template.id.startsWith('new-') ? (
            <Item>
              <ButtonDialog
                buttonText={t(keys => keys.admin.message.test.short_menu)}
                ButtonProps={{ variant: 'outlined' }}
                withCloseButton
                header={t(keys => keys.admin.message.test.title)}
              >
                {closeDialog => <SendTest template={getValues()} onClose={closeDialog} />}
              </ButtonDialog>
            </Item>
          ) : null}
          <Item>
            <ButtonDialog
              disabled={template.id.startsWith('new-') || submitting || isDirty}
              withCloseButton
              classes={{ paper: s.sendMessageDialog }}
              buttonText={
                <>
                  {template.type === 'invitation' ? (
                    <span className={css.showOnDesktop}>
                      {t(keys => keys.admin.message.send.invitations)} …
                    </span>
                  ) : (
                    <span className={css.showOnDesktop}>
                      {t(keys => keys.admin.message.send.message)} …
                    </span>
                  )}
                  <span className={css.hideOnDesktop}>{t(keys => keys.generic.send)} …</span>
                </>
              }
              header={t(keys => keys.admin.message.send.title)}
            >
              {onClose => <SendMessage template={template} onClose={onClose} />}
            </ButtonDialog>
          </Item>
          <Item>
            <Button
              type="submit"
              disabled={!isValid || submitting || (!isDirty && !template.id.startsWith('new-'))}
              color="primary"
              variant="contained"
              disableElevation
            >
              {submitting ? (
                <Loading />
              ) : template.id.startsWith('new-') ? (
                t(keys => keys.generic.create)
              ) : (
                t(keys => keys.generic.save)
              )}
            </Button>
          </Item>
        </div>
      </div>
      <Flex column align="center" className={s.formInner}>
        <div className={clsx(s.tabContent, tab === 'email' && s.activeTab)}>
          <MacEditor control={control} />
        </div>
        <div className={clsx(s.tabContent, tab === 'text' && s.activeTab)}>
          <IphoneEditor control={control} />
        </div>
        {!template.id.startsWith('new-') ? (
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                value={shouldAutoSubmit}
                onChange={e => setShouldAutoSubmit(e.target.checked)}
              />
            }
            label={t(keys => keys.admin.message.auto_save)}
          />
        ) : null}
      </Flex>
    </form>
  );
};
