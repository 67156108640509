import clsx from 'clsx';
import React, { FC, useEffect, useRef } from 'react';

import s from './index.module.scss';

import { useMaps } from '#root/hooks/use-google-maps';
import { ILocation } from '#root/store/types';

export interface IProps {
  location: ILocation;
  className?: string;
}

const mapOptions = {
  draggable: false,
  scrollwheel: false,
  panControl: false,
  disableDoubleClickZoom: true,
  zoom: 12,
  disableDefaultUI: true,
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }, { lightness: 17 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dedede' }, { lightness: 21 }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    {
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#fefefe' }, { lightness: 20 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ],
};

export const Map: FC<IProps> = ({ location, className }) => {
  const maps = useMaps();
  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (maps && mapRef.current) {
      const map = new maps.Map(mapRef.current, {
        ...mapOptions,
        center: {
          lat: location.latitude,
          lng: location.longitude,
        },
      });
      new (window as any).google.maps.Marker({
        map: map,
        position: {
          lat: location.latitude,
          lng: location.longitude,
        },
      });
    }
  }, [maps, mapRef.current, location]);

  return <div ref={mapRef} className={clsx(s.map, className)} />;
};
