import { TextField } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';

type ChildrenFunction = (title?: string) => ReactNode;
export const Title: FC<IBaseBlockWrapperProps & { children?: ChildrenFunction }> = ({
  className,
  children,
}) => {
  const title = useStoreState(state => state.party.party?.title);
  const t = useTranslation();
  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <TextField
            required
            label={t(keys => keys.party.blocks.title.title)}
            variant="outlined"
            fullWidth
            value={title ?? ''}
            error={title?.length === 0}
            onChange={e => getService('party').updateField('title', e.target.value)}
          />
        ) : children ? (
          children(title)
        ) : (
          <h1 className={s.title}>{title}</h1>
        )
      }
    </BaseBlock>
  );
};
