import { useLayoutEffect, useRef } from 'react';

export const useTitle = (title?: string, shouldCleanup = false) => {
  const prevTitleRef = useRef(document.title);

  if (title && document.title !== title) document.title = title;

  useLayoutEffect(() => {
    if (shouldCleanup) {
      return () => {
        document.title = prevTitleRef.current;
      };
    }
  }, []);
};
