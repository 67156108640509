import { Button, ButtonGroup } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';

import { Box } from '../Box';
import { Flex } from '../Flex';
import { Loading } from '../Loading';
import { RedeemCoupon } from '../RedeemCoupon';

import s from './index.module.scss';

import type { Currency, IEditableParty, IPaymentMessageType, Plan } from '#root/store/types';

import { useDefaultCurrency } from '#root/hooks/use-default-currency';
import { useEditableParty } from '#root/hooks/use-party';
import { usePopup } from '#root/hooks/use-popup';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { DoneIcon } from '#root/utils/icons';
import { formatCurrency } from '#root/utils/string';

const canUpgrade = (from: Plan, to: Plan): boolean => {
  if (to === 'premium') {
    return from === 'free';
  } else if (to === 'pro') {
    return from !== 'pro';
  }
  return false;
};

const ShowPriceAndCoupon: FC<{ plan: Plan; party: IEditableParty; currency: Currency }> = ({
  plan,
  party,
  currency,
}) => {
  const t = useTranslation();
  const upgradable = canUpgrade(party.plan, plan);
  const currentPrice = party.prices[plan][currency];
  const originalPrice =
    party.originalPrices?.[plan][currency] !== currentPrice
      ? party.originalPrices?.[plan][currency]
      : null;
  return (
    <>
      <div className={s.price}>
        {upgradable ? (
          <Flex column align="center" justify="center">
            <span>{formatCurrency(party.prices[plan][currency], currency)}</span>
            {originalPrice ? (
              <span className={s.originalPrice}>
                {t(Keys.chooser.before_price)} {formatCurrency(originalPrice, currency, true)}
              </span>
            ) : null}
          </Flex>
        ) : null}
      </div>
      {upgradable ? <RedeemCoupon /> : null}
    </>
  );
};

export const PartyPlanChooser: FC<{ className?: string }> = ({ className }) => {
  const defaultCurrency = useDefaultCurrency();
  const [currency, setCurrency] = useState<Currency>(defaultCurrency);
  const party = useEditableParty();
  const t = useTranslation();
  const onMessage = useCallback((event: MessageEvent<IPaymentMessageType>) => {
    const { data } = event;
    if (data.source === 'payment-window' && data.payment) {
      getService('party').refetch();
    }
  }, []);

  const { open: openPopupPremium, isOpen: isPremiumOpen } = usePopup('change_plan', onMessage);
  const { open: openPopupPro, isOpen: isProOpen } = usePopup('change_plan', onMessage);

  if (!party.prices) {
    return null;
  }

  return (
    <div className={clsx(s.container, className)}>
      <div className={s.plans}>
        <Box bordered className={s.free}>
          <h2>{t(Keys.chooser.free.name)}</h2>
          <ul className="checkmarked list">
            {t(Keys.chooser.free.list)
              .split('|')
              .map((text, index) => (
                <li key={index}>{text}</li>
              ))}
          </ul>
          {party.plan === 'free' ? (
            <>
              <div className={s.price}></div>
              <Button
                disableElevation
                variant="contained"
                disabled={true} // Only from free, as we cannot downgrade
              >
                <DoneIcon /> {t(Keys.chooser.current_plan)}
              </Button>
            </>
          ) : null}
        </Box>
        <Box bordered className={s.premium}>
          <h2>{t(Keys.chooser.premium.name)}</h2>
          <p className={clsx(s.includesText, css.fontSmall, css.fontSans)}>
            {t(Keys.chooser.premium.includes_free)}
          </p>
          <ul className="checkmarked list">
            {t(Keys.chooser.premium.list)
              .split('|')
              .map((text, index) => (
                <li key={index}>{text}</li>
              ))}
          </ul>
          <ShowPriceAndCoupon party={party} plan="premium" currency={currency} />
          <Button
            disableElevation
            size="large"
            variant="contained"
            disabled={!canUpgrade(party.plan, 'premium')} // Only from free, as we cannot downgrade
            onClick={() => openPopupPremium(`/payment/plan/${party.id}/premium/${currency}`)}
          >
            {party.plan === 'pro' ? (
              <DoneIcon />
            ) : party.plan === 'premium' ? (
              <>
                <DoneIcon /> {t(Keys.chooser.current_plan)}
              </>
            ) : isPremiumOpen ? (
              <Loading color="inherit" />
            ) : (
              <>
                {t(Keys.chooser.upgrade_to)} <b>{t(Keys.chooser.premium.name)}</b>
              </>
            )}
          </Button>
        </Box>
        <Box bordered className={s.pro}>
          <h2>{t(Keys.chooser.pro.name)}</h2>
          <p className={clsx(s.includesText, css.fontSmall, css.fontSans)}>
            {t(Keys.chooser.pro.includes_free_and_premium)}
          </p>
          <ul className="checkmarked list">
            {t(Keys.chooser.pro.list)
              .split('|')
              .map((text, index) => (
                <li key={index}>{text}</li>
              ))}
          </ul>
          <ShowPriceAndCoupon party={party} plan="pro" currency={currency} />
          <Button
            disableElevation
            variant="contained"
            disabled={!canUpgrade(party.plan, 'pro')}
            onClick={() => openPopupPro(`/payment/plan/${party.id}/pro/${currency}`)}
          >
            {party.plan === 'pro' ? (
              <>
                <DoneIcon /> {t(Keys.chooser.current_plan)}
              </>
            ) : isProOpen ? (
              <Loading color="inherit" />
            ) : (
              <>
                {t(Keys.chooser.upgrade_to)}
                <b> {t(Keys.chooser.pro.name)}</b>
              </>
            )}
          </Button>
        </Box>
      </div>
      <Flex gap column align="center">
        <ButtonGroup size="small">
          <Button disabled={currency === 'dkk'} onClick={() => setCurrency('dkk')}>
            dkk
          </Button>
          <Button disabled={currency === 'eur'} onClick={() => setCurrency('eur')}>
            eur
          </Button>
        </ButtonGroup>
        {/* {party.plan !== 'free' ? <p>{t(Keys.chooser.downgrade_notice)}</p> : null} */}
        <p className={clsx(css.fontSmall, css.fontSans)}>{t(Keys.generic.all_prices_incl_tax)}</p>
      </Flex>
    </div>
  );
};
