import React, { FC } from 'react';

import { Datepicker } from '../Inputs/datepicker';
import { Location } from '../Inputs/location';
import { Text } from '../Inputs/text';
import { Timepicker } from '../Inputs/timepicker';

import { breakupTranslation, IAbstractType } from '.';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export const Baptism: FC<IAbstractType & { type: string }> = ({ control, type }) => {
  const t = useTranslation();
  const [title, body] = t(Keys.wizard.template[type]).split('|');
  const parts = breakupTranslation(body);

  return (
    <>
      <h2>{title}</h2>
      <span>{parts.childName}</span>
      <Text name="childName" required control={control} />
      <span>{parts.date}</span>
      <Datepicker control={control} />
      <span>{parts.clock}</span>
      <Timepicker control={control} />
      <span>{parts.location}</span>
      <Location name="location" control={control} />
      <span>{parts.name}</span>
      <Text name="name" required control={control} />
    </>
  );
};
