import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';

import { useFetch } from '#root/hooks/use-fetch';
import { IContact, IParty } from '#root/store/types';

type PartialContact = Pick<IContact, 'id' | 'name' | 'maxPeople'>;

export interface IProps {
  partyId: IParty['id'];
  value: string | PartialContact;
  onContactChange: (value: PartialContact | null) => void;
  onNameChange: (value: string) => void;
  TextFieldProps?: TextFieldProps;
}

export const ContactSelector: FC<IProps> = ({
  partyId,
  value,
  onContactChange,
  onNameChange,
  TextFieldProps,
}) => {
  const { data, loading } = useFetch<PartialContact[] | null>(
    `/api/party/${partyId}/missing-rsvps`,
    null
  );

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={data ?? []}
      value={value}
      onChange={(e, changedValue) =>
        typeof changedValue === 'string'
          ? onNameChange(changedValue)
          : onContactChange(changedValue)
      }
      autoComplete
      onInputChange={(e, changedValue) => onNameChange(changedValue)}
      getOptionLabel={option => (typeof option === 'object' ? option.name : option)}
      renderInput={params => <TextField {...params} {...TextFieldProps} />}
    />
  );
};
