import { ForwardToInbox, Person } from '@mui/icons-material';
import { Button, DialogActions, DialogContent, TextField, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Flex, Item } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { Loading } from '#root/Components/Loading';
import { LoadingIconButton } from '#root/Components/LoadingButton';
import { useConfirm } from '#root/hooks/use-confirm';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IEditableParty } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon } from '#root/utils/icons';

const AddCohostForm: FC<{ party: IEditableParty; onClose: () => void }> = ({ party, onClose }) => {
  const t = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>();

  const onSubmit: SubmitHandler<{ email: string }> = async data => {
    setSubmitting(true);
    getService('settings')
      .addCohost(party.id, data.email)
      .then(() => setSubmitted(true))
      .finally(() => setSubmitting(false));
  };

  if (submitted) {
    return (
      <>
        <DialogContent>
          <InfoBox type="success">{t(Keys.admin.settings.cohosts.cohost_invited)}</InfoBox>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {t(Keys.generic.close)}
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <DialogContent>
        <form id="settings-cohost" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t(Keys.generic.email)}
            type="email"
            autoComplete="email"
            inputProps={register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t(Keys.generic.email_error),
              },
            })}
            helperText={errors.email?.message}
            color={errors.email ? 'error' : 'primary'}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disableElevation
          variant="contained"
          type="submit"
          className={css.spaceTopM}
          disabled={!isValid || submitting}
          form="settings-cohost"
        >
          {submitting ? <Loading /> : t(Keys.admin.settings.cohosts.invite_cohost)}
        </Button>
      </DialogActions>
    </>
  );
};

export const Cohosts: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const party = useEditableParty();
  const user = useStoreState(state => state.app.currentUser);
  const { isConfirmed } = useConfirm();

  const t = useTranslation();

  const removeCohost = useCallback(async (id: string) => {
    if (
      await isConfirmed(Keys.admin.settings.cohosts.confirm_delete, Keys.generic.delete, 'error')
    ) {
      return getService('settings').removeCohost(party.id, id);
    }
  }, []);

  return (
    <>
      {party.cohostInvites.length > 0 ? (
        <Flex column outlined bordered>
          {party.cohostInvites.map(invite => (
            <Flex align="center" gap key={invite.id}>
              <Tooltip
                title={
                  invite.user
                    ? t(Keys.admin.settings.cohosts.tooltip_accepted)
                    : t(Keys.admin.settings.cohosts.tooltip_sent)
                }
              >
                <span>{invite.user ? <Person /> : <ForwardToInbox />}</span>
              </Tooltip>
              <Flex column>
                <span className={clsx(css.fontSans, css.fontLarge)}>
                  {invite.user?.name ?? invite.email}
                </span>
                <span className={css.fontSmall}>
                  {format(parseISO(invite.createdAt), 'dd/MM/yyyy - HH:mm')}
                </span>
              </Flex>
              {party.isOwner || user?.id === invite.user?.id ? (
                <Item pushRight>
                  <LoadingIconButton
                    onClick={() => removeCohost(invite.id)}
                    color="error"
                    title={t(Keys.generic.remove)}
                  >
                    <CloseIcon />
                  </LoadingIconButton>
                </Item>
              ) : null}
            </Flex>
          ))}
        </Flex>
      ) : null}
      {party.isOwner ? (
        <Flex justify="end" className={css.spaceTopM}>
          <ButtonDialog
            withCloseButton
            header={t(Keys.admin.settings.cohosts.invite_cohost)}
            buttonText={t(Keys.admin.settings.cohosts.invite_cohost)}
            ButtonProps={{
              disabled,
              variant: 'outlined',
              color: 'secondary',
            }}
          >
            {onClose => <AddCohostForm party={party} onClose={onClose} />}
          </ButtonDialog>
        </Flex>
      ) : null}
    </>
  );
};
