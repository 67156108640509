import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

export function useOnFieldChange(
  name: string,
  control: Control<any, any>,
  callback: (value: any) => void
) {
  const value = useWatch({ name, control });
  const [previousValue, setPreviousValue] = useState<any>(null);
  useEffect(() => {
    if (value !== previousValue) {
      if (previousValue !== null) {
        callback(value);
      }
      setPreviousValue(value);
    }
  }, [previousValue, value, callback]);
}
