import { format, parseISO } from 'date-fns';
import { Control } from 'react-hook-form';

import { Anniversary } from './anniversary';
import { Baptism } from './baptism';
import { Party } from './party';
import { Wedding } from './wedding';

import { getService } from '#root/store';
import { IEditableParty, ILocation } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { nameSuffixS, replaceAll } from '#root/utils/string';

export interface ISpamField {
  zip: string;
}

export interface IAbstractType {
  control: Control<AllPartyTypes & ISpamField>;
}

export interface IBaseType {
  name: string;
  date: string;
  clock: string;
  location?: ILocation;
}
export interface IBirthdayType extends IBaseType {
  type: 'birthday';
}
export interface IPartyType extends IBaseType {
  type: 'party';
}
export interface IChristmasType extends IBaseType {
  type: 'christmas';
}
export interface IWeddingType extends IBaseType {
  type: 'wedding';
  nameOnSpouse: string;
  churchLocation: ILocation;
}
export interface IBaptismType extends IBaseType {
  type: 'baptism';
  childName: string;
}
export interface IAnniversaryType extends IBaseType {
  type: 'anniversary';
  year: string;
}

export type AllPartyTypes =
  | IBirthdayType
  | IPartyType
  | IWeddingType
  | IBaptismType
  | IAnniversaryType
  | IChristmasType;

export type PartyTypes = AllPartyTypes['type'];

export const Types = {
  birthday: Party,
  party: Party,
  wedding: Wedding,
  baptism: Baptism,
  confirmation: Party,
  anniversary: Anniversary,
  christmas: Party,
};

export const TypeBackgrounds = {
  birthday: '/hero-images/birthday/annie-spratt-M20ylqCzSZw-unsplash.jpg',
  party: '/hero-images/party/ian-schneider-PAykYb-8Er8-unsplash.jpg',
  wedding: '/hero-images/wedding/jez-timms-7bVMdNYzH_8-unsplash.jpg',
  baptism: '/hero-images/baptism/eric-froehling-xrT9twwwC6Y-unsplash.jpg',
  confirmation: '/hero-images/confirmation/patrick-fore-b_SHPU5M3nk-unsplash.jpg',
  anniversary: '/hero-images/party/anniversary.jpg',
  christmas: '/hero-images/christmas/eugene-zhyvchik-bbNd3wFwG0I-unsplash.jpg',
};

export const breakupTranslation = (text: string): { [token: string]: string } => {
  text = replaceAll(text, '\\n', '\n');
  const parts = [...text.matchAll(/([\s\S]+?)(%.+?%)/g)];

  return parts.reduce((carry, part) => {
    const [, leadingText, token] = part;
    carry[token.replace(new RegExp('%', 'g'), '')] = leadingText;
    return carry;
  }, {});
};

type PartyWizardCreatorResponse = Pick<
  IEditableParty,
  'type' | 'title' | 'description' | 'date' | 'body' | 'heroImage' | 'location'
>;

export const partyCreator = (data: AllPartyTypes): PartyWizardCreatorResponse => {
  const { type } = data;
  const translation = Keys.wizard.creator[type] ?? Keys.wizard.creator.party;

  const t = getService('translation').translateKey;

  // Ensure to remove irrelivant segments from translation in case some data is undefined.
  const dataForTranslation = {
    ...data,
    name: data.name.trim(),
    nameS: nameSuffixS(data.name.trim()),
    nameOnSpouse: data.type === 'wedding' ? data.nameOnSpouse?.trim() : undefined,
    childNameS:
      data.type === 'baptism' && data.childName ? nameSuffixS(data.childName.trim()) : undefined,
    date: format(parseISO(data.date), 'dd-MM-yyyy'),
    location: data.location ? data.location.address : undefined,
    churchLocation:
      data.type === 'wedding' && data.churchLocation ? data.churchLocation.address : undefined,
  };

  const [hour, min] = data.clock.split(':');
  const date = new Date(data.date);
  date.setHours(Number(hour));
  date.setMinutes(Number(min));

  return {
    type,
    title: t(translation.title, dataForTranslation),
    description: t(translation.description, dataForTranslation),
    body: t(translation.body, dataForTranslation),
    date: date.toISOString(),
    heroImage: TypeBackgrounds[type],
    location: data.location,
  };
};
