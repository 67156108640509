import { Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller, useFormState } from 'react-hook-form';

import { Flex } from '../Flex';

import { SmsField } from './SmsField';
import s from './index.module.scss';

import { useIsRsvpActive } from '#root/hooks/use-is-rsvp-active';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { IMessageTemplate } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { supports } from '#root/utils/party';
import { getMaxLength, hasLinkInText } from '#root/utils/sms';

const BubbleHeader: FC<{ control: Control<IMessageTemplate, any> }> = ({ control }) => {
  const { errors } = useFormState({ control, name: 'text' });
  const party = useEditableParty();
  const user = useStoreState(state => state.app.currentUser);
  const t = useTranslation();
  const canUseSenderNumber = supports(party, 'sms_sender');
  const smsUseSenderNumber = canUseSenderNumber && party.smsUseSenderNumber && user?.phone_verified;
  return (
    <div className={s.bubbleHeader}>
      {errors.text ? (
        <div className={s.errorMessage}>{errors.text.message}</div>
      ) : smsUseSenderNumber ? (
        <div className={s.smsSender}>{user.phone}</div>
      ) : (
        <Tooltip title={t(Keys.admin.message.sms_sender_tooltip)}>
          <span>
            <Flex align="center" className={s.smsSender}>
              invii.me
              {canUseSenderNumber ? <Info color="info" fontSize="small" /> : null}
            </Flex>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export const IphoneEditor: FC<{ control: Control<IMessageTemplate, any> }> = ({ control }) => {
  const t = useTranslation();
  const rsvpActive = useIsRsvpActive();
  return (
    <div className={s.iphone}>
      <div className={s.pcaHold}>
        <div className={s.pcaMain}>
          <div className={s.pcaInner}>
            <div className={s.bubbleWrapper}>
              <BubbleHeader control={control} />
              <div className={s.bubble}>
                <Controller
                  name="text"
                  control={control}
                  rules={{
                    validate: {
                      tooLong: value =>
                        value.length > getMaxLength(value)
                          ? t(Keys.admin.message.text_characters_too_long)
                          : undefined,
                      noUrls: value =>
                        /(https?:\/\/[^\s]+)/g.test(value)
                          ? t(Keys.admin.message.text_contains_url)
                          : undefined,
                    },
                  }}
                  render={({ field }) => (
                    <SmsField
                      defaultValue={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      suffix={
                        !hasLinkInText(field.value)
                          ? rsvpActive
                            ? t(Keys.admin.message.text_link_with_rsvp)
                            : t(Keys.admin.message.text_link_without_rsvp)
                          : null
                      }
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={s.pcaSub}>
          <div className={s.pcaTop}></div>
          <div className={s.pcaMid}>
            <div className={s.pcaPart}></div>
          </div>
          <div className={s.pcaBot}></div>
        </div>
      </div>
    </div>
  );
};
