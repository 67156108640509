import { AddPhotoAlternate, CloudDownload, Info } from '@mui/icons-material';
import { Button, DialogContent, IconButton, TextField } from '@mui/material';
import clsx from 'clsx';
import ImageBlobReduce from 'image-blob-reduce';
import React, { FC, useCallback, useRef, useState } from 'react';
import Dropzone from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import isURL from 'validator/lib/isURL';

import { PreviewImage } from '../PreviewImage';

import s from './index.module.scss';

import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { IEditableWish, IWish } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon } from '#root/utils/icons';

export interface IProps {
  editableItem?: IWish;
  onChange: (wish: IWish) => void;
  onCancel: () => void;
}

export const WishlistForm: FC<IProps> = ({ editableItem, onChange, onCancel }) => {
  const t = useTranslation();
  const { id: partyId } = useParty();
  const titleInputRef = useRef<HTMLInputElement>();
  const [submitting, setSubmitting] = useState(false);
  const [fetchingOgTags, setFetchingOgTags] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const {
    formState: { errors, isValid, isDirty },
    setValue,
    setError,
    register,
    handleSubmit,
    reset,
    watch,
  } = useForm<IEditableWish>({
    defaultValues: editableItem ?? {},
    mode: 'onBlur',
  });
  const watchedUpload = watch('upload');
  const watchedImage = watch('image');
  const watchedLink = watch('link');

  const onSubmit: SubmitHandler<IEditableWish> = async data => {
    if (submitting || isResizing) return;
    try {
      setSubmitting(true);
      const wish = await getService('wishlist').submit(partyId, data);
      onChange(wish);
      reset();
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
    onCancel();
  };

  const onDropImage = useCallback(async (files: File[]) => {
    const file = files[0];

    if (!file) return;
    setIsResizing(true);

    try {
      const resizedBlob = await ImageBlobReduce().toBlob(file, { max: 1000 });
      setValue('upload', resizedBlob, { shouldDirty: true });
    } catch (e) {
      // Failover using unresized image.
      setValue('upload', file, { shouldDirty: true });
    } finally {
      setIsResizing(false);
    }
  }, []);

  const onImageRemove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setValue('upload', null, { shouldDirty: true });
    setValue('image', null, { shouldDirty: true });
  };

  const fetchOgTags = async () => {
    if (watchedLink) {
      setFetchingOgTags(true);
      const response = await getService('wishlist').fetch(watchedLink);
      if (response) {
        response.image && setValue('image', response.image);
        response.title &&
          setValue('title', response.title, { shouldTouch: true, shouldValidate: true });
      } else {
        setError('link', { type: 'custom', message: t(Keys.party.wishlist.fetch_error) });
      }
      setFetchingOgTags(false);
    }
  };

  return (
    <DialogContent>
      <form onSubmit={handleSubmit(onSubmit)} className="print-hidden">
        <Flex gap align="center" stackOnMobile>
          <Item grow>
            <Flex column>
              <TextField
                inputRef={titleInputRef}
                label={t('Tekst')}
                autoComplete="off"
                fullWidth
                margin="normal"
                inputProps={register('title', { required: true })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label={t('evt. Link')}
                placeholder="https://"
                autoComplete="off"
                fullWidth
                inputProps={register('link', {
                  validate: value => !value || isURL(value) || t('Link is invalid'),
                })}
                error={!!errors.link}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={
                  errors.link?.message ||
                  (fetchingOgTags ? (
                    <Loading size={13} color="secondary" />
                  ) : watchedLink ? (
                    <a href="#" onClick={fetchOgTags}>
                      {t(Keys.party.wishlist.fetch_tags)}
                    </a>
                  ) : undefined)
                }
              />
            </Flex>
          </Item>
          <Item pushRight>
            <Dropzone
              onDrop={onDropImage}
              accept={{
                'image/jpeg': [],
                'image/png': [],
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={clsx(
                      s.dropzone,
                      (isResizing || !(watchedImage || watchedUpload)) && s.noImage
                    )}
                  >
                    <input {...getInputProps()} />
                    {isResizing ? (
                      <Loading />
                    ) : (
                      <>
                        <PreviewImage
                          className={s.preview}
                          url={watchedImage}
                          file={watchedUpload}
                        />
                        <Flex align="center" justify="center" gap className={s.imageButtons}>
                          <>
                            <div className={s.dropzoneIcon}>
                              <IconButton title={t(keys => keys.party.wishlist.upload_image)}>
                                <AddPhotoAlternate />
                              </IconButton>
                            </div>
                            {watchedImage || watchedUpload ? (
                              <IconButton
                                color="secondary"
                                onClick={onImageRemove}
                                className={s.dropzoneRemoveImage}
                                title={t(keys => keys.generic.remove)}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </>
                        </Flex>
                      </>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </Item>
        </Flex>

        <Flex className={css.spaceTopL}>
          {editableItem?.id ? (
            <Button onClick={onCancel}>{t(keys => keys.generic.cancel)}</Button>
          ) : null}

          <Item pushRight>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting || isResizing || !isDirty || !isValid}
              disableElevation
            >
              {submitting ? (
                <Loading size="small" />
              ) : editableItem?.id ? (
                t(keys => keys.generic.save)
              ) : (
                t(keys => keys.generic.create)
              )}
            </Button>
          </Item>
        </Flex>
      </form>
    </DialogContent>
  );
};
