import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import clsx from 'clsx';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from './use-translation';

import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
interface IAlertMessage {
  title?: string;
  message: string;
}
const AlertContext = createContext<(message: IAlertMessage | string) => void>(window.alert);

export const AlertProvider: FC<PropsWithChildren> = ({ children }) => {
  const t = useTranslation();
  const [message, setMessage] = useState<IAlertMessage | null>(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    // We're overwriting native alerts because they're not shown on iOS
    window.alert = (m: string) => {
      setMessage({ message: m });
    };
  }, []);

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const alert = (m: IAlertMessage | string) => {
    typeof m === 'string' ? setMessage({ message: m }) : setMessage(m);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  return (
    <AlertContext.Provider value={alert}>
      <>
        {children}
        {isOpen && message ? (
          <Dialog open={true}>
            <DialogContent dividers>
              {message?.title ? <h2>{message.title}</h2> : null}
              <div
                className={clsx(css.spaceTopL, css.spaceBottomL)}
                dangerouslySetInnerHTML={{ __html: message.message }}
              />
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={handleClose} variant="contained" color="primary">
                {t(Keys.generic.ok)}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
