import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useNavigate } from './use-navigate';

import { useStoreState } from '#root/store';
import { idParamIsParty } from '#root/utils/idParamIsParty';

export const useRedirectOnAdmin = (redirectTo: 'slug' | 'sid' = 'sid') => {
  const { idOrLang: sidParam } = useParams<{ idOrLang: string }>();
  const sid = useStoreState(state => state.party.party?.sid);
  const slug = useStoreState(state => state.party.party?.slug);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (!sid || !slug) {
      return;
    }

    if (sidParam && idParamIsParty(sidParam) && (sidParam === sid || sidParam === slug)) {
      const [, , ...rest] = location.pathname.split('/');
      if (redirectTo === 'sid' && sidParam !== sid) {
        navigate(`/${sid}/${rest.join('/')}`, true);
      } else if (redirectTo === 'slug' && sidParam !== slug) {
        navigate(`/${slug}/${rest.join('/')}`, true);
      }
    }
  }, [sidParam, sid, slug]);
};
