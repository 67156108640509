import { IconButton } from '@mui/material';
import React, { FC } from 'react';

export type ButtonProps = React.ComponentPropsWithoutRef<typeof IconButton>;

export const ToolbarButton: FC<ButtonProps & { active?: boolean; hidden?: boolean }> = ({
  children,
  active,
  hidden,
  ...buttonProps
}) => {
  if (hidden) return null;

  return (
    <IconButton size="small" color={active ? 'secondary' : 'default'} {...buttonProps}>
      {children}
    </IconButton>
  );
};
