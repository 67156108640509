import clsx from 'clsx';
import React, { forwardRef, PropsWithChildren } from 'react';

import s from './index.module.scss';

type DivProps = React.ComponentPropsWithoutRef<'div'>;

export interface IProps extends DivProps {
  className?: string;
  bordered?: boolean;
  shadow?: boolean;
  margin?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
}

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  ({ children, className, bordered, shadow, margin, marginBottom, marginTop, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          s.base,
          className,
          bordered && s.border,
          shadow && s.shadow,
          margin && s.margin,
          marginBottom && s.marginBottom,
          marginTop && s.marginTop
        )}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
