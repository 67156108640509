import React, { FC, Fragment, ReactNode } from 'react';

import { PagerActions } from './PagerActions';
import { SortableColumn } from './SortableColumn';
import s from './index.module.scss';

import { usePager } from '#root/hooks/use-pager';

export type OnOrderByChangeCallback = (name: string) => void;

type Params = { [key: string]: boolean | string | number };

export type OrderBy = [string, 'asc' | 'desc'] | null;

export interface IProps {
  url: string;
  headerRenderer?: (
    orderBy: OrderBy,
    onOrderByChangeCallback: OnOrderByChangeCallback,
    refresh: () => void
  ) => ReactNode;
  footerRenderer?: (refresh: () => void) => ReactNode;
  rowRenderer: (item: unknown, refresh: () => void) => ReactNode;

  idField?: string;
  defaultSort?: OrderBy;
  parentParams?: Params;
  rowsPerPage?: number;
  actions?: () => ReactNode;
  canRefresh?: boolean;
  filterable?: string[];
  noResultsMessage?: ReactNode;
  loadingMessage?: ReactNode;
  className?: string;
  actionPlacement?: 'top' | 'bottom';
}

export const Pager: FC<IProps> = ({
  url,
  defaultSort,
  parentParams = {},
  headerRenderer,
  footerRenderer,
  rowRenderer,
  rowsPerPage = 200,
  actions,
  canRefresh,
  filterable = [],
  noResultsMessage,
  idField,
  loadingMessage,
  className,
  actionPlacement = 'top',
}) => {
  const pager = usePager({ url, parentParams, filterable, defaultSort, rowsPerPage });

  const renderActions = (
    <div className={s.actions}>
      <PagerActions pager={pager} actions={actions} canRefresh={canRefresh} />
    </div>
  );

  return (
    <div>
      {pager.data !== undefined && actionPlacement === 'top' && renderActions}
      {headerRenderer?.(pager.orderBy, pager.onOrderByChangeCallback, pager.refresh)}
      {pager.data === undefined ? (
        loadingMessage
      ) : pager.data.length > 0 ? (
        <div className={className}>
          {pager.data.map((item, index) => (
            <Fragment key={idField && item[idField] ? item[idField] : index}>
              {rowRenderer(item, pager.refresh)}
            </Fragment>
          ))}
          {footerRenderer?.(pager.refresh)}
        </div>
      ) : (
        <>
          {noResultsMessage}
          {footerRenderer?.(pager.refresh)}
        </>
      )}
      {pager.data !== undefined && actionPlacement === 'bottom' && renderActions}
    </div>
  );
};

export { SortableColumn };
