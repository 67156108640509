import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { BigNumberField } from '#root/Components/BigNumberField';
import { ContactSelector } from '#root/Components/ContactSelector';
import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useConfirm } from '#root/hooks/use-confirm';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IRsvp, IRsvpFormData } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { LinkIcon } from '#root/utils/icons';

export const EditRSVP: FC<{ item?: IRsvp; onClose: () => void; refresh: () => void }> = ({
  item,
  onClose,
  refresh,
}) => {
  const t = useTranslation();
  const party = useStoreState(state => state.party.party);
  const [submitting, setSubmitting] = useState(false);
  const { isConfirmed } = useConfirm();

  const {
    setError,
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<IRsvpFormData>({
    defaultValues: item ?? {
      isComming: true,
      numberOfPeople: 1,
      name: '',
    },
    mode: 'onChange',
  });
  const isComming = watch('isComming');
  const contact = watch('contact');

  if (!party) return null;

  const onDelete = async () => {
    if (item && (await isConfirmed(Keys.admin.rsvp.delete, Keys.generic.delete, 'error'))) {
      await getService('rsvp').delete(party.id, item.id);
      refresh();
      onClose();
    }
  };

  const onSubmit: SubmitHandler<IRsvpFormData> = async rsvp => {
    if (submitting) return;
    setSubmitting(true);
    getService('rsvp')
      .save(party.id, rsvp)
      .then(() => {
        refresh();
        onClose();
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          if (body.errors) {
            for (const [field, message] of Object.entries<string>(body.errors)) {
              setError(field as any, { type: 'custom', message });
            }
          } else if (body.message) {
            alert(body.message);
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap>
            <Controller
              control={control}
              name="name"
              rules={{
                validate: value => !!contact || !!value || t(keys => keys.generic.required),
              }}
              render={({ field: { value, onChange, onBlur } }) => (
                <ContactSelector
                  partyId={party.id}
                  value={contact ?? value}
                  onNameChange={data => {
                    if (value !== data) {
                      setValue('contact', null);
                      onChange(data);
                    }
                  }}
                  onContactChange={data => {
                    setValue('contact', data);
                  }}
                  TextFieldProps={{
                    label: t(keys => keys.generic.name),
                    onBlur,
                    helperText: !contact && errors.name?.message,
                    error: !!errors.name,
                    margin: 'normal',
                    fullWidth: true,
                  }}
                />
              )}
            />
            {contact ? (
              <Flex gap align="center" className={css.fontSmall}>
                <LinkIcon /> {t(keys => keys.admin.rsvp.connected)}
              </Flex>
            ) : null}
            <Flex justify="space-between">
              <Controller
                control={control}
                name="isComming"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup
                    value={value ? '1' : '0'}
                    onChange={e => onChange(e.target.value === '1')}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={
                        party.guestCountChoiceEnabled
                          ? t(keys => keys.party.blocks.rsvp.is_comming_amount.label)
                          : t(keys => keys.party.blocks.rsvp.is_comming.label)
                      }
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={t(keys => keys.party.blocks.rsvp.not_comming.label)}
                    />
                  </RadioGroup>
                )}
              />
              {isComming && party.guestCountChoiceEnabled ? (
                <div>
                  <Controller
                    control={control}
                    name="numberOfPeople"
                    shouldUnregister
                    render={({ field: { onChange, value } }) => (
                      <BigNumberField
                        compact
                        maxValue={contact?.maxPeople}
                        value={value}
                        onChange={val => onChange(val ?? 0)}
                      />
                    )}
                  />
                </div>
              ) : null}
            </Flex>
            {isComming && party.rsvpFields.length ? (
              <>
                <h3>{t(keys => keys.admin.rsvp.fields.title)}</h3>
                {party.rsvpFields
                  .filter(field => !field.hidden || item?.fields[field.id] !== undefined)
                  .map(rsvpField =>
                    rsvpField.type === 'text' ? (
                      <TextField
                        key={rsvpField.id}
                        variant="outlined"
                        fullWidth
                        label={rsvpField.name}
                        inputProps={register(`fields.${rsvpField.id}`)}
                      />
                    ) : (
                      <FormControlLabel
                        key={rsvpField.id}
                        control={
                          <Controller
                            control={control}
                            name={`fields.${rsvpField.id}`}
                            render={({ field: { value, ...rest } }) => (
                              <Checkbox color="primary" checked={!!value} {...rest} />
                            )}
                          />
                        }
                        label={rsvpField.name}
                      />
                    )
                  )}
              </>
            ) : null}
            <Flex gap>
              {item ? (
                <Button disabled={submitting} onClick={onDelete} color="error" variant="outlined">
                  {t(keys => keys.generic.delete)}
                </Button>
              ) : null}
              <Item pushRight>
                <Button disabled={submitting} onClick={onClose} color="primary" variant="outlined">
                  {t(keys => keys.generic.cancel)}
                </Button>
              </Item>
              <Button
                type="submit"
                disabled={submitting || !isDirty || !isValid}
                color="primary"
                variant="contained"
                disableElevation
              >
                {submitting ? <Loading /> : t(keys => keys.generic.save)}
              </Button>
            </Flex>
          </Flex>
        </form>
      </DialogContent>
    </>
  );
};
