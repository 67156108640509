import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { List, MenuItem } from '@mui/material';
import { Editor } from '@tiptap/react';
import React, { FC, useCallback } from 'react';

import { DropdownMenu } from '../DropdownMenu';

import { ImageToolbarButton } from './Extensions/Image/image-toolbar-button';
import { ToolbarButton } from './ToolbarButton';
import s from './index.module.scss';

import {
  AddPhotoIcon,
  ClearFormatIcon,
  FontSizeIcon,
  LinkIcon,
  LinkOffIcon,
} from '#root/utils/icons';

export interface IToolBarOptions {
  bold?: boolean;
  italic?: boolean;
  link?: boolean;
  lists?: boolean;
  textAlign?: boolean;
  clear?: boolean;
  size?: boolean;
  image?: boolean;
}

export const Toolbar: FC<{ editor: Editor | null } & IToolBarOptions> = ({
  editor,
  ...toolbarOptions
}) => {
  if (!editor) {
    return null;
  }

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('Link URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  return (
    <div className={s.toolbar}>
      {toolbarOptions.bold && (
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          active={editor.isActive('bold')}
        >
          <FormatBoldIcon />
        </ToolbarButton>
      )}
      {toolbarOptions.italic && (
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          active={editor.isActive('italic')}
        >
          <FormatItalicIcon />
        </ToolbarButton>
      )}
      {toolbarOptions.size && (
        <DropdownMenu icon={<FontSizeIcon />}>
          {({ closeDropdown }) => (
            <List>
              <MenuItem
                selected={editor.isActive('heading', { level: 2 })}
                onClick={() =>
                  closeDropdown(editor.chain().focus().toggleHeading({ level: 2 }).run())
                }
              >
                Large
              </MenuItem>
              <MenuItem
                selected={editor.isActive('heading', { level: 3 })}
                onClick={() =>
                  closeDropdown(editor.chain().focus().toggleHeading({ level: 3 }).run())
                }
              >
                Medium
              </MenuItem>
              <MenuItem
                selected={editor.isActive('paragraph')}
                onClick={() => closeDropdown(editor.chain().focus().setParagraph().run())}
              >
                Normal
              </MenuItem>
            </List>
          )}
        </DropdownMenu>
      )}
      {toolbarOptions.link && (
        <>
          <ToolbarButton onClick={setLink} active={editor.isActive('link')}>
            <LinkIcon />
          </ToolbarButton>
          <ToolbarButton onClick={() => editor.chain().focus().unsetLink().run()}>
            <LinkOffIcon />
          </ToolbarButton>
        </>
      )}
      {toolbarOptions.lists && (
        <>
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            active={editor.isActive('bulletList')}
          >
            <FormatListBulletedIcon />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            active={editor.isActive('orderedList')}
          >
            <FormatListNumberedIcon />
          </ToolbarButton>
        </>
      )}
      {toolbarOptions.textAlign && (
        <>
          <ToolbarButton
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            active={editor.isActive({ textAlign: 'left' })}
          >
            <FormatAlignLeftIcon />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            active={editor.isActive({ textAlign: 'center' })}
          >
            <FormatAlignCenterIcon />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => editor.chain().focus().setTextAlign('right').run()}
            active={editor.isActive({ textAlign: 'right' })}
          >
            <FormatAlignRightIcon />
          </ToolbarButton>
        </>
      )}
      {toolbarOptions.clear && (
        <ToolbarButton onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          <ClearFormatIcon />
        </ToolbarButton>
      )}
      {toolbarOptions.image && (
        <ImageToolbarButton editor={editor}>
          <AddPhotoIcon />
        </ImageToolbarButton>
      )}
    </div>
  );
};
