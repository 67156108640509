import { Loader } from '@googlemaps/js-api-loader';
import { useCallback, useEffect, useState } from 'react';
const GOOGLE_MAPS_API_KEY = 'AIzaSyB77eL1WBzFcR4Tl-elISrNeN1QLMjARW4';

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
});
export const useGoogleMaps = () => {
  const places = useCallback(() => loader.importLibrary('places'), []);
  const maps = useCallback(() => loader.importLibrary('maps'), []);

  return {
    places,
    maps,
  };
};

export const usePlaces = () => {
  const [places, setPlaces] = useState<google.maps.PlacesLibrary>();
  useEffect(() => {
    (async () => {
      try {
        const service = await loader.importLibrary('places');
        setPlaces(service);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return places;
};

export const useMaps = () => {
  const [maps, setMaps] = useState<google.maps.MapsLibrary>();
  useEffect(() => {
    (async () => {
      try {
        const service = await loader.importLibrary('maps');
        setMaps(service);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return maps;
};
