import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { WysiwygField } from '#root/Components/WysiwygField';
import { getService, useStoreState } from '#root/store';

export const Body: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const body = useStoreState(state => state.party.party?.body);
  const hasBody = body && body.length > 0 && body !== '<p></p>';

  return (
    <BaseBlock editable>
      {({ editable }) =>
        editable ? (
          <div className={className}>
            <WysiwygField
              value={body ?? ''}
              className={s.body}
              onChange={html => getService('party').updateField('body', html)}
            />
          </div>
        ) : (
          <>
            {hasBody ? (
              <div className={className}>
                <div className={s.body} dangerouslySetInnerHTML={{ __html: body }} />
              </div>
            ) : null}
          </>
        )
      }
    </BaseBlock>
  );
};
