import clsx from 'clsx';
import React, { forwardRef, PropsWithChildren } from 'react';

import s from './index.module.scss';

type DivProps = React.ComponentPropsWithoutRef<'div'>;

export interface IProps extends DivProps {
  className?: string;
  shadow?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  right?: boolean;
  color?: 'primary' | 'secondary';
  bold?: boolean;
}

export const Bubble = forwardRef<HTMLDivElement, PropsWithChildren<IProps>>(
  ({ children, className, shadow, marginTop, marginBottom, right, color, bold, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          s.base,
          className,
          color === 'primary' && s.colorPrimary,
          color === 'secondary' && s.colorSecondary,
          shadow && s.shadow,
          marginTop && s.marginTop,
          marginBottom && s.marginBottom,
          right && s.right,
          bold && s.bold
        )}
        {...rest}
      >
        {children}
        <div className={s.arrow} />
      </div>
    );
  }
);
