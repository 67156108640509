export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Helps showing e.g. a spinner for at least `ms` miliseconds. If the server requests are taking less than `ms` it will wait a bit.
 *
 *  setIsSubmitting(true)
 *  const fakeDelay = createFakeDelayPromise(1000);
 *  await requestToServer()
 *  await fakeDelay;
 *  setIsSubmitting(false)
 *
 * @param ms miliseconds to fake
 */
export const createFakeDelayPromise = (ms = 500) => {
  const startTime = performance.now();

  return new Promise<void>(async resolve => {
    const endTime = performance.now();
    const elapsedTime = endTime - startTime;
    if (elapsedTime < ms) {
      await wait(ms - elapsedTime);
    }
    resolve();
  });
};
