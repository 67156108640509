// https://tiptap.dev/
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import { EditorContent, Extensions, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Controller } from 'react-hook-form';

import { UploadImage } from './Extensions/Image';
import { type IToolBarOptions, Toolbar } from './Toolbar';
import s from './index.module.scss';

import { getService } from '#root/store';

const toolbarOptionTypes = {
  none: {},
  simple: {
    bold: true,
    italic: true,
  },
  full: {
    bold: true,
    italic: true,
    link: true,
    lists: true,
    textAlign: true,
    clear: true,
    size: true,
    image: true,
  },
} as const satisfies { [key: string]: IToolBarOptions };

const isToolbarOptionsObject = (
  options: IToolBarOptions | keyof typeof toolbarOptionTypes
): options is IToolBarOptions => typeof options === 'object';

export interface IProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  error?: boolean;
  toolbarOptions?: IToolBarOptions | keyof typeof toolbarOptionTypes;
  className?: string;
}

export const WysiwygField: FC<IProps> = ({
  className,
  label,
  error,
  toolbarOptions,
  value,
  onChange,
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  return (
    <div className={clsx(s.field, hasFocus && s.focus, error && s.error)}>
      {label && <span className={s.label}>{label}</span>}
      <Editor
        className={className}
        value={value}
        onChange={onChange}
        onFocusChange={setHasFocus}
        toolbarOptions={toolbarOptions}
      />
    </div>
  );
};

export const Editor: FC<{
  value: string;
  onChange: (value: string) => void;
  onFocusChange?: (focus: boolean) => void;
  toolbarOptions?: IToolBarOptions | keyof typeof toolbarOptionTypes;
  className?: string;
}> = ({ value, onChange, onFocusChange, toolbarOptions = 'full', className }) => {
  const enabledToolbarOptions: IToolBarOptions = isToolbarOptionsObject(toolbarOptions)
    ? toolbarOptions
    : toolbarOptionTypes[toolbarOptions];

  const extensions: Extensions = [StarterKit, HorizontalRule];
  if (enabledToolbarOptions.link) {
    extensions.push(
      Link.configure({
        openOnClick: false,
      })
    );
  }
  if (enabledToolbarOptions.textAlign) {
    extensions.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      })
    );
  }
  if (enabledToolbarOptions.image) {
    extensions.push(
      UploadImage(getService('party').uploadImage).configure({
        HTMLAttributes: {
          loading: 'lazy',
        },
      })
    );
  }

  const editor = useEditor({
    extensions,
    content: value,
    onUpdate: ({ editor }) => onChange(editor.getHTML()),
    onFocus: () => onFocusChange?.(true),
    onBlur: () => onFocusChange?.(false),
  });

  const hasToolbar = toolbarOptions !== 'none';

  return (
    <>
      {hasToolbar && <Toolbar editor={editor} {...enabledToolbarOptions} />}
      <EditorContent
        editor={editor}
        className={clsx(s.editor, hasToolbar && s.hasToolbar, className)}
      />
    </>
  );
};
