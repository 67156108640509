import React, { FC } from 'react';

import { Link } from '#root/Components/Link';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

export const Footer: FC<{ className: string }> = ({ className }) => {
  const t = useTranslation();
  const canEdit = useStoreState(state => state.party.canEdit);
  return (
    <div className={className}>
      {!canEdit ? <Link to="/new/party">{t(Keys.party.footer.create)}</Link> : null}
      <span>© 2016 - {new Date().getFullYear()} invii.me</span>
    </div>
  );
};
