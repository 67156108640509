import React, { FC } from 'react';

import { Rsvps } from './Rsvps';

import { RedirectIfNotOwner } from '#root/Components/Redirect';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

const PartyRSVP: FC = () => {
  const t = useTranslation();
  usePartyTitle(t(Keys.admin.rsvp.title));

  return (
    <RedirectIfNotOwner>
      <Rsvps />
    </RedirectIfNotOwner>
  );
};

export default PartyRSVP;
