import { Button, TextField, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Container } from '#root/Components/Container';
import { Flex, Item } from '#root/Components/Flex';
import { Link } from '#root/Components/Link';
import { useRedirectIfLoggedIn } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { api } from '#root/utils/api';

export interface IPasswordForm {
  email: string;
}

export const PasswordForm: FC = () => {
  useRedirectIfLoggedIn();
  const t = useTranslation();
  const { register, handleSubmit } = useForm<IPasswordForm>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onSubmit: SubmitHandler<IPasswordForm> = data => {
    if (submitting) return;
    setSubmitting(true);
    setError(null);
    setSuccess(false);

    api
      .post('/recover-password', {
        json: {
          email: data.email,
        },
      })
      .json()
      .then((json: any) => {
        setSubmitting(false);
        if (json.status) {
          setSuccess(true);
        } else if (json.error) {
          setError(json.error);
        }
      });
  };

  return (
    <Container small marginTop>
      <h2>{t(keys => keys.login.recover.title)}</h2>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap>
          {error && <Typography color="error">{error}</Typography>}
          {success && (
            <Typography color="primary">{t(keys => keys.login.recover.success)}</Typography>
          )}
          <TextField
            variant="outlined"
            fullWidth
            label={t(keys => keys.generic.email)}
            name="email"
            autoComplete="email"
            autoFocus
            inputProps={register('email', { required: true })}
          />
          <Button
            disableElevation
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            {t(keys => keys.login.recover.action)}
          </Button>
          <Flex className="spacing-top-md" justify="space-between">
            <Item>
              <Link to="/login">{t(keys => keys.login.login)}</Link>
            </Item>
            <Item>
              <Link to="/register">{t(keys => keys.login.signup)}</Link>
            </Item>
          </Flex>
        </Flex>
      </form>
    </Container>
  );
};
