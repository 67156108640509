import { createBrowserHistory } from '@remix-run/router';
import { Listener } from '@remix-run/router/dist/history';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { idParamIsParty } from '#root/utils/idParamIsParty';

const reactRoutes = ['new', 'login', 'register', 'password', 'account', 'parties'];

// The frontpage has static content delivered by the server.
// When browser goes back from "some" url to frontpage, react router does not refresh, and then we do not have the correct static content.
// This should also be used on other pages, where we deliver static SEO content.
export const FrontpageNavigationFix = () => {
  const location = useLocation();

  useEffect(() => {
    const history = createBrowserHistory();
    // This will only listen on pop states forward/backward.
    const onPop: Listener = e => {
      const [, idOrLang, ...rest] = e.location.pathname.split('/');

      const isFrontpage = !idParamIsParty(idOrLang) && rest.length === 0;

      if (isFrontpage) {
        window.location.reload();
      }
    };

    return history.listen(onPop);
  }, []);

  useEffect(() => {
    const bodyDom = document.body;
    if (!bodyDom) {
      return;
    }

    const [, idOrLang, route] = location.pathname.split('/');
    const isParty = idParamIsParty(idOrLang);
    if (isParty || reactRoutes.includes(route)) {
      // Going away from frontpage, ensure the static content is hidden.
      bodyDom.classList.add('static-hidden');
    } else {
      bodyDom.classList.remove('static-hidden');
    }
  }, [location]);

  return null;
};
