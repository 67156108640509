import React, { FC, PropsWithChildren } from 'react';

import s from './styles.module.scss';
type ButtonProps = React.ComponentPropsWithoutRef<'button'>;

export const TextButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...rest }) => {
  return (
    <button className={s.button} {...rest}>
      {children}
    </button>
  );
};
