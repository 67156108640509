import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { IPartyWizardInput } from '.';

import { AddressAutocomplete } from '#root/Components/AddressAutocomplete';
import { getService } from '#root/store';

export interface IProps {
  name: 'location' | 'churchLocation';
  required?: boolean;
}

export const Location: FC<IPartyWizardInput & IProps> = ({ control, name, required }) => {
  return (
    <span className="Wizard_Input">
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { value, onChange } }) => (
          <AddressAutocomplete
            inputProps={{ variant: 'standard', autoComplete: 'off' }}
            language={getService('translation').currentLanguage()}
            defaultValue={value?.address}
            onChange={onChange}
          />
        )}
      />
    </span>
  );
};
