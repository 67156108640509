import { useCallback, useEffect } from 'react';
import { FieldValues, SubmitHandler, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';

import { useDebounce } from './use-debounce';

export function useAutoSubmit<T extends FieldValues>(
  watch: UseFormWatch<T>,
  handleSubmit: UseFormHandleSubmit<T>,
  onSubmit: SubmitHandler<T>,
  //   ref: RefObject<HTMLFormElement>,
  enabled: boolean | (() => boolean) = true,
  delay = 1000
) {
  const debouncedFunc = useDebounce(() => {
    const shouldSubmit = typeof enabled === 'function' ? enabled() : enabled;

    if (shouldSubmit) {
      handleSubmit(onSubmit)();
    }
  }, delay);

  const externalTrigger = useCallback(() => {
    debouncedFunc();
  }, [debouncedFunc]);

  useEffect(() => {
    const subscription = watch(() => debouncedFunc());
    return () => {
      subscription.unsubscribe();
    };
  }, [debouncedFunc, watch, enabled]);

  return externalTrigger;
}
