import { Button, DialogActions, DialogContent, FormLabel, MenuItem, Select } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ManualSelection } from './ManualSelection';
import { SendMessageButton } from './SendMessageButton';
import { SendMessageResponse } from './SendMessageResponse';
import { SendMessageSummary } from './SendMessageSummary';

import { Flex } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { Link } from '#root/Components/Link';
import { Loading } from '#root/Components/Loading';
import { useFetch } from '#root/hooks/use-fetch';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IContact, IMessageTemplate, type MessageToWhomType } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { createFakeDelayPromise } from '#root/utils/wait';

type StatResult = {
  [key in MessageToWhomType]: {
    contacts: number;
    ids?: string[];
    mail: number;
    text: number;
    tokens: number;
  };
} & {
  tokensPrContact: number;
};

export const SendMessage: FC<{ template: IMessageTemplate; onClose: () => void }> = ({
  template,
  onClose,
}) => {
  const userEmailVerified = useStoreState(state => state.app.currentUser?.email_verified);
  useEffect(() => {
    if (!userEmailVerified) {
      // Every time this component is mounted, we should try to reload the verification status.
      getService('app').reloadUser();
    }
  }, [userEmailVerified]);
  const party = useEditableParty();
  const manualSelection = useStoreState(state => state.messages.manualSelection);
  const manualSelectionCount = useMemo(
    () =>
      Object.keys(manualSelection).reduce(
        (count, id) => {
          count.contacts++;
          if (manualSelection[id]?.email) {
            count.mail++;
          }
          if (manualSelection[id]?.text) {
            count.text++;
          }
          return count;
        },
        { contacts: 0, mail: 0, text: 0 }
      ),
    [manualSelection]
  );
  const [isSending, setIsSending] = useState(false);
  const [error, setError] = useState<string>();
  const [response, setResponse] = useState<{
    tokensUsed: number;
    emailsSent: number;
    textSent: number;
    errors: number;
    contacts: IContact[];
  } | null>();
  const [sendingSuccessfull, setSendingSuccessfull] = useState(false);
  const { data: stat } = useFetch<StatResult | null>(
    `/api/party/${party.id}/messages/presend/${template.id}`,
    null
  );

  const [toWhom, setToWhom] = useState<MessageToWhomType>('missing');
  const t = useTranslation();

  const doSend = useCallback(
    async (omitSms = false) => {
      if (isSending) return;
      setResponse(null);
      setIsSending(true);

      // Ensure the loading is shown for at least xx seconds.
      const fakeDelay = createFakeDelayPromise(3000);

      await getService('messages')
        .send(party.id, template.id, toWhom, omitSms)
        .then(async response => {
          await fakeDelay;
          setResponse(response);
          setSendingSuccessfull(true);
        })
        .catch(async error => {
          if (error.response) {
            const message = await error.response.json();
            setError(message);
          }
        });
      setIsSending(false);
    },
    [party, template, toWhom, isSending]
  );

  const doSendAll = useCallback(() => doSend(), [doSend]);
  const doSendWithoutSms = useCallback(() => doSend(true), [doSend]);

  if (isSending) {
    return (
      <>
        <DialogContent>
          <Flex column gap align="center" className={clsx(css.spaceBottomL, css.spaceTopL)}>
            <Loading size="large" />
            {t(Keys.admin.message.send.description)}
          </Flex>
        </DialogContent>
      </>
    );
  }
  if (sendingSuccessfull) {
    return (
      <>
        <DialogContent>
          {response ? <SendMessageResponse response={response} templateId={template.id} /> : null}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" disableElevation onClick={onClose} color="secondary">
            {t(Keys.generic.ok)}
          </Button>
        </DialogActions>
      </>
    );
  }

  const templateHasTextMessage = template.text.trim().length > 1;

  const tokensToUse =
    templateHasTextMessage && stat
      ? toWhom === 'custom'
        ? stat.tokensPrContact * manualSelectionCount.text
        : stat[toWhom].tokens
      : 0;

  const sendingResult = {
    ids: toWhom === 'custom' ? undefined : stat?.[toWhom].ids,
    contacts: toWhom === 'custom' ? manualSelectionCount.contacts : stat?.[toWhom].contacts ?? 0,
    mail: toWhom === 'custom' ? manualSelectionCount.mail : stat?.[toWhom].mail ?? 0,
    text: templateHasTextMessage
      ? toWhom === 'custom'
        ? manualSelectionCount.text
        : stat?.[toWhom].text ?? 0
      : 0,
  };

  return (
    <>
      <DialogContent>
        <Flex gap align="center" stackOnMobile>
          <FormLabel htmlFor="send-to">{t(Keys.admin.message.send_to.label)}</FormLabel>
          <Select
            name="send-to"
            size="small"
            value={toWhom}
            onChange={e => setToWhom(e.target.value as MessageToWhomType)}
          >
            <MenuItem value="all">
              {t(Keys.admin.message.send_to.all) + (stat ? ` (${stat?.all.contacts})` : '')}
            </MenuItem>
            <MenuItem value="missing">
              {t(Keys.admin.message.send_to.missing) + (stat ? ` (${stat?.missing.contacts})` : '')}
            </MenuItem>
            <MenuItem value="custom">{t(Keys.admin.message.send_to.custom)}</MenuItem>
            <MenuItem value="rsvp">
              {t(Keys.admin.message.send_to.rsvp) + (stat ? ` (${stat?.rsvp.contacts})` : '')}
            </MenuItem>
            <MenuItem value="withoutRsvp">
              {t(Keys.admin.message.send_to.without_rsvp) +
                (stat ? ` (${stat?.withoutRsvp.contacts})` : '')}
            </MenuItem>
            <MenuItem value="rsvpYes">
              {t(Keys.admin.message.send_to.rsvp_yes) +
                (stat ? ` (${stat?.rsvpYes.contacts})` : '')}
            </MenuItem>
            <MenuItem value="rsvpNo">
              {t(Keys.admin.message.send_to.rsvp_no) + (stat ? ` (${stat?.rsvpNo.contacts})` : '')}
            </MenuItem>
          </Select>
        </Flex>
        {error ? (
          <InfoBox className={css.spaceTopM} type="error">
            {error}
          </InfoBox>
        ) : null}
        {!userEmailVerified ? (
          <InfoBox className={css.spaceTopM} type="error">
            {t(Keys.admin.message.test.verify_account)}{' '}
            <Link to="/account">{t(keys => keys.admin.message.test.account_link)}</Link>
          </InfoBox>
        ) : null}

        {toWhom === 'custom' ? (
          <Flex column className={css.spaceTopL}>
            <ManualSelection />
            <Flex column align="center">
              <SendMessageSummary
                tokensInAccount={party.tokens}
                people={sendingResult.contacts}
                mails={sendingResult.mail}
                texts={sendingResult.text}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex column align="center">
            <div className={css.spaceTopL}>
              {stat ? (
                <SendMessageSummary
                  tokensInAccount={party.tokens}
                  people={sendingResult.contacts}
                  ids={sendingResult.ids}
                  mails={sendingResult.mail}
                  texts={sendingResult.text}
                />
              ) : (
                <Loading size="large" />
              )}
            </div>
          </Flex>
        )}
      </DialogContent>
      <DialogActions>
        <SendMessageButton
          amountToPay={tokensToUse - Math.max(0, party.tokens)}
          onSend={doSendAll}
          onSendWithoutSms={doSendWithoutSms}
          skipEnabled={sendingResult.mail > 0 && sendingResult.text > 0}
          disabled={
            !stat ||
            (stat[toWhom]?.contacts ?? manualSelectionCount.contacts) === 0 ||
            !userEmailVerified
          }
        />
      </DialogActions>
    </>
  );
};
