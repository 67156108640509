import { ButtonBase } from '@mui/material';
import { parseISO } from 'date-fns';
import React, { FC, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import s from './index.module.scss';

import { Container } from '#root/Components/Container';
import { DateTimeView } from '#root/Components/DateTimeView';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useNavigate } from '#root/hooks/use-navigate';
import { useRedirectIfLoggedOut } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

export const Parties: FC = () => {
  useRedirectIfLoggedOut();
  const t = useTranslation();
  useEffect(() => {
    getService('app').loadParties();
  }, []);
  const parties = useStoreState(state => state.app.parties);
  const navigate = useNavigate();
  const sortedParties = useMemo(() => {
    if (!parties) return null;

    return Object.values(parties).sort((a, b) => {
      const d1 = parseISO(a.date);
      const d2 = parseISO(b.date);
      return d2.getTime() - d1.getTime();
    });
  }, [parties]);

  return (
    <Container>
      <h2>
        <Flex gap align="center">
          {t(keys => keys.page.parties.title)}
          {!sortedParties ? <Loading /> : null}
        </Flex>
      </h2>
      <div className={s.grid}>
        <ButtonBase onClick={() => navigate('/new/party')} className={s.new}>
          {t(Keys.page.parties.create)}
        </ButtonBase>
        {sortedParties?.map(party => (
          <ButtonBase
            component={Link}
            to={`/${party.slug ?? party.sid}`}
            key={party.id}
            style={party.heroImage ? { backgroundImage: `url(${party.heroImage})` } : undefined}
          >
            <h3>{party.title}</h3>
            <DateTimeView date={party.date} />
            {party.plan !== 'free' ? (
              <span className={s[party.plan]}>{t(Keys.plan[party.plan].name)}</span>
            ) : null}
            {party.isCohost ? (
              <span className={s.cohost}>{t(Keys.page.parties.cohost)}</span>
            ) : null}
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
};
