import React, { FC, useEffect, useState } from 'react';
import { Outlet, Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';

import s from './App.module.scss';
import { Account } from './Routes/Account';
import { ChangePassword } from './Routes/Account/ChangePassword';
import { Cohost } from './Routes/Cohost';
import { NewParty } from './Routes/NewParty';
import { Parties } from './Routes/Parties';
import PartyRoute from './Routes/Party';
import PartyContactsRoute from './Routes/PartyContacts';
import PartyInvitationsRoute from './Routes/PartyInvitations';
import PartyRSVPRoute from './Routes/PartyRSVP';
import PartySettingsRoute from './Routes/PartySettings';
import PhotosRoute from './Routes/Photos';
import { PlanChooser } from './Routes/PlanChooser';
import WishlistRoute from './Routes/Wishlist';
import { PartyWizardRoute } from './Routes/Wizard';

import { ExposeRouterNavigate } from '#root/Components/ExposeRouterNavigate';
import { Flex } from '#root/Components/Flex';
import { FrontpageNavigationFix } from '#root/Components/FrontpageNavigationFix';
import { GlobalLoading } from '#root/Components/GlobalLoading';
import { HelpPage } from '#root/Components/Help/HelpPage';
import { Loading } from '#root/Components/Loading';
import { Menu } from '#root/Components/Menu';
import { PromptLoginProvider } from '#root/Components/PromptLoginProvider';
import { LoginForm } from '#root/Modules/Login/LoginForm';
import { PasswordForm } from '#root/Modules/Login/PasswordForm';
import { RegisterForm } from '#root/Modules/Login/RegisterForm';
// import { useThemeColors } from '#root/hooks/use-theme-colors';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { parameters } from '#root/store/parameters';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { idParamIsParty } from '#root/utils/idParamIsParty';

const Wrapper: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  // const themeColors = useThemeColors();

  // const theme = {
  //   '--party-theme-foreground': themeColors?.foreground ?? 'var(--color-foreground)',
  //   '--party-theme-primary': themeColors?.primary ?? 'var(--color-primary)',
  //   '--party-theme-primary-contrast': themeColors?.primary ?? 'var(--color-foreground)',
  //   '--party-theme-background': themeColors?.background ?? 'var(--color-background)',
  // } as React.CSSProperties;

  return (
    <>
      <FrontpageNavigationFix />
      {/* <ThemeProvider
        theme={(outer: Theme) =>
          themeColors
            ? createTheme({
                ...outer,
                palette: {
                  ...outer.palette,
                  primary: { main: themeColors.primary, contrastText: themeColors.primaryContrast },
                },
              })
            : outer
        }
      > */}
      <div className={s.app}>
        <Menu isLoading={isLoading} />

        {!isLoading ? <Outlet /> : null}
      </div>
      {/* </ThemeProvider> */}
    </>
  );
};

const LangWrapper: FC = () => {
  const { idOrLang } = useParams<{ idOrLang: string }>();

  if (!idOrLang || idParamIsParty(idOrLang)) {
    return <></>;
  }

  return <Outlet />;
};

export const PartyWrapper: FC = () => {
  const { idOrLang } = useParams<{ idOrLang: string }>();
  const [notFound, setNotFound] = useState(parameters.errorUrl === idOrLang);
  const t = useTranslation();
  useEffect(() => {
    const id = idOrLang && idParamIsParty(idOrLang) ? idOrLang : null;
    if (!notFound && id) {
      getService('party')
        .load(id)
        .catch(() => {
          setNotFound(true);
        });
    }
  }, [idOrLang]);

  const isLoaded = useStoreState(state => !!state.party.party?.id);

  // Check if we are a party route or a language route.
  if (!idOrLang || !idParamIsParty(idOrLang)) {
    return <></>;
  }

  if (notFound) {
    return (
      <Flex column align="center" className={css.spaceTopL}>
        <p>{t(Keys.page.not_found)}</p>
      </Flex>
    );
  }

  if (!isLoaded) {
    return (
      <Flex column align="center" className={css.spaceTopL}>
        <Loading size="large" />
      </Flex>
    );
  }

  return <Outlet />;
};

const Static = () => {
  // This route will match everything else.
  // Two things to note:
  // 1. This will ensure that the static content which was hidden, will be shown.
  // 2. Any route, even a Layout route will NOT render if not even a single route will match.
  document.getElementById('static')?.classList.remove('hidden');
  return <></>;
};

export const App: FC = () => {
  const isInititalized = useStoreState(state => state.app.isInititalized);
  useEffect(() => {
    getService('app').loadInitial();
  }, []);

  return (
    <>
      <PromptLoginProvider />
      <Router>
        <ExposeRouterNavigate />
        <Routes>
          <Route element={<Wrapper isLoading={!isInititalized} />}>
            <Route path="/:idOrLang" Component={PartyWrapper}>
              <Route path="" Component={PartyRoute}>
                <Route path="wishlist" Component={WishlistRoute} />
                <Route path="photos" Component={PhotosRoute} />
              </Route>
              <Route path="contacts" Component={PartyContactsRoute} />
              <Route path="invitations" Component={PartyInvitationsRoute} />
              <Route path="rsvps" Component={PartyRSVPRoute} />
              <Route path="settings" Component={PartySettingsRoute} />
              <Route path="plan" Component={PlanChooser} />
              <Route path="cohost/:id/:secret" Component={Cohost} />
            </Route>
            <Route path="/:idOrLang" Component={LangWrapper}>
              <Route path="new/:type" Component={PartyWizardRoute} />
              <Route path="new/:type/edit" Component={NewParty} />
              <Route path="login" Component={LoginForm} />
              <Route path="register" Component={RegisterForm} />
              <Route path="password" Component={PasswordForm} />
              <Route path="change-password" Component={ChangePassword} />
              <Route path="account" Component={Account} />
              <Route path="parties" Component={Parties} />
              <Route path="help" Component={HelpPage} />
              <Route path="*" Component={Static} />
            </Route>
          </Route>
        </Routes>
      </Router>
      <GlobalLoading className={s.globalLoading} />
    </>
  );
};
