import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { Flex } from '../Flex';
import { InfoBox } from '../InfoBox';
import { Loading } from '../Loading';

import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';

export interface IProps {
  onClose: () => void;
  phoneNumber: string;
}

export const VerifyPhone: FC<IProps> = ({ onClose, phoneNumber }) => {
  const t = useTranslation();
  const [smsSend, setSmsSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [code, setCode] = useState('');
  const onCodeChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => setCode(e.target.value),
    []
  );
  const onVerificationCheck = useCallback(() => {
    getService('app')
      .verifyPhoneCode(code)
      .then(() => {
        setSuccess(true);
        onClose();
      })
      .catch(() => {
        setSuccess(false);
      });
  }, [code]);
  const onVerify = useCallback(async () => {
    setLoading(true);
    await getService('app')
      .beginVerifyPhone()
      .then(() => setSmsSend(true))
      .catch(async error => {
        const body = await error.response.json();
        if (body) {
          alert(body);
        }
      });
    setTimeout(() => setLoading(false), 3000);
  }, []);

  return (
    <>
      <DialogContent>
        <Flex column align="center">
          {success === false && (
            <InfoBox type="warning">{t(keys => keys.verify.phone.error)}</InfoBox>
          )}
          {loading && <Loading size="large" />}
          {!loading && !smsSend && (
            <p>{t(keys => keys.verify.phone.description2, { phoneNumber })}</p>
          )}
          {!loading && smsSend && (
            <>
              <p>{t(keys => keys.verify.phone.description)}</p>
              <TextField value={code} onChange={onCodeChange} placeholder="xxxxx" />
            </>
          )}
        </Flex>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t(keys => keys.generic.close)}</Button>
        {!loading && !success && smsSend && (
          <Button
            variant="contained"
            color="primary"
            onClick={onVerificationCheck}
            disabled={code.length !== 6}
            disableElevation
          >
            {t(keys => keys.verify.phone.title)}
          </Button>
        )}
        {!loading && !success && !smsSend && (
          <Button variant="contained" color="primary" onClick={onVerify} disableElevation>
            {t(keys => keys.verify.phone.action)}
          </Button>
        )}
      </DialogActions>
    </>
  );
};
