import { createBrowserHistory } from '@remix-run/router';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import { App } from './App';
import { ThemeProvider } from './ThemeProvider';

import { AlertProvider } from '#root/hooks/use-alert';
import { ConfirmProvider } from '#root/hooks/use-confirm';
import { registerUrlParams } from '#root/hooks/use-feature';
import { store } from '#root/store';

createBrowserHistory();

registerUrlParams('PHOTOS');
registerUrlParams('ANDERS');

export const Bootstrap: React.FC = () => {
  return (
    <ThemeProvider>
      <ConfirmProvider>
        <AlertProvider>
          <Provider store={store}>
            <Suspense fallback={null}>
              <App />
            </Suspense>
          </Provider>
        </AlertProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
};
