import {
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import s from './LoginForm.module.scss';

import { Container } from '#root/Components/Container';
import { Flex, Item } from '#root/Components/Flex';
import { Link } from '#root/Components/Link';
import FacebookLogo from '#root/Images/Facebook.png';
import { ReactComponent as GoogleSvg } from '#root/Images/Google.svg';
import { usePopup } from '#root/hooks/use-popup';
import { useRedirectIfLoggedIn } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { DoneIcon } from '#root/utils/icons';
import { isMobile } from '#root/utils/is-mobile';

export interface IRegisterForm {
  email: string;
  password: string;
  accept_terms: boolean;
  marketing_consent: boolean;
  name: string;
}

export interface IProps {
  withoutTitle?: boolean;
}

export const RegisterForm: FC<IProps> = ({ withoutTitle }) => {
  useRedirectIfLoggedIn();
  const [selectedSocial, setSelectedSocial] = useState<'facebook' | 'google' | undefined>();
  const onLoginMessage = useCallback((event: MessageEvent<any>) => {
    const { data } = event;
    if (data.source === 'login-connect-success') {
      getService('app').loadUser();
    }
  }, []);
  const { open: openPopup } = usePopup('login', onLoginMessage);
  const t = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<IRegisterForm>({
    defaultValues: {
      email: '',
      password: '',
      name: '',
      accept_terms: false,
      marketing_consent: false,
    },
  });
  const [submitting, setSubmitting] = useState(false);
  const onSubmit: SubmitHandler<IRegisterForm> = data => {
    if (submitting || !acceptTerms) return;

    if (selectedSocial) {
      openPopup(
        `/connect/${selectedSocial}?_destination=/login/connect-login-success&state=${btoa(
          JSON.stringify({
            type: 'signup',
            marketingConsent,
            language: getService('translation').currentLanguage(),
          })
        )}`
      );
    } else {
      setSubmitting(true);
      getService('app')
        .register(data)
        .then(() => {
          setSubmitting(false);
        })
        .catch(async error => {
          setSubmitting(false);
          if (error.response) {
            try {
              const body = await error.response.json();

              if (body.errors) {
                for (const [field, message] of Object.entries<string>(body.errors)) {
                  setError(field as any, { type: 'custom', message });
                }
              } else {
                if (typeof body === 'string') {
                  setError('root', { type: 'custom', message: body });
                } else {
                  console.error(body); // Try to track what is going on here.
                  captureException(body);
                  setError('root', { type: 'custom', message: t(Keys.generic.error_try_again) });
                }
              }
            } catch (e) {
              console.error(error); // Try to track what is going on here.
              captureException(e);
            }
          } else {
            console.error(error);
            captureException(error);
          }
        });
    }
  };

  const watchFields = watch(['email', 'password', 'name', 'accept_terms']);
  const marketingConsent = watch('marketing_consent');
  const acceptTerms = watchFields[3];
  const submitDisabled = selectedSocial ? !acceptTerms : watchFields.some(field => !field);

  return (
    <Container small marginTop>
      {!withoutTitle ? <h2>{t(keys => keys.signup.create_account)}</h2> : null}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex column gap>
          <Flex justify="center" className="spacing-top-md">
            <Item>
              <Link to="/login">{t(keys => keys.login.already_have_account)}</Link>
            </Item>
          </Flex>
          <Flex column gap className={s.socialLogins}>
            <ButtonBase
              onClick={() =>
                setSelectedSocial(prev => (prev === 'facebook' ? undefined : 'facebook'))
              }
              className={clsx(s.facebook, s.socialButton)}
              color="secondary"
            >
              <div>
                <img src={FacebookLogo} /> {t(keys => keys.signup.facebook)}
                {selectedSocial === 'facebook' && <DoneIcon />}
              </div>
            </ButtonBase>
            <ButtonBase
              onClick={() => setSelectedSocial(prev => (prev === 'google' ? undefined : 'google'))}
              className={clsx(s.google, s.socialButton)}
              color="secondary"
            >
              <div>
                <GoogleSvg /> {t(keys => keys.signup.google)}
                {selectedSocial === 'google' && <DoneIcon />}
              </div>
            </ButtonBase>
          </Flex>
          {!selectedSocial ? (
            <>
              <div className={s.divider}>
                <div className={s.line}></div>
                <div className={s.or}>{t(keys => keys.login.or)}</div>
              </div>
              <TextField
                variant="outlined"
                fullWidth
                label={t(keys => keys.generic.name)}
                autoFocus={!isMobile()}
                required
                inputProps={register('name', { required: true })}
              />
              <TextField
                variant="outlined"
                fullWidth
                label={t(keys => keys.generic.email)}
                type="email"
                autoComplete="email"
                required
                inputProps={register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t(keys => keys.generic.email_error),
                  },
                })}
                error={!!errors.email}
                color={errors.email ? 'error' : 'primary'}
                helperText={errors.email ? errors.email.message : ''}
              />
              <TextField
                variant="outlined"
                fullWidth
                label={t(keys => keys.generic.password)}
                type="password"
                required
                inputProps={register('password', {
                  required: true,
                  minLength: {
                    value: 8,
                    message: t(keys => keys.signup.password.error),
                  },
                })}
                error={!!errors.password}
                color={errors.password ? 'error' : 'primary'}
                helperText={
                  errors.password ? errors.password.message : t(keys => keys.signup.password.helper)
                }
              />
            </>
          ) : null}

          <FormControlLabel
            color={errors.accept_terms ? 'error' : 'primary'}
            control={
              <Controller
                control={control}
                rules={{ required: true }}
                name="accept_terms"
                render={({ field: { value, ...rest } }) => <Checkbox checked={value} {...rest} />}
              />
            }
            label={
              <span dangerouslySetInnerHTML={{ __html: t(keys => keys.signup.terms) + ' *' }} />
            }
          />
          {errors.accept_terms ? (
            <Typography color="error">{errors.accept_terms.message}</Typography>
          ) : null}

          <FormControlLabel
            control={
              <Controller
                control={control}
                name="marketing_consent"
                render={({ field: { value, ...rest } }) => (
                  <Checkbox color="primary" checked={value} {...rest} />
                )}
              />
            }
            label={t(keys => keys.page.account.marketing_consent)}
          />
          {errors.root ? <Typography color="error">{errors.root.message}</Typography> : null}
          <Button
            disableElevation
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={submitDisabled || submitting}
          >
            {t(keys => keys.signup.create_account)}
          </Button>
        </Flex>
      </form>
    </Container>
  );
};
