import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { Flex } from '../Flex';

import s from './BubbleHeader.module.scss';

export const BubbleHeader: FC<PropsWithChildren<{ className?: string; bordered?: boolean }>> = ({
  children,
  className,
  bordered,
}) => {
  return (
    <Flex gap align="center" className={clsx(className, s.root, bordered && s.bordered)}>
      {children}
    </Flex>
  );
};
