import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import s from './index.module.scss';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export interface IProps {
  isPremium?: boolean;
  blured?: boolean;
  className?: string;
}

export const PremiumLabel: FC<IProps> = ({ isPremium, blured, className }) => {
  const t = useTranslation();
  const spaan = (
    <span className={clsx(s.root, blured && s.blured, className)}>{t(Keys.plan.premium.name)}</span>
  );

  if (isPremium) {
    return <></>;
  }

  return <Tooltip title={t(Keys.plan.premium.label)}>{spaan}</Tooltip>;
};
