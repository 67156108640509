import { Button, Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import isMobilePhone from 'validator/es/lib/isMobilePhone';

import { Box } from '#root/Components/Box';
import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Flex } from '#root/Components/Flex';
import { LazyButton } from '#root/Components/LazyButton';
import { Loading } from '#root/Components/Loading';
import { VerifyPhone } from '#root/Components/VerifyPhone';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IUser } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { SendIcon } from '#root/utils/icons';

export const UserForm: FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const user = useStoreState(state => state.app.currentUser);
  const t = useTranslation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<IUser>({
    mode: 'onChange',
    defaultValues: user,
  });

  const onSubmit: SubmitHandler<IUser> = data => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    getService('app')
      .saveUser(data)
      .then(user => reset(user))
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <Box bordered marginBottom>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>{t(Keys.page.account.user)}</h2>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={t(Keys.generic.name)}
          inputProps={register('name', { required: true })}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={t(Keys.generic.email)}
          type="email"
          autoComplete="email"
          inputProps={register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: t(Keys.generic.email_error),
            },
          })}
          helperText={
            errors.email ? (
              errors.email.message
            ) : !user.email_verified ? (
              <Flex gap align="center">
                <Tooltip title={t(Keys.page.account.resend_verification)}>
                  <span>
                    <LazyButton
                      loadingWhenDisabled
                      size="small"
                      onClick={() => getService('app').verifyEmail()}
                    >
                      <SendIcon fontSize="inherit" />
                    </LazyButton>
                  </span>
                </Tooltip>
                {t(Keys.page.account.resend_verification)}
              </Flex>
            ) : undefined
          }
          color={!user.email_verified || errors.email ? 'error' : 'primary'}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={t(Keys.generic.phone)}
          type="tel"
          autoComplete="tel"
          placeholder="+45"
          error={!!errors.phone}
          inputProps={register('phone', {
            validate: value =>
              !value ||
              isMobilePhone(value, 'any', { strictMode: true }) ||
              (value.charAt(0) !== '+'
                ? t(Keys.generic.phone_error_start_with)
                : t(Keys.generic.phone_error)),
          })}
          helperText={
            errors.phone ? (
              errors.phone.message
            ) : !user.phone_verified && user.phone?.length && !isDirty ? (
              <ButtonDialog
                header={t(Keys.page.account.verify_number)}
                buttonText={t(Keys.page.account.verify_number)}
                withCloseButton
              >
                {onClose => <VerifyPhone phoneNumber={user.phone ?? ''} onClose={onClose} />}
              </ButtonDialog>
            ) : undefined
          }
        />

        <FormControlLabel
          control={
            <Controller
              control={control}
              name="marketingConsent"
              render={({ field: { value, ...rest } }) => (
                <Checkbox color="primary" checked={value} {...rest} />
              )}
            />
          }
          label={t(Keys.page.account.marketing_consent)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!isDirty || submitting || !isValid}
          disableElevation
        >
          {submitting ? <Loading /> : t(Keys.generic.save)}
        </Button>
      </form>
    </Box>
  );
};
