import { format, formatISO, parseISO } from 'date-fns';
import React, { FC } from 'react';

import s from './index.module.scss';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export const DateTimeView: FC<{ date: string | undefined }> = ({ date }) => {
  const t = useTranslation();
  const months = t(Keys.generic.months).split('|');
  if (!date) {
    console.error('DateTimeView has no date');
    return <></>;
  }

  const transformedDate = parseISO(date);
  return (
    <time dateTime={formatISO(transformedDate)}>
      <div className={s.date}>
        <div className={s.day}>{format(transformedDate, 'd')}</div>
        <div className={s.month}>{months[transformedDate.getMonth()]}</div>
        <div className={s.year}>{format(transformedDate, 'yyyy')}</div>
      </div>
      {/* <div className={s.time}>{format(transformedDate, 'HH:mm')}</div> */}
    </time>
  );
};
