import { Dialog, DialogContent, DialogTitle, IconButton, Switch } from '@mui/material';
import React, { FC, useCallback } from 'react';

import { WishlistForm } from './components/Form';
import { WishlistList } from './components/List';
import s from './index.module.scss';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useConfirm } from '#root/hooks/use-confirm';
import { useDataList } from '#root/hooks/use-data-list';
import { useNavigate } from '#root/hooks/use-navigate';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useRedirectIfNotSupports } from '#root/hooks/use-redirect-if-logged-in';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IParty, IWish } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon } from '#root/utils/icons';

const Wishlist: FC = () => {
  const party = useStoreState(state => state.party.party as IParty);
  const canEdit = useStoreState(state => state.party.canEdit);
  const t = useTranslation();
  const navigate = useNavigate();
  usePartyTitle(t(keys => keys.party.wishlist.title));
  useRedirectIfNotSupports('wishlist');
  const { isConfirmed } = useConfirm();

  const {
    data: wishes,
    remove,
    move,
    set,
    loading,
  } = useDataList<IWish>(`/api/party/${party.id}/wishes`);

  const onMoveItem = useCallback(
    (wishId: string, from: number, to: number) => {
      if (from !== to) {
        getService('wishlist').move(party.id, wishId, to);
        move(from, to);
      }
    },
    [move]
  );

  const onChange = useCallback((wish: IWish) => {
    set(list => {
      if (list.length === 0) {
        // Ugly side effect. If this was the first item added, an action was added to the Party, so we need to refetch that.
        getService('party').refetch();
      }

      const isNew = list.every(i => i.id !== wish.id);
      return isNew ? [...list, wish] : list.map(i => (i.id === wish.id ? wish : i));
    });
  }, []);
  const wishesCount = wishes.length;
  const onDelete = useCallback(
    async (wish: IWish) => {
      if (await isConfirmed(Keys.party.wishlist.delete_confirm, Keys.generic.delete, 'error')) {
        getService('wishlist').delete(party.id, wish.id);
        remove(wish);

        if (wishesCount === 1) {
          // Ugly side effect. If this was the last item, the button was removed.
          getService('party').refetch();
        }
      }
    },
    [wishesCount]
  );

  const onClose = useCallback(() => navigate(`/${party.slug || party.sid}`, true), [party]);
  const onUpdateReservationsEnabled = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
      getService('party').saveSetting('wishlistReservationsEnabled', checked),
    [party]
  );

  return (
    <Dialog fullWidth open={true} onClose={onClose} disableEscapeKeyDown>
      <DialogTitle id="dialog-title" style={{ marginRight: 40 }}>
        <Flex>
          {t(keys => keys.party.wishlist.title)}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Flex>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Flex column align="center">
            <Loading size="large" />
          </Flex>
        ) : (
          <WishlistList
            list={wishes}
            moveItem={onMoveItem}
            onChange={onChange}
            onDelete={onDelete}
          />
        )}
      </DialogContent>
      {canEdit ? (
        <Flex
          gap
          justify="space-between"
          align="center"
          reverse
          className={css.spaceM}
          stackOnMobile
        >
          <ButtonDialog
            withCloseButton
            header={t(keys => keys.party.wishlist.add_title)}
            buttonText={t(keys => keys.party.wishlist.add_title)}
          >
            {onClose => <WishlistForm onCancel={onClose} onChange={onChange} />}
          </ButtonDialog>
          <div>
            <label className={s.reservationSwitch}>
              <Switch
                checked={party.wishlistReservationsEnabled}
                onChange={onUpdateReservationsEnabled}
                size="small"
                color="primary"
              />
              {t(keys => keys.party.wishlist.enable_reservations)}
            </label>
          </div>
        </Flex>
      ) : null}
    </Dialog>
  );
};

export default Wishlist;
