import { Person } from '@mui/icons-material';
import { Button, InputBase, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import s from './RsvpReplyForm.module.scss';

import { Bubble } from '#root/Components/Bubble';
import { BubbleHeader } from '#root/Components/Bubble/BubbleHeader';
import { BubbleTime } from '#root/Components/Bubble/BubbleTime';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IParty, IPublicRsvp } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { CloseIcon, DoneIcon } from '#root/utils/icons';

export interface IProps {
  rsvp: IPublicRsvp;
}
export const RsvpReplyForm: FC<IProps> = ({ rsvp }) => {
  const id = useStoreState(state => (state.party.party as IParty).id);
  const guestCountChoiceEnabled = useStoreState(
    state => (state.party.party as IParty).guestCountChoiceEnabled
  );
  const rsvpFields = useStoreState(state => (state.party.party as IParty).rsvpFields);

  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const {
    setError,
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<{ message: string; zip: string }>({
    defaultValues: {
      message: '',
    },
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<{ message: string; zip: string }> = async data => {
    if (submitting) return;

    const { zip, message } = data;
    if (zip.length || message.trim().length === 0) {
      return;
    }
    setSubmitting(true);
    getService('rsvp')
      .reply(id, rsvp.id, message)
      .then(responseRsvp => {
        getService('party').updateCurrentlyViewingRsvp(responseRsvp);
        reset();
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          if (body.errors) {
            for (const [field, message] of Object.entries<string>(body.errors)) {
              setError(field as any, { type: 'custom', message });
            }
          } else if (body.message) {
            alert(body.message);
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const renderedRsvpFields = rsvpFields.filter(
    rsvpField => rsvp.fields[rsvpField.id] !== undefined && rsvp.fields[rsvpField.id] !== ''
  );

  return (
    <div className={s.wrapper}>
      <h2>{t(Keys.party.blocks.rsvp.your_answer)}</h2>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <Bubble marginBottom right color="secondary" bold>
          <BubbleHeader bordered>
            <div>
              {rsvp.isComming ? (
                <DoneIcon fontSize="small" color="primary" />
              ) : (
                <CloseIcon fontSize="small" color="error" />
              )}
            </div>
            <div>{rsvp.name}</div>
            {guestCountChoiceEnabled && rsvp.isComming ? (
              <Flex align="center">
                <Person fontSize="small" />
                {rsvp.numberOfPeople}
              </Flex>
            ) : null}
          </BubbleHeader>
          {rsvp.comment ? <div className={s.comment}>{rsvp.comment}</div> : null}
          {renderedRsvpFields.length > 0 && rsvp.isComming ? (
            <div className={s.fields}>
              <Flex column gap>
                {renderedRsvpFields.map(({ id, name, type }) => (
                  <Flex align="center" gap key={id}>
                    <b>{name}:</b>{' '}
                    {type === 'text' ? (
                      rsvp.fields[id]
                    ) : rsvp.fields[id] ? (
                      <DoneIcon color="primary" />
                    ) : (
                      <CloseIcon />
                    )}
                  </Flex>
                ))}
              </Flex>
            </div>
          ) : null}
          <BubbleTime className={s.time} dateTime={rsvp.createdAt} />
        </Bubble>
        <div className={s.thread}>
          {rsvp.thread.map(message => (
            <Bubble
              key={message.id}
              right={!message.isHost}
              color={message.isHost ? 'primary' : 'secondary'}
              className={message.isHost ? s.isHost : s.isGuest}
              marginBottom
              bold
            >
              <div className={s.comment}>{message.message}</div>
              <BubbleTime className={s.time} dateTime={message.createdAt} />
            </Bubble>
          ))}

          <Bubble color="secondary" right className={s.isGuest}>
            <InputBase
              size="small"
              fullWidth
              multiline
              rows={3}
              inputProps={register('message', {
                required: true,
              })}
              placeholder={t(Keys.party.blocks.rsvp.new_message)}
            />
          </Bubble>
        </div>

        <TextField
          inputProps={register('zip')}
          style={{ visibility: 'hidden', height: 0 }}
          fullWidth={true}
          autoComplete="off"
          tabIndex={-1}
        />
        <Button
          disableElevation
          type="submit"
          disabled={submitting || !isValid}
          color="primary"
          variant="contained"
          className={s.button}
        >
          {submitting ? <Loading /> : t(Keys.generic.reply)}
        </Button>
      </form>
    </div>
  );
};
