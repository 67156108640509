import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import React, { FC, useRef } from 'react';

import { Flex, Item } from '../Flex';

import { usePlaces } from '#root/hooks/use-google-maps';
import { ILocation, Language } from '#root/store/types';

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
}

export interface IProps {
  language?: Language;
  defaultValue?: string;
  onChange: (location: ILocation) => void;
  inputProps?: Partial<TextFieldProps>;
}

const geocodeByPlaceId = (placeId: string): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const { OK } = window.google.maps.GeocoderStatus;

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      { placeId },
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status !== OK) {
          return reject(status);
        }
        return resolve(results);
      }
    );
  });
};

// https://developers.google.com/maps/faq#languagesupport
export const AddressAutocomplete: FC<IProps> = ({
  language = Language.DA,
  defaultValue,
  onChange,
  inputProps,
}) => {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState(defaultValue ?? '');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const places = usePlaces();
  const autocompleteService = useRef<google.maps.places.AutocompleteService>();

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string; language: string },
          callback: (results: google.maps.places.AutocompletePrediction[] | null) => void
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback);
        },
        400
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && places) {
      autocompleteService.current = new places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, language }, results => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, language, places]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: 300 }}
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      autoSelect
      autoHighlight
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText=""
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        // Get geometry details for place.
        if (newValue) {
          geocodeByPlaceId(newValue?.place_id).then(results => {
            if (results.length) {
              onChange({
                address: newValue.description,
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
              });
            }
          });
          setValue(newValue);
        } else {
          setValue(newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => <TextField {...params} {...inputProps} />}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Flex align="center">
              <Item column>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Item>
              <Item>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Item>
            </Flex>
          </li>
        );
      }}
    />
  );
};
