import { captureException, init as initSentry } from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.scss';
import './polyfills';
import { Bootstrap } from './Bootstrap';
import { ConnectSuccess } from './ConnectSuccess';
import { PaymentWindow } from './PaymentWindow';
import { UnsubscribeHandler } from './UnsubscribeHandler';

import { ErrorBoundary } from '#root/Components/ErrorBoundary';
import { routerNavigate } from '#root/Components/ExposeRouterNavigate';

if (import.meta.env.PROD) {
  initSentry({
    dsn: import.meta.env.VITE_SENTRY_DSN,
  });
}

const modules = {
  app: Bootstrap,
  connectSuccess: ConnectSuccess,
  paymentWindow: PaymentWindow,
  unsubscribeHandler: UnsubscribeHandler,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).csrfToken = document
  .querySelector('meta[name="csrf-token"]')
  ?.getAttribute('content');

Object.keys(modules).forEach((container: keyof typeof modules) => {
  const dom = document.getElementById(container);
  if (dom) {
    const root = createRoot(dom);
    const Component = modules[container];
    root.render(
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    );
  }
});

// Highjack all static links to create party, so we dont need a refresh
try {
  const links = document.body.querySelectorAll<HTMLAnchorElement>('a[href*="/new/"]');

  Array.from(links).forEach(link => {
    link.addEventListener('click', event => {
      try {
        if (routerNavigate) {
          routerNavigate(link.href.replace(window.location.origin, ''));
          event.preventDefault();
        }
      } catch (e) {
        captureException(e);
      }
    });
  });
} catch (e) {
  captureException(e);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
