import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PartyWizard } from '#root/Components/PartyWizard';
import { PartyTypes } from '#root/Components/PartyWizard/Types';
import { getService } from '#root/store';

export const PartyWizardRoute: FC = () => {
  const { type } = useParams<{ type: PartyTypes }>();

  useEffect(() => {
    getService('party').cancelWizard();
  }, []);

  return <PartyWizard type={type ?? 'party'} />;
};
