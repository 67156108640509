import { TextField } from '@mui/material';
import React, { FC } from 'react';
import { Control, Controller, useFormState } from 'react-hook-form';

import { WysiwygField } from '../WysiwygField';

import s from './index.module.scss';

import { useTranslation } from '#root/hooks/use-translation';
import { IMessageTemplate } from '#root/store/types';

export const MacEditor: FC<{
  control: Control<IMessageTemplate, any>;
}> = ({ control }) => {
  const t = useTranslation();
  const { errors } = useFormState({ control, name: 'name' });
  return (
    <div className={s.macbook}>
      <div className={s.pcaHold}>
        <div className={s.pcaMain}>
          <div className={s.pcaInner}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.name}
              label={t(keys => keys.admin.message.send.subject)}
              inputProps={control.register('name', {
                validate: value => value.trim().length > 1,
              })}
            />
            <Controller
              control={control}
              render={({ field }) => (
                <WysiwygField
                  value={field.value}
                  onChange={field.onChange}
                  label={t(keys => keys.admin.message.email)}
                />
              )}
              name="email"
            />
          </div>
        </div>

        <div className={s.pcaSub}>
          <div className={s.pcaTop}></div>
          <div className={s.pcaMid}>
            <div className={s.pcaPart}></div>
          </div>
          <div className={s.pcaBot}></div>
        </div>
      </div>
    </div>
  );
};
