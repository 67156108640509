import React, { FC } from 'react';

import { Container } from '../Container';

import { Help } from '.';

import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

export const HelpPage: FC = () => {
  const t = useTranslation();
  return (
    <Container>
      <h1>{t(Keys.menu.help)}</h1>
      <Help />
    </Container>
  );
};
