import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';

import { Flex } from '#root/Components/Flex';
import { useOnFieldChange } from '#root/hooks/use-on-field-change';
import { useTranslation } from '#root/hooks/use-translation';
import { IPartySettings } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';

export const RsvpField: FC<{
  control: Control<IPartySettings, any>;
  index: number;
}> = ({ control, index }) => {
  const t = useTranslation();
  const watchType = useWatch({ name: `rsvpFields.${index}.type`, control });
  const watchRequired = useWatch({ name: `rsvpFields.${index}.required`, control });

  const onRequiredFieldChanged = useCallback(
    (isChecked: boolean) => {
      if (watchType === 'choice' && isChecked) {
        alert(t(Keys.admin.rsvp.field.required_notice));
      }
    },
    [watchType]
  );

  useOnFieldChange(`rsvpFields.${index}.required`, control, onRequiredFieldChanged);

  return (
    <Flex stackOnMobile gap align="center" grow>
      <Flex column grow>
        <TextField
          autoFocus={false}
          variant="outlined"
          fullWidth
          size="small"
          label={t(Keys.admin.rsvp.field.name)}
          placeholder={t(Keys.admin.rsvp.field.name_placeholder)}
          inputProps={control.register(`rsvpFields.${index}.name`, { required: true })}
        />
        {watchType === 'choice' && watchRequired ? (
          <p className={clsx(css.fontSans, css.fontSmall, css.fontColorErrorLight, css.fontBold)}>
            {t(Keys.admin.rsvp.field.required_notice)}
          </p>
        ) : null}
      </Flex>
      <Controller
        control={control}
        name={`rsvpFields.${index}.type`}
        render={({ field }) => (
          <Select size="small" {...field}>
            <MenuItem value="text">{t(Keys.admin.rsvp.field.type.text)}</MenuItem>
            <MenuItem value="choice">{t(Keys.admin.rsvp.field.type.choice)}</MenuItem>
          </Select>
        )}
      />
      <Controller
        control={control}
        name={`rsvpFields.${index}.required`}
        render={({ field: { value, ...rest } }) => (
          <FormControlLabel
            control={<Checkbox color="primary" checked={value} {...rest} />}
            label={t(Keys.generic.required)}
          />
        )}
      />
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={`rsvpFields.${index}.hidden`}
            render={({ field: { value, ...rest } }) => (
              <Checkbox color="error" checked={value} {...rest} />
            )}
          />
        }
        label={t(Keys.admin.rsvp.field.hidden)}
      />
    </Flex>
  );
};
