import { useMemo } from 'react';

import { useStoreState } from '#root/store';
import { IEditableParty } from '#root/store/types';

export const useParty = () => {
  const party = useStoreState(state => state.party.party);

  if (!party) {
    throw new Error('Party not available');
  }

  return party;
};

export const useMemoParty = () => {
  const party = useParty();

  return useMemo(() => {
    console.log('memoed');
    return party;
  }, []);
};

export const useEditableParty = () => {
  const party = useStoreState(state => state.party.party);

  if (!party) {
    throw new Error('Party not available');
  }

  return party as IEditableParty;
};
