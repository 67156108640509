import React, { FC, useCallback } from 'react';
import { DragDropContext, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';

import { Wish } from '../Wish';

import { useTranslation } from '#root/hooks/use-translation';
import { IWish } from '#root/store/types';

export interface IProps {
  list: IWish[];
  moveItem: (wishId: string, from: number, to: number) => void;
  onChange: (wish: IWish) => void;
  onDelete: (wish: IWish) => void;
}

export const WishlistList: FC<IProps> = ({ list, moveItem, onChange, onDelete }) => {
  const t = useTranslation();
  if (!list || !list.length) return <p>{t('Ingen ønsker er tilføjet endnu.')}</p>;

  const onDragEnd = useCallback<OnDragEndResponder>(
    draggedItem => {
      if (!draggedItem.destination) return;

      const from = draggedItem.source.index;
      const wishId = list.at(from)?.id;

      if (!wishId) {
        console.error(`Could not find wish with id(${wishId}) in list while dragging.`);
        return;
      }
      const to = draggedItem.destination.index;
      moveItem(wishId, from, to);
    },
    [list, moveItem]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="wishlist-drop">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {list.map((item, index) => (
              <Wish
                key={item.id}
                item={item}
                index={index}
                onChange={onChange}
                onDelete={onDelete}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
