import { useCallback, useState } from 'react';

export function useSlider<T>(slides: T[], initialSlideIndex?: number) {
  const [isOpen, setIsOpen] = useState(false);
  const close = useCallback(() => setIsOpen(false), []);
  const open = useCallback((index?: number) => {
    if (index !== undefined) {
      setIndex(index);
    }
    setIsOpen(true);
  }, []);

  const [index, setIndex] = useState(initialSlideIndex ?? 0);
  const slidesLengths = slides.length;

  const next = useCallback(
    () => setIndex(prev => (prev < slidesLengths - 1 ? prev++ : 0)),
    [slidesLengths]
  );
  const previous = useCallback(
    () => setIndex(prev => (prev > 0 ? prev-- : slidesLengths - 1)),
    [slidesLengths]
  );

  return {
    index,
    setIndex,
    isOpen,
    open,
    close,
    next,
    previous,
    slides,
    isEnd: index === slidesLengths - 1,
    isBeginning: index === 0,
  };
}
