import { Edit } from '@mui/icons-material';
import { IconButton, TextField, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Flex } from './Flex';
import { LoadingButton } from './LoadingButton';
import { TextButton } from './TextButton';

import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';

interface IData {
  code: string;
}

export const RedeemCouponForm: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { coupon } = useEditableParty();
  const t = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<IData>({
    defaultValues: {
      code: coupon ?? '',
    },
  });

  const onSubmit: SubmitHandler<IData> = async data => {
    try {
      await getService('party').redeemCoupon(data.code);
      onClose();
    } catch (e) {
      alert(t(Keys.redeem_coupon.error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap>
        <TextField
          variant="outlined"
          fullWidth
          autoComplete="off"
          autoFocus
          label={t(Keys.redeem_coupon.field)}
          inputProps={{ ...register('code'), style: { textTransform: 'uppercase' } }}
          size="small"
          color="secondary"
        />
        <LoadingButton
          type="submit"
          variant="contained"
          size="small"
          disabled={!isValid || !isDirty}
          loading={isSubmitting}
          color="secondary"
        >
          {t(Keys.redeem_coupon.button)}
        </LoadingButton>
      </Flex>
    </form>
  );
};
export const RedeemCoupon: FC = () => {
  const [isClicked, setClicked] = useState(false);
  const t = useTranslation();
  const { coupon } = useEditableParty();
  const onClose = useCallback(() => setClicked(false), []);
  useEffect(() => setClicked(false), [coupon]);

  return (
    <>
      {isClicked ? (
        <RedeemCouponForm onClose={onClose} />
      ) : (
        <Flex gap="half" justify="center" align="center">
          {coupon ? (
            <span>
              <span className={clsx(css.fontColorGrey2, css.fontSans, css.fontSmall)}>
                {t(Keys.redeem_coupon.field)}:{' '}
              </span>
              <span className={css.fontSans}>{coupon}</span>
            </span>
          ) : null}
          {coupon ? (
            <Tooltip
              title={t(Keys.redeem_coupon.change_button)}
              PopperProps={{ placement: 'right-end' }}
            >
              <IconButton size="small" onClick={() => setClicked(true)}>
                <Edit fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <TextButton onClick={() => setClicked(true)}>
              {t(Keys.redeem_coupon.enabler_button)}
            </TextButton>
          )}
        </Flex>
      )}
    </>
  );
};
