import React, { FC, PropsWithChildren } from 'react';

import { useRedirectIfNotOwner } from '#root/hooks/use-redirect-if-logged-in';
import { useStoreState } from '#root/store';

export const RedirectIfNotOwner: FC<PropsWithChildren<any>> = ({ children }) => {
  const canEdit = useStoreState(state => state.party.canEdit);
  useRedirectIfNotOwner();
  if (canEdit) {
    return children;
  }

  return null;
};
