import clsx from 'clsx';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Actions } from '../../Blocks/Actions';
import { Body } from '../../Blocks/Body';
import { DateTime } from '../../Blocks/DateTime';
import { Description } from '../../Blocks/Description';
import { Footer } from '../../Blocks/Footer';
import { HeroImage } from '../../Blocks/HeroImage';
import { Location } from '../../Blocks/Location';
import { Rsvp } from '../../Blocks/Rsvp';
import { SU } from '../../Blocks/SU';
import { ThemePicker } from '../../Blocks/ThemePicker';
import { Title } from '../../Blocks/Title';

import s from './index.module.scss';

export const LegacyDefault: FC = () => {
  return (
    <>
      <div className={s.template}>
        <HeroImage className={s.heroImage} clickToEdit>
          {hasHeroImage => (
            <div className={clsx(s.heroInner, hasHeroImage && s.heroInnerWithImage)}>
              <div className={s.intro}>
                <Title className={s.title} />
                <Description className={s.description} />
              </div>
              <DateTime className={s.date} />
            </div>
          )}
        </HeroImage>
        <div className={s.grid}>
          <Body className={s.body} />
          <div className={s.sidebar}>
            <Actions />
            <Rsvp className={s.rsvp} />
          </div>
          <SU className={s.suDate} />
          <Location className={s.location} />
        </div>
      </div>
      <ThemePicker className={s.themePicker} />
      <Footer className={s.footer} />
      <Outlet />
    </>
  );
};
