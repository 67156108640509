import { OpenInNew, SwapVert } from '@mui/icons-material';
import { MenuItem, MenuList, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { WishlistForm } from '../Form';

import { Reserve } from './Reserve';
import s from './index.module.scss';

import { Box } from '#root/Components/Box';
import { ButtonDialog } from '#root/Components/ButtonDialog';
import { ConditionalWrapper } from '#root/Components/ConditionalWrapper';
import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Flex, Item } from '#root/Components/Flex';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { IWish } from '#root/store/types';
import { ThreeDotsIcon } from '#root/utils/icons';

const Link: FC<PropsWithChildren<{ url?: string; className?: string }>> = ({
  url,
  children,
  className,
}) =>
  url ? (
    <a href={url} className={className} target="_blank">
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );

export interface IProps {
  index: number;
  item: IWish;
  onChange: (wish: IWish) => void;
  onDelete: (wish: IWish) => void;
}

export const Wish: FC<IProps> = ({ index, item, onChange, onDelete }) => {
  const isOwner = useStoreState(state => state.party.canEdit);

  const t = useTranslation();
  return (
    <Draggable index={index} draggableId={item.id} isDragDisabled={!isOwner}>
      {(provided, snapshot) => {
        // Restrict dragging to vertical axis
        let transform = provided.draggableProps.style?.transform;

        if (snapshot.isDragging && transform) {
          transform = transform.replace(/\(.+,/, '(0,');
        }

        const style = {
          ...provided.draggableProps.style,
          transform,
        };
        return (
          <Box
            shadow
            marginBottom
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={style}
            {...provided.dragHandleProps}
          >
            <Flex gap align="center" className={s.container}>
              {isOwner && <SwapVert color="primary" className={s.dragHandle} />}
              <Item grow>
                <Link url={item.link} className={clsx(s.wish, item.link && s.hasLink)}>
                  <h3>
                    <ConditionalWrapper
                      condition={!!item.link}
                      wrapper={children => (
                        <Tooltip title={item.link} placement="bottom">
                          <span>{children}</span>
                        </Tooltip>
                      )}
                    >
                      <span className={s.title}>
                        {item.title}
                        {item.link ? (
                          <OpenInNew className={s.linkIcon} fontSize="inherit" color="inherit" />
                        ) : null}
                      </span>
                    </ConditionalWrapper>
                  </h3>
                  {item.image ? (
                    <div className={s.image}>
                      <img src={item.image} />
                    </div>
                  ) : null}
                </Link>
              </Item>
              <Reserve item={item} onChange={onChange} isOwner={isOwner} />
              {isOwner && (
                <DropdownMenu icon={<ThreeDotsIcon />} className={s.dropdownMenu}>
                  {({ closeDropdown }) => (
                    <MenuList>
                      <MenuItem onClick={() => closeDropdown(onDelete(item))}>
                        {t(keys => keys.generic.delete)}
                      </MenuItem>
                      <ButtonDialog
                        header={t(keys => keys.party.wishlist.edit_title)}
                        menuItem={t(keys => keys.generic.edit)}
                        onClose={closeDropdown}
                        withCloseButton
                      >
                        {onClose => (
                          <WishlistForm
                            editableItem={item}
                            onCancel={onClose}
                            onChange={onChange}
                          />
                        )}
                      </ButtonDialog>
                    </MenuList>
                  )}
                </DropdownMenu>
              )}
            </Flex>
          </Box>
        );
      }}
    </Draggable>
  );
};
