import clsx from 'clsx';
import React, { FC } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import s from './index.module.scss';

import { useStoreState } from '#root/store';
import { idParamIsParty } from '#root/utils/idParamIsParty';

type LinkProps = React.ComponentPropsWithoutRef<typeof RouterLink>;

export const Link: FC<LinkProps & { direct?: boolean }> = ({
  className,
  children,
  to: baseTo,
  direct,
  ...rest
}) => {
  const { idOrLang } = useParams<{ idOrLang: string }>();
  const userLanguage = useStoreState(state => state.app.currentUser?.language);
  const partyLanguage = useStoreState(state => state.party?.party?.language);

  const shouldUseLang = !direct || (idOrLang && !idParamIsParty(idOrLang));

  const lang = idOrLang && !idParamIsParty(idOrLang) ? idOrLang : userLanguage ?? partyLanguage;

  if (shouldUseLang && !lang) {
    console.error('Could not find current language');
  }

  const to =
    shouldUseLang && typeof baseTo === 'string' && baseTo.startsWith('/')
      ? `/${lang ?? 'da'}${baseTo}`
      : baseTo;

  return (
    <RouterLink className={clsx(className, s.link)} to={to} {...rest}>
      {children}
    </RouterLink>
  );
};
type LinkAProps = React.ComponentPropsWithoutRef<'a'>;

export const LinkA: FC<LinkAProps> = ({ className, children, ...rest }) => {
  return (
    <a className={clsx(className, s.link)} {...rest}>
      {children}
    </a>
  );
};
