import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { Box } from '../Box';

import s from './index.module.scss';

export interface IProps {
  title?: ReactNode;
  bordered?: boolean;
  type: 'info' | 'success' | 'warning' | 'error';
  className?: string;
}

export const InfoBox: FC<IProps & PropsWithChildren> = ({
  type,
  title,
  children,
  bordered,
  className,
}) => {
  return (
    <Box className={clsx(className, s[type], s.root)} bordered={bordered}>
      {title ? <div className={s.title}>{title}</div> : null}
      {children}
    </Box>
  );
};
