import { DragHandle } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Control, useFieldArray } from 'react-hook-form';

import { RsvpField } from './RsvpField';

import { Flex } from '#root/Components/Flex';
import { useTranslation } from '#root/hooks/use-translation';
import { IPartySettings } from '#root/store/types';
import { css } from '#root/utils/css';

export const RsvpFields: FC<{
  control: Control<IPartySettings, any>;
}> = ({ control }) => {
  const t = useTranslation();
  const { fields, append, move } = useFieldArray({
    control,
    name: 'rsvpFields',
  });

  const onDragEnd: OnDragEndResponder = useCallback(
    ({ source, destination }) => {
      if (destination) {
        move(source.index, destination?.index);
      }
    },
    [move]
  );

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fields">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Flex column gap bordered>
                {fields.map((rsvpField, index) => (
                  <Draggable key={rsvpField.id} draggableId={rsvpField.id} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Flex align="center" gap>
                          <div>
                            <IconButton {...provided.dragHandleProps}>
                              <DragHandle />
                            </IconButton>
                          </div>
                          <RsvpField control={control} index={index} />
                        </Flex>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Flex>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Flex justify="center" className={css.spaceTopS}>
        <Button
          onClick={() =>
            append({
              id: `temp-${new Date().getTime().toString()}`,
              name: '',
              required: false,
              type: 'text',
              hidden: false,
            })
          }
          variant="outlined"
        >
          {t(keys => keys.admin.rsvp.field.add)}
        </Button>
      </Flex>
    </>
  );
};
