import matchAll from 'string.prototype.matchall';
matchAll.shim();

(async () => {
  if ('ResizeObserver' in window === false) {
    // Loads polyfill asynchronously, only if required.
    try {
      const module = await import('@juggle/resize-observer');
      (window as any).ResizeObserver = module.ResizeObserver;
    } catch (error) {
      console.error(error);
    }
  }
})();

// Fix for issue: https://github.com/facebook/react/issues/11538#issuecomment-417504600
// Can be removed when following in chromium is fixed: https://bugs.chromium.org/p/chromium/issues/detail?id=872770
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    // eslint-disable-next-line prefer-rest-params
    return originalInsertBefore.apply(this, arguments);
  };
}
