import React, { FC, useEffect, useMemo } from 'react';

export const PreviewImage: FC<{ url?: string | null; file?: Blob | null; className?: string }> = ({
  url,
  file,
  className,
}) => {
  const source = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return url;
  }, [url, file]);

  useEffect(() => {
    return () => {
      source && URL.revokeObjectURL(source);
    };
  }, [source]);

  if (!source) {
    return <></>;
  }

  return <img src={source} className={className} />;
};
