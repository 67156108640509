import { List, ListSubheader, MenuItem } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { DropdownMenu } from '../DropdownMenu';

import s from './index.module.scss';

import { useFeature } from '#root/hooks/use-feature';
import { usePageType } from '#root/hooks/use-page-type';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { IEditableParty } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { CaretDownIcon } from '#root/utils/icons';
import { supports } from '#root/utils/party';

export const PartyMenu: FC<{ asDropdown?: boolean; onClose?: () => void }> = ({
  asDropdown,
  onClose,
}) => {
  const pageType = usePageType();
  const t = useTranslation();

  if (pageType !== 'party' && pageType !== 'admin') {
    return <></>;
  }

  if (asDropdown) {
    return (
      <DropdownMenu
        buttonText={
          <div className={s.menuButton}>
            <span>{t(Keys.menu.party.title)}</span>
            <CaretDownIcon />
          </div>
        }
        ButtonProps={{ variant: 'outlined' }}
      >
        {({ closeDropdown }) => <PartyMenuItems close={closeDropdown} />}
      </DropdownMenu>
    );
  }

  return <PartyMenuItems close={onClose} withHeader />;
};

export const PartyMenuItems: FC<{ close?: () => void; withHeader?: boolean }> = ({
  close,
  withHeader,
}) => {
  const t = useTranslation();
  const sid = useStoreState(state => state.party.party?.sid);
  const slug = useStoreState(state => state.party.party?.slug);
  const features = useStoreState(state => state.party.party?.features);
  const plan = useStoreState(state => (state.party.party as IEditableParty)?.plan);
  const photosFeatureEnabled = useFeature('PHOTOS');

  return (
    <List
      subheader={
        withHeader ? (
          <ListSubheader className={s.subheader} component="div">
            {t(Keys.menu.party.title)}
          </ListSubheader>
        ) : undefined
      }
    >
      <MenuItem to={`/${slug || sid}`} component={Link} onClick={() => close?.()}>
        {t(keys => keys.menu.party.frontpage)}
      </MenuItem>
      <MenuItem to={`/${sid}/contacts`} component={Link} onClick={() => close?.()}>
        {t(keys => keys.menu.party.contacts)}
      </MenuItem>
      <MenuItem to={`/${sid}/invitations`} component={Link} onClick={() => close?.()}>
        {t(keys => keys.menu.party.invitations)}
      </MenuItem>
      {supports(features, 'rsvp') && (
        <MenuItem to={`/${sid}/rsvps`} component={Link} onClick={() => close?.()}>
          {t(keys => keys.menu.party.rsvps)}
        </MenuItem>
      )}
      {supports(features, 'wishlist') && (
        <MenuItem to={`/${sid}/wishlist`} component={Link} onClick={() => close?.()}>
          {t(keys => keys.menu.party.wishlist)}
        </MenuItem>
      )}
      {photosFeatureEnabled && supports(features, 'photos') ? (
        <MenuItem to={`/${sid}/photos`} component={Link} onClick={() => close?.()}>
          {t(keys => keys.menu.party.photos)}
        </MenuItem>
      ) : null}
      <MenuItem to={`/${sid}/settings`} component={Link} onClick={() => close?.()}>
        {t(keys => keys.menu.party.settings)}
      </MenuItem>
      {plan === 'free' && (
        <MenuItem to={`/${sid}/plan`} component={Link} onClick={() => close?.()}>
          <b>{t(keys => keys.menu.party.upgrade)}</b>
        </MenuItem>
      )}
    </List>
  );
};
