import { Button } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';

import s from './SettingsForm.module.scss';

import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useEditableParty } from '#root/hooks/use-party';
import { usePopup } from '#root/hooks/use-popup';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { IPaymentMessageType } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { supports } from '#root/utils/party';
import { formatCurrency } from '#root/utils/string';

export const BuyUnlimitedPhotos: FC = () => {
  const party = useEditableParty();
  const t = useTranslation();
  const onMessage = useCallback((event: MessageEvent<IPaymentMessageType>) => {
    const { data } = event;
    if (data.source === 'payment-window' && data.payment !== false) {
      getService('party').refetch();
    }
  }, []);
  const { open: openPopup, isOpen: isPaymentOpen } = usePopup('unlimited_photos', onMessage);

  const price = party.prices.unlimited_photos['dkk'];

  const overLimit = party.limits.photos && party.limits.photos < party.photosUploadedByGuests;

  if (!supports(party, 'photos')) return <></>;

  return (
    <Flex bordered stackOnMobile align="center" className={s.mobileBorderBottom}>
      <Item grow>
        {overLimit ? (
          <p className={css.fontBold}>
            {t(Keys.admin.settings.photos.unlimited.over_limit_description, {
              limit: party.limits.photos,
            })}
          </p>
        ) : (
          <p>{t(Keys.admin.settings.photos.unlimited.description1)}</p>
        )}
        <p className={clsx(css.fontSmall, css.fontColorGrey2)}>
          {t(Keys.admin.settings.photos.unlimited.limit, {
            limit: party.limits.photos ?? t(Keys.admin.settings.photos.unlimited.is_unlimited),
          })}
        </p>
        <p className={clsx(css.fontSmall, css.fontColorGrey2)}>
          {t(Keys.admin.settings.photos.unlimited.uploaded, {
            amount: party.photosUploadedByGuests,
          })}
          {overLimit ? (
            <span className={clsx(css.fontBold, css.fontColorError)}>
              {' '}
              {t(Keys.admin.settings.photos.unlimited.over_limit)}
            </span>
          ) : null}
        </p>
      </Item>
      {party.limits.photos !== null ? (
        <Item column>
          <Flex align="center" gap column>
            {t(Keys.admin.settings.photos.unlimited.pay_action, {
              amount: formatCurrency(price, 'dkk'),
            })}
            <Button
              disableElevation
              variant="contained"
              disabled={isPaymentOpen}
              onClick={() => openPopup(`/payment/unlimited_photos/${party.id}/dkk`)}
            >
              <Flex gap align="center">
                {t(Keys.generic.buy)}
                {isPaymentOpen ? <Loading color="inherit" size="small" /> : null}
              </Flex>
            </Button>
          </Flex>
        </Item>
      ) : null}
    </Flex>
  );
};
