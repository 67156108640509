import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, MenuItem, MenuList } from '@mui/material';
import React, { FC, useCallback, useState } from 'react';

import { DropdownMenu } from '../../../../Components/DropdownMenu';
import { Flex } from '../../../../Components/Flex';

import s from './SendMessageButton.module.scss';

import type { Currency, IPaymentMessageType } from '#root/store/types';

import { Loading } from '#root/Components/Loading';
import { usePopup } from '#root/hooks/use-popup';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';
import { SendIcon } from '#root/utils/icons';
import { formatCurrency } from '#root/utils/string';

const pricePerToken = {
  dkk: 400,
  eur: 50,
};

export const SendMessageButton: FC<{
  onSend: () => void;
  onSendWithoutSms: () => void;
  amountToPay: number;
  skipEnabled?: boolean;
  disabled?: boolean;
}> = ({ onSend, onSendWithoutSms, amountToPay, skipEnabled, disabled }) => {
  const [currency, setCurrency] = useState<Currency>('dkk');

  const t = useTranslation();
  const partyId = useStoreState(state => state.party.party?.id);
  const onMessage = useCallback(
    (event: MessageEvent<IPaymentMessageType>) => {
      const { data } = event;
      if (data.source === 'payment-window' && data.payment !== false) {
        onSend();
      }
    },
    [onSend]
  );
  const { open: openPopup, isOpen: isPaymentOpen } = usePopup('add_token', onMessage);

  return (
    <>
      <div className={s.root}>
        {amountToPay > 0 ? (
          <>
            <span className={s.costNotice}>
              {t(Keys.admin.message.send.one_token_costs, {
                amount: formatCurrency(pricePerToken[currency], currency),
              })}
            </span>
            <ButtonGroup size="small">
              <Button disabled={currency === 'dkk'} onClick={() => setCurrency('dkk')}>
                dkk
              </Button>
              <Button disabled={currency === 'eur'} onClick={() => setCurrency('eur')}>
                eur
              </Button>
            </ButtonGroup>
          </>
        ) : null}
        <ButtonGroup variant="contained" disableElevation>
          {amountToPay <= 0 ? (
            <Button disabled={disabled} onClick={onSend}>
              <Flex gap align="center">
                {t(keys => keys.admin.message.send.action)} <SendIcon />
              </Flex>
            </Button>
          ) : (
            <Button
              disabled={disabled}
              onClick={() => openPopup(`/payment/token/${partyId}/${amountToPay}/${currency}`)}
            >
              <Flex gap align="center">
                {t(Keys.admin.message.send.pay_action, {
                  amount: formatCurrency(pricePerToken[currency] * amountToPay, currency),
                })}{' '}
                {isPaymentOpen ? <Loading color="inherit" size="small" /> : <SendIcon />}
              </Flex>
            </Button>
          )}
          {skipEnabled ? (
            <DropdownMenu buttonText={<ArrowDropDown />} ButtonProps={{ size: 'small', disabled }}>
              {() => (
                <MenuList dense autoFocusItem>
                  <MenuItem onClick={onSendWithoutSms}>
                    {t(Keys.admin.message.send.action_without_sms)}
                  </MenuItem>
                </MenuList>
              )}
            </DropdownMenu>
          ) : null}
        </ButtonGroup>
      </div>
    </>
  );
};
