import clsx from 'clsx';
import { parseISO } from 'date-fns';
import React, { FC } from 'react';

import s from './BubbleTime.module.scss';

export const BubbleTime: FC<{ dateTime: string; className?: string }> = ({
  dateTime,
  className,
}) => {
  const parsedDateTime = parseISO(dateTime);

  return (
    <div className={clsx(className, s.root)}>
      {parsedDateTime.toLocaleDateString('da')}
      {' - '}
      {parsedDateTime.toLocaleTimeString('da', {
        hour12: false,
        minute: '2-digit',
        hour: '2-digit',
      })}
    </div>
  );
};
