import React, { FC } from 'react';
import { useBeforeUnload } from 'react-use';

import Themes from './Themes';

import { usePartyTitle } from '#root/hooks/use-party-title';
import { useRedirectOnAdmin } from '#root/hooks/use-redirect-on-admin';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

const Party: FC = () => {
  usePartyTitle(); // @todo: Editing the title will make this rerender
  useRedirectOnAdmin('slug');

  const t = useTranslation();
  const isDirty = useStoreState(state => state.party.isDirty);
  const themeName = useStoreState(state => state.party.party?.themeName);
  useBeforeUnload(isDirty, t(Keys.generic.unsaved_changes_loss));

  const ThemeComponent = (themeName && Themes[themeName]) ?? Themes.default;

  return <ThemeComponent />;
};

export default Party;
