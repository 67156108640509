import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { Box } from '#root/Components/Box';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IUser } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';

export const NotificationsForm: FC = () => {
  const [submitting, setSubmitting] = useState(false);
  const user = useStoreState(state => state.app.currentUser);
  const t = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<IUser>({
    mode: 'onChange',
    defaultValues: user,
  });

  const onSubmit: SubmitHandler<IUser> = data => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    getService('app')
      .saveUser(data)
      .then(user => reset(user))
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <Box shadow bordered marginBottom>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>{t(Keys.page.account.notifications)}</h2>
        <Flex column>
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="muteRsvps"
                render={({ field: { value, ...rest } }) => (
                  <Checkbox color="primary" checked={value} {...rest} />
                )}
              />
            }
            label={t(Keys.page.account.mute_rsvps)}
          />
          <FormControlLabel
            control={
              <Controller
                control={control}
                name="muteRsvpMessages"
                render={({ field: { value, ...rest } }) => (
                  <Checkbox color="primary" checked={value} {...rest} />
                )}
              />
            }
            label={t(Keys.page.account.mute_rsvp_messages)}
          />
          <div className={css.spaceTopS}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isDirty || submitting || !isValid}
              disableElevation
            >
              {submitting ? <Loading /> : t(Keys.generic.save)}
            </Button>
          </div>
        </Flex>
      </form>
    </Box>
  );
};
