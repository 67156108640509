import { IconButton, IconButtonProps } from '@mui/material';
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';

import { Loading } from '../Loading';

export interface IProps {
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  timeout?: number;
  loadingWhenDisabled?: boolean;
}

export const LazyButton: FC<PropsWithChildren<IProps> & IconButtonProps> = ({
  children,
  onClick,
  loading,
  loadingWhenDisabled,
  disabled = false,
  timeout = 4000,
  ...rest
}) => {
  const [enabled, setEnabled] = useState(true);
  const timerRef = useRef<number>();

  const handle = useCallback(() => {
    onClick();
    setEnabled(false);
  }, [onClick]);

  useEffect(() => {
    if (loading && enabled) {
      setEnabled(false);
      if (timerRef.current) {
        window.clearTimeout(timerRef.current);
      }
    }
  }, [loading, enabled]);

  useEffect(() => {
    if (!enabled && !loading) {
      const timer = window.setTimeout(() => {
        setEnabled(true);
      }, timeout);

      timerRef.current = timer;

      return () => {
        clearTimeout(timer);
      };
    }
  }, [enabled, loading]);

  return (
    <IconButton onClick={handle} disabled={!enabled || disabled} {...rest}>
      {loadingWhenDisabled && !enabled ? <Loading size="small" /> : children}
    </IconButton>
  );
};
