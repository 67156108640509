import { Node, nodeInputRule } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';

import { UploadFn, uploadImagePlugin } from './upload-plugin';

/**
 * Tiptap Extension to upload images
 * @see  https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 * @since 7th July 2021
 *
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */

interface ImageOptions {
  inline: boolean;
  allowBase64: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      /**
       * Add an image
       */
      setImage: (options: { src: string; alt?: string; title?: string }) => ReturnType;
    };
  }
}

export const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const UploadImage = (uploadFn: UploadFn) => {
  return Node.create<ImageOptions>({
    name: 'image',

    addOptions() {
      return {
        inline: true,
        allowBase64: false,
        HTMLAttributes: {},
      };
    },

    inline() {
      return this.options.inline;
    },

    group() {
      return this.options.inline ? 'inline' : 'block';
    },

    draggable: true,

    addAttributes() {
      return {
        src: {
          default: null,
        },
        alt: {
          default: null,
        },
        title: {
          default: null,
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: this.options.allowBase64 ? 'img[src]' : 'img[src]:not([src^="data:"])',
          getAttrs: dom => {
            if (typeof dom === 'string') return {};
            const element = dom as HTMLImageElement;

            const obj = {
              src: element.getAttribute('src'),
              title: element.getAttribute('title'),
              alt: element.getAttribute('alt'),
            };
            return obj;
          },
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
    },
    addCommands() {
      return {
        setImage:
          options =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: options,
            });
          },
      };
    },
    addInputRules() {
      return [
        nodeInputRule({
          find: inputRegex,
          type: this.type,
          getAttributes: match => {
            const [, , alt, src, title] = match;

            return { src, alt, title };
          },
        }),
      ];
    },
    addProseMirrorPlugins() {
      return [uploadImagePlugin(uploadFn)];
    },
  });
};
