import { useMemo } from 'react';

const isNil = (val: unknown): boolean => val === undefined || val === null;

const getBoolVal = (val?: string | boolean | null): boolean => {
  if (typeof val === 'string') {
    return val === 'true';
  }

  return !!val;
};

type Feature = 'PHOTOS' | 'ANDERS';

const appendFeature = (feature: Feature) => `INVII_${feature}`;

export const registerUrlParams = (featureName: Feature) => {
  const urlSearchParams = window && new URLSearchParams(window.location.search);
  const myParam = urlSearchParams ? urlSearchParams.get(appendFeature(featureName)) : null;

  if (!isNil(myParam)) {
    if (getBoolVal(myParam)) {
      console.log(`Turning on ${featureName}`);
      window.localStorage.setItem(appendFeature(featureName), 'true');
    } else {
      console.log(`Turning off ${featureName}`);
      window.localStorage.removeItem(appendFeature(featureName));
    }
  }
};

const flags = {
  PHOTOS: true,
} as const;

export const useFeature = (name: Feature, enabled = false): boolean => {
  const featureEnabled = useMemo((): boolean => {
    try {
      const localStorageValue = window?.localStorage?.getItem(appendFeature(name));

      if (!isNil(localStorageValue)) {
        return getBoolVal(localStorageValue);
      }

      if (!isNil(flags[name])) {
        return flags[name];
      }
      return !!enabled;
    } catch (e) {
      return false;
    }
  }, [name, enabled]);

  return featureEnabled;
};
