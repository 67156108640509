import { Info } from '@mui/icons-material';
import React, { FC } from 'react';

import { Accordion } from '#root/Components/Accordion';
import { Cell, Row, Table } from '#root/Components/Table';
import { useFetch } from '#root/hooks/use-fetch';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { IContact } from '#root/store/types';
import { Keys } from '#root/translations-keys';

export interface IProps {
  people: number;
  ids?: string[];
  mails: number;
  texts: number;
  tokensInAccount: number;
}

export const SendMessageSummary: FC<IProps> = ({ people, ids, mails, texts, tokensInAccount }) => {
  const t = useTranslation();
  const party = useEditableParty();
  const { data: contacts } = useFetch<IContact[] | null>(
    `/api/party/${party.id}/contacts?filters=id|${(ids || []).slice(0, 50).join(',')}`,
    null,
    undefined,
    !!ids && ids.length > 0 && ids.length < 50
  );

  if (people === 0) {
    return <p>{t(Keys.admin.message.send.all_guests_already)}</p>;
  }

  return (
    <>
      <p>
        {t(Keys.admin.message.sending_to, {
          count: people,
          mail: mails,
          text: texts,
        })}
      </p>
      {contacts && !!ids && ids.length < 50 ? (
        <Accordion compact title={t(Keys.admin.message.send.details)}>
          <div style={{ maxHeight: 300, overflowY: 'auto' }}>
            <Table template="auto auto auto" striped>
              {contacts.map(contact => (
                <Row key={contact.id}>
                  <Cell>{contact.name}</Cell>
                  <Cell title={contact.email}>{contact.email}</Cell>
                  <Cell title={contact.phone}>{contact.phone}</Cell>
                </Row>
              ))}
            </Table>
          </div>
        </Accordion>
      ) : null}
      {texts > 0 && tokensInAccount > 0 ? (
        <p>{t(Keys.admin.message.tokens_in_account, { amount: tokensInAccount })}</p>
      ) : null}
    </>
  );
};
