import { DialogContent } from '@mui/material';
import React, { FC, useRef, useState } from 'react';

import { IframePreview } from '#root/Components/IframePreview';
import { getService } from '#root/store';
import { IMessageTemplate, IParty } from '#root/store/types';

export interface IProps {
  party: IParty;
  templateGetter: () => IMessageTemplate;
}

export const Preview: FC<IProps> = ({ party, templateGetter: getter }) => {
  const [previewIsLoading, setPreviewIsLoading] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const refreshPreview = async () => {
    if (iframeRef.current) {
      try {
        const template = getter();
        setPreviewIsLoading(true);
        const body = await getService('messages').preview(party.id, template.id, template);
        const doc = iframeRef.current.contentWindow?.document;
        doc?.open();
        doc?.write(body);
        doc?.close();
      } catch (e) {
        // no op
      } finally {
        setPreviewIsLoading(false);
      }
    }
  };
  return (
    <DialogContent>
      <IframePreview refresh={refreshPreview} ref={iframeRef} loading={previewIsLoading} />
    </DialogContent>
  );
};
