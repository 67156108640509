import { Button, DialogContent, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import { EditContact } from './Contact';
import { MassImport } from './MassImport';
import s from './index.module.scss';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Container } from '#root/Components/Container';
import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { SortableColumn } from '#root/Components/Pager';
import { PagerActions } from '#root/Components/Pager/PagerActions';
import { Column } from '#root/Components/Table';
import { useFeature } from '#root/hooks/use-feature';
import { useNavigate } from '#root/hooks/use-navigate';
import { useOptimisticData } from '#root/hooks/use-optimistic-data';
import { usePager } from '#root/hooks/use-pager';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { IContact } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { HelpIcon } from '#root/utils/icons';

export const Contacts: FC = () => {
  const party = useEditableParty();
  const massImportFeatureEnabled = useFeature('ANDERS');

  const t = useTranslation();
  const navigate = useNavigate();

  if (!party) return null;

  const pager = usePager<IContact>({
    url: `/api/party/${party.id}/contacts`,
    defaultSort: ['name', 'asc'],
    rowsPerPage: 50,
  });

  const optimistic = useOptimisticData(pager.setData, 'id');

  return (
    <Container withPadding>
      <>
        <Flex align="center" gap>
          <h2>{t(keys => keys.titles.contacts)}</h2>
          <PagerActions pager={pager} />
          <Item pushRight>
            <ButtonDialog
              withCloseButton
              buttonText={t(keys => keys.admin.contacts.create)}
              header={t(keys => keys.admin.contacts.create)}
              ButtonProps={{
                variant: 'contained',
                disableElevation: true,
              }}
              classes={{
                container: s.mobileDialog,
              }}
            >
              {() => (
                <DialogContent>
                  <EditContact
                    showLabels
                    className={s.dialogForm}
                    onChange={optimistic.add}
                    onDelete={optimistic.remove}
                  />
                </DialogContent>
              )}
            </ButtonDialog>
          </Item>
          {massImportFeatureEnabled || party.plan === 'pro' ? (
            <Item className={css.showOnTablet}>
              <ButtonDialog
                withCloseButton
                header={t(Keys.admin.rsvp.import.header)}
                buttonText={t(Keys.admin.rsvp.import.button)}
                ButtonProps={{
                  variant: 'contained',
                  disableElevation: true,
                }}
              >
                {onClose => <MassImport onImport={pager.refresh} onClose={onClose} />}
              </ButtonDialog>
            </Item>
          ) : null}
          <Item className={css.showOnDesktop}>
            <Button
              variant="outlined"
              disableElevation
              onClick={() => navigate(`/${party.sid}/invitations`, true)}
            >
              {t(keys => keys.menu.party.invitations)} …
            </Button>
          </Item>
        </Flex>

        <div className={clsx(s.row, s.header, party.guestCountChoiceEnabled && s.withGuestCount)}>
          <SortableColumn
            name="name"
            orderBy={pager.orderBy}
            onClick={pager.onOrderByChangeCallback}
          >
            {t(keys => keys.admin.contacts.labels.names)}
          </SortableColumn>
          <Column>{t(keys => keys.admin.contacts.labels.email)}</Column>
          <Column>{t(keys => keys.admin.contacts.labels.phone)}</Column>
          {party.guestCountChoiceEnabled ? (
            <Column>
              <Flex gap>
                {t(keys => keys.admin.contacts.labels.people)}
                <Tooltip title={t(keys => keys.admin.contacts.people.tooltip)}>
                  <HelpIcon />
                </Tooltip>
              </Flex>
            </Column>
          ) : null}
        </div>
      </>
      <div className={s.wrapper}>
        {pager.data === undefined ? (
          <Flex column align="center" className={css.spaceM}>
            <Loading size="large" />
          </Flex>
        ) : (
          pager.data?.map(contact => (
            <EditContact
              key={contact.id}
              autoSubmit
              className={clsx(s.contactRow, party.guestCountChoiceEnabled && s.withGuestCount)}
              item={contact}
              onChange={optimistic.alter}
              onDelete={optimistic.remove}
            />
          ))
        )}
      </div>
      <PagerActions pager={pager} className={s.pager} />
      <div className={clsx(s.createRow, css.showOnDesktop)}>
        <EditContact
          className={clsx(s.row, party.guestCountChoiceEnabled && s.withGuestCount)}
          onChange={optimistic.add}
          onDelete={optimistic.remove}
        />
      </div>
    </Container>
  );
};
