import { IconButton } from '@mui/material';
import React, { forwardRef, useEffect } from 'react';

import s from './index.module.scss';

import { Loading } from '#root/Components/Loading';
import { RefreshIcon } from '#root/utils/icons';

export interface IProps {
  refresh: () => void;
  loading?: boolean;
  canRefresh?: boolean;
}

export const IframePreview = forwardRef<HTMLIFrameElement, IProps>(
  ({ refresh, loading, canRefresh }, ref) => {
    useEffect(() => {
      refresh();
    }, []);

    return (
      <div className={s.previewContainer}>
        {canRefresh ? (
          <div className={s.previewRefreshButton}>
            <IconButton onClick={refresh} title="Refresh preview" size="small">
              {loading ? <Loading size={24} color="secondary" /> : <RefreshIcon />}
            </IconButton>
          </div>
        ) : null}
        <div className={s.iframeOverflow}>
          <iframe ref={ref} className={s.iframe} />
        </div>
      </div>
    );
  }
);
