import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { IPartyWizardInput } from '.';

import { getService } from '#root/store';

export const Timepicker: FC<IPartyWizardInput> = ({ control }) => {
  return (
    <span className="Wizard_Input">
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getService('translation').getLocale()}
      >
        <Controller
          control={control}
          name="clock"
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => {
            let date: Date | null = null;
            if (value) {
              const [hour, min] = value.split(':');
              date = new Date();
              date.setHours(Number(hour));
              date.setMinutes(Number(min));
            }
            return (
              <TimePicker
                className="TimeInput_input"
                ampm={false}
                slotProps={{
                  textField: { variant: 'standard' },
                  openPickerButton: { size: 'small' },
                }}
                value={date}
                onChange={newDate => {
                  if (newDate && !isNaN(newDate.getTime())) {
                    onChange(format(newDate, 'HH:mm'));
                  }
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
    </span>
  );
};
