import { Editor } from '@tiptap/react';
import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';

import { ToolbarButton } from '../../ToolbarButton';

import { getService } from '#root/store';

export const ImageToolbarButton: FC<{ editor: Editor } & PropsWithChildren> = ({
  editor,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => {
    inputRef.current?.click();
  }, []);
  const onSelectFile: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    async e => {
      const file = e.target.files?.[0];
      if (file) {
        const src = await getService('party').uploadImage(file);
        editor.chain().focus().setImage({ src }).run();
      }
    },
    [editor]
  );

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onSelectFile}
        style={{ opacity: 0, width: 0, overflow: 'hidden' }}
      />
      <ToolbarButton onClick={onClick}>{children}</ToolbarButton>
    </>
  );
};
