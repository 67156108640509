import { useEffect } from 'react';

import { useNavigate } from './use-navigate';

import { useStoreState } from '#root/store';
import { Feature } from '#root/store/types';
import { supports } from '#root/utils/party';

export const useRedirectIfLoggedIn = (to = '/parties', shouldBeLoggedIn = true) => {
  const user = useStoreState(state => state.app.currentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user === shouldBeLoggedIn) {
      navigate(to);
    }
  }, [user]);
};

export const useRedirectIfLoggedOut = (to = '/login') => {
  useRedirectIfLoggedIn(to, false);
};

export const useRedirectIfOwner = () => useRedirectWhenOwner(true);
export const useRedirectIfNotOwner = () => useRedirectWhenOwner();
export const useRedirectWhenOwner = (redirectWhenOwner = false) => {
  const canEdit = useStoreState(state => state.party.canEdit);
  const sid = useStoreState(state => state.party.party?.sid);

  const navigate = useNavigate();

  const shouldRedirect = redirectWhenOwner === canEdit;

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/${sid}`, true);
    }
  }, [shouldRedirect, sid]);
};

export const useRedirectIfNotSupports = (feature: Feature, to?: string) => {
  const features = useStoreState(state => state.party.party?.features);
  const sid = useStoreState(state => state.party.party?.sid);
  const navigate = useNavigate();
  const redirectTo = to ?? `/${sid}`;

  useEffect(() => {
    if (!supports(features, feature)) {
      navigate(redirectTo, true);
    }
  }, [features, feature, to]);
};
