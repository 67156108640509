import { Button, List, MenuItem } from '@mui/material';
import clsx from 'clsx';
import React, { FC } from 'react';

import { EditRSVP } from './EditRSVP';
import { MissingRsvps } from './MissingRsvps';
import { RSVP } from './RSVP';
import s from './index.module.scss';

import { ButtonDialog } from '#root/Components/ButtonDialog';
import { Container } from '#root/Components/Container';
import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { PagerActions } from '#root/Components/Pager/PagerActions';
import { SortableButton } from '#root/Components/Pager/PagerTable';
import { useFilterLink } from '#root/Components/Pager/useFilterLink';
import { useRsvpStats } from '#root/Components/RsvpStats';
import { usePager } from '#root/hooks/use-pager';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { IRsvp } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CaretDownIcon, DoneIcon } from '#root/utils/icons';

const all =
  (...funcs: (() => unknown)[]) =>
  () =>
    funcs.forEach(func => func());

export const Rsvps: FC = () => {
  const { render: renderStats, load: reloadStats } = useRsvpStats();
  const party = useEditableParty();
  const t = useTranslation();
  const { active: isFilteredById, onFilter: onFilterById } = useFilterLink('id', undefined, true);

  const pager = usePager<IRsvp>({
    url: `/api/party/${party.id}/rsvps`,
    defaultSort: ['createdAt', 'desc'],
    filterable: ['id'],
  });

  return (
    <Container marginBottom>
      <Flex align="center" gap>
        <h2>{t(Keys.admin.rsvp.title)}</h2>
        <ButtonDialog
          buttonText={t(Keys.admin.rsvp.create)}
          header={t(Keys.admin.rsvp.create)}
          withCloseButton
        >
          {onClose => <EditRSVP onClose={onClose} refresh={all(pager.refresh, reloadStats)} />}
        </ButtonDialog>
        <Item pushRight className={css.showOnDesktop}>
          <DropdownMenu
            buttonText={
              <Flex align="center">
                <span>{t(Keys.admin.rsvp.export.menu)}</span>
                <CaretDownIcon />
              </Flex>
            }
            ButtonProps={{ variant: 'outlined', color: 'secondary', size: 'small' }}
          >
            {({ closeDropdown }) => (
              <List>
                <MenuItem
                  component="a"
                  target="_blank"
                  href={`/export/${party.sid}/rsvp-all.csv`}
                  onClick={closeDropdown}
                >
                  {t(Keys.admin.rsvp.export.csv.all)}
                </MenuItem>
                <MenuItem
                  component="a"
                  target="_blank"
                  href={`/export/${party.sid}/rsvp-attending.csv`}
                  onClick={closeDropdown}
                >
                  {t(Keys.admin.rsvp.export.csv.attending)}
                </MenuItem>
                <MenuItem
                  component="a"
                  target="_blank"
                  href={`/export/${party.sid}/rsvp-rejected.csv`}
                  onClick={closeDropdown}
                >
                  {t(Keys.admin.rsvp.export.csv.rejected)}
                </MenuItem>
              </List>
            )}
          </DropdownMenu>
        </Item>
      </Flex>
      {pager.data === undefined ? (
        <Loading />
      ) : pager.data.length === 0 ? (
        <Flex column align="center" className={css.spaceL}>
          <p>{t(Keys.admin.rsvp.empty)}</p>
        </Flex>
      ) : (
        <>
          <div>{renderStats()}</div>
          {isFilteredById ? (
            <Flex gap align="center" justify="center" className={css.spaceBottomM}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onFilterById(null)}
                disableElevation
              >
                {t(Keys.admin.rsvp.show_all)}
              </Button>
            </Flex>
          ) : (
            <Flex
              gap
              align="center"
              justify="center"
              className={clsx(css.spaceBottomM, css.hideOnMobile)}
            >
              <p className={s.sortBy}>{t(Keys.generic.sort_by)}:</p>
              <SortableButton
                name="isComming"
                orderBy={pager.orderBy}
                onClick={pager.onOrderByChangeCallback}
                disabled={pager.loading}
                color="secondary"
              >
                <Flex gap align="center">
                  <DoneIcon fontSize="small" color="primary" />
                  {t(Keys.admin.rsvp.column.attending)}
                </Flex>
              </SortableButton>
              <SortableButton
                name="name"
                orderBy={pager.orderBy}
                onClick={pager.onOrderByChangeCallback}
                disabled={pager.loading}
                color="secondary"
              >
                {t(Keys.admin.rsvp.column.name)}
              </SortableButton>
              <SortableButton
                name="createdAt"
                orderBy={pager.orderBy}
                onClick={pager.onOrderByChangeCallback}
                disabled={pager.loading}
                color="secondary"
              >
                {t(Keys.admin.rsvp.filter.received_date)}
              </SortableButton>
              {party.guestCountChoiceEnabled ? (
                <SortableButton
                  name="numberOfPeople"
                  orderBy={pager.orderBy}
                  onClick={pager.onOrderByChangeCallback}
                  disabled={pager.loading}
                  color="secondary"
                >
                  {t(Keys.admin.rsvp.filter.guest_count)}
                </SortableButton>
              ) : null}
            </Flex>
          )}
          {pager.data.map(rsvp => (
            <RSVP
              key={rsvp.id}
              item={rsvp}
              rsvpFields={party.rsvpFields}
              refresh={all(pager.refresh, reloadStats)}
              showGuestCount={party.guestCountChoiceEnabled}
            />
          ))}
          <Flex className={css.spaceTopM}>
            <PagerActions pager={pager} />
            <Item pushRight>
              <ButtonDialog
                ButtonProps={{ variant: 'outlined' }}
                buttonText={t(Keys.admin.rsvp.see_who_not)}
                header={t(Keys.admin.rsvp.see_who_not)}
                withCloseButton
              >
                {onClose => <MissingRsvps onClose={onClose} partyId={party.id} />}
              </ButtonDialog>
            </Item>
          </Flex>
        </>
      )}
    </Container>
  );
};
