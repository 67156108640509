import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { FC } from 'react';
import { MemoryRouter, Route, Routes } from 'react-router-dom';

import { LoginForm } from '#root/Modules/Login/LoginForm';
import { PasswordForm } from '#root/Modules/Login/PasswordForm';
import { RegisterForm } from '#root/Modules/Login/RegisterForm';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { CloseIcon } from '#root/utils/icons';
import { isMobile } from '#root/utils/is-mobile';

export const PromptLoginProvider: FC = () => {
  const t = useTranslation();
  const shouldPromptLogin = useStoreState(state => state.app.promptLogin);
  const onClose = () => {
    getService('app').closePromptLogin();
  };

  if (!shouldPromptLogin) return <></>;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="dialog-title"
      fullWidth
      fullScreen={isMobile()}
    >
      <DialogTitle id="dialog-title">
        {t(keys => keys.login.prompt.title)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <MemoryRouter>
          <Routes>
            <Route path="/" element={<RegisterForm withoutTitle />} />
            <Route path="/:language/login" Component={LoginForm} />
            <Route path="/:language/register" element={<RegisterForm withoutTitle />} />
            <Route path="/:language/password" Component={PasswordForm} />
          </Routes>
        </MemoryRouter>
      </DialogContent>
    </Dialog>
  );
};
