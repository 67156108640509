import { Button, InputBase } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import s from './RsvpMessageForm.module.scss';

import { Bubble } from '#root/Components/Bubble';
import { Loading } from '#root/Components/Loading';
import { useIsMobile } from '#root/hooks/use-breakpoint';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';

export const RsvpMessageForm: FC<{ rsvpId: string; onReply: () => void; className?: string }> = ({
  rsvpId,
  onReply,
  className,
}) => {
  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const { id: partyId } = useEditableParty();
  const isMobile = useIsMobile();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<{ message: string }>({
    defaultValues: {
      message: '',
    },
  });
  const onSubmit: SubmitHandler<{ message: string }> = async data => {
    if (submitting) return;
    setSubmitting(true);
    try {
      await getService('rsvp').reply(partyId, rsvpId, data.message);
      onReply();
      reset();
    } catch (error) {
      console.error(error);
    }
    setSubmitting(false);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={clsx(className, s.form)}>
      <Bubble right color="secondary" shadow>
        <InputBase
          autoFocus={!isMobile}
          fullWidth
          minRows={3}
          placeholder={t(Keys.admin.rsvp.reply_placeholder)}
          color="secondary"
          className={s.field}
          multiline
          inputProps={register('message', {
            required: true,
          })}
        />
      </Bubble>
      <Button
        disableElevation
        type="submit"
        disabled={submitting || !isValid}
        variant="contained"
        className={s.button}
      >
        {submitting ? <Loading /> : 'Send'}
      </Button>
    </form>
  );
};
