import React, { FC } from 'react';

import { Contacts } from './Contacts';

import { RedirectIfNotOwner } from '#root/Components/Redirect';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useTranslation } from '#root/hooks/use-translation';
import { Keys } from '#root/translations-keys';

const PartyContacts: FC = () => {
  const t = useTranslation();
  usePartyTitle(t(Keys.titles.contacts));

  return (
    <RedirectIfNotOwner>
      <Contacts />
    </RedirectIfNotOwner>
  );
};

export default PartyContacts;
