import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';

import { debounce } from '#root/utils/debounce';

export const useDebounce = <A extends any[], R = void>(
  fn: (...args: A) => R,
  ms: number
): ((...args: A) => Promise<R>) => {
  const callbackRef = useRef(fn);
  useLayoutEffect(() => {
    callbackRef.current = fn;
  });
  const [debouncedFun, teardown] = useMemo(
    () => debounce<A, R>((...args) => callbackRef.current(...args), ms),
    [ms]
  );
  useEffect(() => () => teardown(), []);

  return debouncedFun;
};
