import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Divider, ListSubheader, MenuItem, MenuList } from '@mui/material';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

import s from './Invitations.module.scss';
import { MessageTemplateEditor } from './MessageTemplateEditor';

import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { IEditableParty, IMessageTemplate } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { getMaxLength } from '#root/utils/sms';
import { emojiRegex, replaceAll } from '#root/utils/string';

export const Invitations: FC = () => {
  const t = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const closeDrawer = useCallback((anything?: unknown) => {
    setDrawerOpen(false);
  }, []);
  const drawerRef = useRef<HTMLDivElement>(null);
  useClickAway(drawerRef, () => {
    setDrawerOpen(false);
  });
  const party = useStoreState(state => state.party.originalParty as IEditableParty);

  const generateTextForInvitation = () => {
    let html = `${t(keys => keys.admin.invitations.dear_guest)}\n\n${party.body}`;

    html = html.replace(emojiRegex, '');
    // Replace <br> and <p> with newlines.
    html = replaceAll(html, '/p>', '/p>\n\n');
    html = replaceAll(html, 'br>', 'br>\n');

    const div = document.createElement('div');
    div.innerHTML = html;
    let text = div.innerText;
    div.remove();

    // Remove any urls.
    text = text.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '');

    // Contract to maximum xx chars.
    text = text.substring(0, getMaxLength('')); // < first we assume they do not have a ((link))
    text = text.substring(0, getMaxLength(text));
    return text.trim();
  };

  const generatedefaultNewInvitation = () =>
    ({
      id: 'new-invitation',
      createdAt: '',
      modifiedAt: '',
      name: party.title,
      type: 'invitation',
      email: `<h1>${party.title}</h1><p>${t(keys => keys.admin.invitations.dear_guest)}</p>${
        party.body
      }`,
      text: generateTextForInvitation(), // @todo: make default custom text that is not based on `body`
    } satisfies IMessageTemplate);

  const templates = party.messageTemplates.length
    ? party.messageTemplates
    : [generatedefaultNewInvitation()];

  const sortedTemplates = useMemo(() => {
    return [...templates].sort((a, b) => {
      const d1 = parseISO(a.modifiedAt);
      const d2 = parseISO(b.modifiedAt);
      return d2.getTime() - d1.getTime();
    });
  }, [templates]);

  const [selectedID, setSelectedId] = useState<IMessageTemplate['id']>(sortedTemplates[0]?.id);
  const [newTemplate, setNewTemplate] = useState<IMessageTemplate>();
  const onNewInvitation = useCallback(() => {
    closeDrawer();
    setNewTemplate(generatedefaultNewInvitation());
    setSelectedId('new-invitation');
  }, [party]);

  const onNewEmptyMessage = useCallback(() => {
    closeDrawer();
    setNewTemplate({
      id: 'new-message',
      createdAt: '',
      modifiedAt: '',
      name: t(Keys.admin.invitations.title.new_empty),
      type: 'message',
      email: `<p>${t(keys => keys.admin.invitations.dear_guest)}</p><p></p>`,
      text: `${t(keys => keys.admin.invitations.dear_guest)}\n`,
    });
    setSelectedId('new-message');
  }, []);

  if (!party) {
    return <></>;
  }

  const template = sortedTemplates.find(template => template.id === selectedID) ?? newTemplate;

  return (
    <>
      <div className={clsx(s.grid, drawerOpen && s.drawerOpen)}>
        {!drawerOpen ? (
          <div className={s.drawerOpenButton} onClick={() => setDrawerOpen(true)}>
            <MenuOpenIcon fontSize="large" color="inherit" />
          </div>
        ) : null}
        <div className={s.drawer} ref={drawerRef}>
          {!drawerOpen ? (
            <div className={s.drawerOpenHandle} onClick={() => setDrawerOpen(true)}></div>
          ) : null}
          <div className={s.drawerContent}>
            <h3></h3>
            <MenuList
              className={clsx(s.list, s.menuScrollable)}
              subheader={<ListSubheader>{t(Keys.admin.invitations.messages_label)}</ListSubheader>}
            >
              {sortedTemplates.map(template => (
                <MenuItem
                  key={template.id}
                  selected={selectedID === template.id}
                  onClick={() => closeDrawer(setSelectedId(template.id))}
                >
                  <span>{template.name}</span>
                </MenuItem>
              ))}
            </MenuList>

            <MenuList
              className={s.list}
              subheader={<ListSubheader>{t(Keys.admin.invitations.create_label)}</ListSubheader>}
            >
              <MenuItem onClick={onNewInvitation}>
                {t(keys => keys.admin.invitations.create_invitation)}
              </MenuItem>
              <MenuItem onClick={onNewEmptyMessage}>
                {t(keys => keys.admin.invitations.create_message)}
              </MenuItem>
              <Divider />
            </MenuList>
            <div className={s.tip}>
              <p dangerouslySetInnerHTML={{ __html: t(keys => keys.admin.invitations.tip) }}></p>
            </div>
          </div>
        </div>
        {drawerOpen ? <div className={s.drawerBackdrop}></div> : null}
        {template ? (
          <MessageTemplateEditor
            className={s.editor}
            template={template}
            key={template.id}
            onCreate={(id: IMessageTemplate['id']) => setSelectedId(id)}
          />
        ) : null}
      </div>
    </>
  );
};
