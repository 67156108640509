export const hasLinkInText = (text: string): boolean => {
  text = text.toLowerCase();
  return text.indexOf('((link))') !== -1 || text.indexOf('((link))') !== -1;
};

export const getMaxLength = (text: string): number => {
  const maxLength =
    120 * 5 - // 5 text messages
    20; // buffer for substituted name

  if (!hasLinkInText(text)) {
    return (
      maxLength -
      13 - // Leading text inserted before link
      30 // The link: https://invii.me/m/xxxx/xxxx
    );
  }

  return maxLength;
};
