import { IconButton } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { Flex } from '#root/Components/Flex';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { CloseIcon } from '#root/utils/icons';

export const SU: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const suDate = useStoreState(state => state.party.party?.suDate);
  const t = useTranslation();

  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={getService('translation').getLocale()}
          >
            <Flex align="center" gap>
              <DatePicker
                label={t(keys => keys.party.blocks.su.label)}
                value={suDate ? parseISO(suDate) : null}
                onChange={newDate => {
                  if (newDate && !isNaN(newDate.getTime())) {
                    getService('party').updateField('suDate', newDate.toISOString());
                  }
                }}
              />
              {suDate ? (
                <IconButton
                  color="error"
                  onClick={() => getService('party').updateField('suDate', null)}
                  title={t(keys => keys.generic.clear)}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Flex>
          </LocalizationProvider>
        ) : suDate ? (
          <div className={s.root}>
            {t(keys => keys.party.blocks.su.label)}: {format(parseISO(suDate), 'dd/MM/yyyy')}
          </div>
        ) : null
      }
    </BaseBlock>
  );
};
