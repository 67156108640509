import { Button, ButtonProps, IconButton, IconButtonProps } from '@mui/material';
import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { Loading } from '../Loading';

export interface IProps {
  onClick?: () => Promise<any>;
  disabled?: boolean;
  loading?: boolean;
}

export const LoadingButton: FC<PropsWithChildren<IProps> & ButtonProps> = ({
  children,
  onClick,
  loading: parentLoading,
  ...rest
}) => {
  const [loading, setLoading] = useState(parentLoading);

  if (parentLoading !== undefined && onClick !== undefined) {
    console.error('You should not be using parentLoading and onClick on the same time.');
  }

  useEffect(() => {
    if (parentLoading !== undefined && parentLoading !== loading) {
      setLoading(parentLoading);
    }
  }, [parentLoading]);

  const handle = useCallback(() => {
    if (onClick) {
      setLoading(true);
      onClick().finally(() => {
        setLoading(false);
      });
    }
  }, [onClick]);

  return (
    <Button disableElevation onClick={onClick ? handle : undefined} disabled={loading} {...rest}>
      {loading ? <Loading size="small" color="inherit" /> : children}
    </Button>
  );
};

export const LoadingIconButton: FC<PropsWithChildren<IProps> & IconButtonProps> = ({
  children,
  onClick,
  loading: parentLoading,
  ...rest
}) => {
  const [loading, setLoading] = useState(parentLoading);

  if (parentLoading !== undefined && onClick !== undefined) {
    console.error('You should not be using parentLoading and onClick on the same time.');
  }

  useEffect(() => {
    if (parentLoading !== undefined && parentLoading !== loading) {
      setLoading(parentLoading);
    }
  }, [parentLoading]);

  const handle = useCallback(() => {
    if (onClick) {
      setLoading(true);
      onClick().finally(() => {
        setLoading(false);
      });
    }
  }, [onClick]);

  return (
    <IconButton onClick={onClick ? handle : undefined} disabled={loading} {...rest}>
      {loading ? <Loading size="small" color={rest.color as any} /> : children}
    </IconButton>
  );
};
