import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { WysiwygField } from '#root/Components/WysiwygField';
import { getService, useStoreState } from '#root/store';

export const Description: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const description = useStoreState(state => state.party.party?.description);

  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <WysiwygField
            value={description ?? ''}
            toolbarOptions="simple"
            onChange={html => getService('party').updateField('description', html)}
          />
        ) : (
          <>
            {description ? (
              <div className={s.description} dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
          </>
        )
      }
    </BaseBlock>
  );
};
