import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from 'react-use';

export const usePopup = (name: string, onReceiveMessage: (event: MessageEvent<any>) => void) => {
  const windowObjectReference = useRef<Window | null>();
  const previousUrl = useRef<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(
    (url: string) => {
      // window features
      const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

      if (!windowObjectReference.current || windowObjectReference.current.closed) {
        /* if the pointer to the window object in memory does not exist
            or if such pointer exists but the window was closed */
        windowObjectReference.current = window.open(url, name, strWindowFeatures);
      } else if (previousUrl.current !== url) {
        /* if the resource to load is different,
            then we load it in the already opened secondary window and then
            we bring such window back on top/in front of its parent window. */
        windowObjectReference.current = window.open(url, name, strWindowFeatures);
        windowObjectReference.current?.focus();
      } else {
        /* else the window reference must exist and the window
            is not closed; therefore, we can bring it back on top of any other
            window with the focus() method. There would be no need to re-create
            the window or to reload the referenced resource. */
        windowObjectReference.current.focus();
      }

      previousUrl.current = url;
      setIsOpen(true);
    },
    [name, onReceiveMessage]
  );

  useEffect(() => {
    if (isOpen) {
      // Add the listener for receiving a message from the popup
      const handler = onReceiveMessage;
      window.addEventListener('message', handler, false);
      return () => {
        window.removeEventListener('message', handler);
      };
    }
  }, [isOpen, onReceiveMessage]);

  const close = useCallback(() => {
    windowObjectReference.current?.close();
    windowObjectReference.current = undefined;
    window.removeEventListener('message', onReceiveMessage);
    setIsOpen(false);
  }, [onReceiveMessage]);

  useEffect(() => {
    return () => {
      windowObjectReference.current?.close();
    };
  }, []);

  useInterval(() => {
    if (windowObjectReference.current?.closed) {
      windowObjectReference.current = undefined;
      window.removeEventListener('message', onReceiveMessage);
      setIsOpen(false);
    }
  }, 1000);
  return { open, close, isOpen };
};
