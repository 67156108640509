import { useCallback, useRef } from 'react';

export function useTrackInputFocus() {
  const hasFocus = useRef(false);
  const onFocus = useCallback(() => (hasFocus.current = true), [hasFocus]);
  const onBlur = useCallback(() => (hasFocus.current = false), [hasFocus]);

  return {
    hasFocus,
    field: {
      onFocus,
      onBlur,
    },
  };
}
