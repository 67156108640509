import { useLanguage } from './use-language';

const languageToCurrencyMap = {
  da: 'dkk',
  en: 'eur',
  default: 'dkk',
};

export const useDefaultCurrency = () => {
  const language = useLanguage();

  return languageToCurrencyMap[language] ?? languageToCurrencyMap.default;
};
