import { TextField } from '@mui/material';
import React, { FC } from 'react';

import { IPartyWizardInput } from '.';

export interface IProps {
  name: 'name' | 'childName' | 'year' | 'nameOnSpouse';
  required?: boolean;
}

export const Text: FC<IPartyWizardInput & IProps> = ({ control, name, required }) => {
  return (
    <span className="Wizard_Input">
      <TextField variant="standard" inputProps={control.register(name, { required })} />
    </span>
  );
};
