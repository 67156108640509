import { endOfDay, isAfter, parseISO, startOfDay } from 'date-fns';

import { useStoreState } from '#root/store';

export const useIsRsvpActive = (): true | 'afterSu' | false => {
  const rsvpEnabled = useStoreState(state => state.party.party?.rsvpEnabled);
  const rsvpDeadlineEnabled = useStoreState(state => state.party.party?.rsvpDeadlineEnabled);
  const suDate = useStoreState(state => state.party.party?.suDate);
  const date = useStoreState(state => state.party.party?.date);

  if (!rsvpEnabled) {
    return false;
  }

  if (rsvpDeadlineEnabled && suDate) {
    const transformedSuDate = parseISO(suDate);
    if (isAfter(new Date(), endOfDay(transformedSuDate))) {
      return 'afterSu';
    }
  }

  if (date) {
    const transformedDate = parseISO(date);

    if (isAfter(new Date(), startOfDay(transformedDate))) {
      return false;
    }
  }

  return true;
};
