import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';

export const ThemePicker: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const themeName = useStoreState(state => state.party.party?.themeName);
  const t = useTranslation();
  return (
    <BaseBlock editable className={className}>
      {({ editable }) =>
        editable ? (
          <FormControl size="small" color="secondary">
            <InputLabel>{t(Keys.party.blocks.theme.label)}</InputLabel>
            <Select
              color="secondary"
              size="small"
              value={themeName}
              label={t(Keys.party.blocks.theme.label)}
              onChange={e => getService('party').updateField('themeName', e.target.value)}
            >
              <MenuItem value="legacyDefault">{t(Keys.party.blocks.theme.legacy.label)}</MenuItem>
              <MenuItem value="default">{t(Keys.party.blocks.theme.default.label)}</MenuItem>
            </Select>
          </FormControl>
        ) : null
      }
    </BaseBlock>
  );
};
