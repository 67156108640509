import { Button, List, MenuItem, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { DropdownMenu } from '../DropdownMenu';

import {
  AllPartyTypes,
  ISpamField,
  partyCreator,
  PartyTypes,
  TypeBackgrounds,
  Types,
} from './Types';
import s from './index.module.scss';

import { Loading } from '#root/Components/Loading';
import { useNavigate } from '#root/hooks/use-navigate';
import { useTitle } from '#root/hooks/use-title';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { CaretDownIcon } from '#root/utils/icons';

export interface IProps {
  type: PartyTypes;
}

export const PartyWizard: FC<IProps> = ({ type }) => {
  const t = useTranslation();
  useTitle(t(Keys.wizard.title, { type: t(Keys.type[type]) }));
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<AllPartyTypes & ISpamField>({
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<AllPartyTypes & ISpamField> = async ({ zip, ...data }) => {
    if (submitting || zip || !isValid) return;

    setSubmitting(true);
    setTimeout(() => {
      getService('party').createNew(partyCreator({ ...data, type }));
      navigate(`/new/${type}/edit`);
    }, 100);
  };

  const Type = Types[type];
  const backgroundImage = `url(${TypeBackgrounds[type]})`;

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={s.invitationForm}
      style={{ backgroundImage }}
      autoComplete="off"
    >
      <div className={s.inner}>
        <div className={s.paper}>
          <div className={s.typePicker}>
            <DropdownMenu
              buttonText={
                <div className={s.menuButton}>
                  <span>{t(Keys.wizard.change_type)}</span>
                  <CaretDownIcon />
                </div>
              }
              ButtonProps={{ variant: 'text', color: 'secondary' }}
            >
              {({ closeDropdown }) => (
                <List>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/party'))}>
                    {t(Keys.type.party)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/birthday'))}>
                    {t(Keys.type.birthday)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/wedding'))}>
                    {t(Keys.type.wedding)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/baptism'))}>
                    {t(Keys.type.baptism)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/confirmation'))}>
                    {t(Keys.type.confirmation)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/anniversary'))}>
                    {t(Keys.type.anniversary)}
                  </MenuItem>
                  <MenuItem onClick={() => closeDropdown(navigate('/new/christmas'))}>
                    {t(Keys.type.christmas)}
                  </MenuItem>
                </List>
              )}
            </DropdownMenu>
          </div>
          <Type control={control} type={type} />
          <Button
            className={s.submit}
            disableElevation
            variant="contained"
            type="submit"
            disabled={!isValid || submitting}
          >
            {submitting ? <Loading size="small" /> : t(keys => keys.generic.create)}
          </Button>
          <TextField
            inputProps={register('zip')}
            style={{ visibility: 'hidden', height: 0 }}
            fullWidth={true}
            autoComplete="off"
            tabIndex={-1}
          />
        </div>

        <p className={s.changeThis}>{t(Keys.wizard.change_this)}</p>
      </div>
    </form>
  );
};
