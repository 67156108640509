import { AutoTextSize } from '@oldnox/auto-text-size';
import clsx from 'clsx';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Actions } from '../../Blocks/Actions';
import { Body } from '../../Blocks/Body';
import { DateTime } from '../../Blocks/DateTime';
import { Footer } from '../../Blocks/Footer';
import { HeroImage } from '../../Blocks/HeroImage';
import { Location } from '../../Blocks/Location';
import { Rsvp } from '../../Blocks/Rsvp';
import { SU } from '../../Blocks/SU';
import { ThemePicker } from '../../Blocks/ThemePicker';
import { Title } from '../../Blocks/Title';

import s from './index.module.scss';

import { useStoreState } from '#root/store';

export const Default: FC = () => {
  const editModeIsActive = useStoreState(state => state.party.editMode);
  return (
    <>
      <HeroImage className={s.background}>
        {() => (
          <div className={s.scrollableContainer}>
            <div className={s.container}>
              <div className={clsx(s.grid, editModeIsActive && s.editable)}>
                <div className={s.main}>
                  <DateTime className={s.dateMobile} />
                  <Title className={s.title}>
                    {title => (
                      <div className={s.theTitle}>
                        <AutoTextSize mode="multiline" minFontSizePx={20} maxFontSizePx={70}>
                          {title}
                        </AutoTextSize>
                      </div>
                    )}
                  </Title>
                  <Body className={s.body} />
                </div>
                <div className={s.sidebar}>
                  <DateTime className={s.dateDesktop} />
                  <Actions className={s.actions} />
                  <Rsvp className={s.rsvp} />
                  <SU className={s.suDate} />
                </div>
                <Location className={s.location} mapClassName={s.locationMap} />
              </div>
              <ThemePicker className={s.themePicker} />
            </div>
            <Footer className={s.footer} />
          </div>
        )}
      </HeroImage>
      <Outlet />
    </>
  );
};
