import { Button, DialogContent, TextField } from '@mui/material';
import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useNavigate } from '#root/hooks/use-navigate';
import { useTranslation } from '#root/hooks/use-translation';
import { getService } from '#root/store';
import { Keys } from '#root/translations-keys';
import { api } from '#root/utils/api';
import { css } from '#root/utils/css';

interface Form {
  field: string;
}

export const RemoveUser: FC<{ onClose: () => void }> = ({ onClose }) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<Form>();

  const onSubmit: SubmitHandler<Form> = () => {
    if (submitting) return;

    setMessage(undefined);
    setSubmitting(true);

    api
      .delete(`/api/user`)
      .then(() => {
        getService('app').unloadUser();
        navigate('/');
      })
      .catch(() => {
        setMessage(t(Keys.generic.error_try_again));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const disabled = submitting || !isValid;

  return (
    <DialogContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>{t(Keys.page.account.remove_user.title)}</h2>
        <p className={css.preWrap}>{t(Keys.page.account.remove_user.description)}</p>
        <TextField
          type="text"
          variant="outlined"
          margin="normal"
          color="error"
          error
          fullWidth
          label={t(Keys.page.account.remove_user.textfield_label)}
          autoComplete="off"
          autoFocus
          inputProps={register('field', {
            required: true,
            validate: {
              isCorrect: val =>
                val.toLowerCase() === t(Keys.page.account.remove_user.textfield_value),
            },
          })}
        />
        {message ? <p>{message}</p> : null}
        <Flex justify="space-between" className={css.spaceTopL}>
          <Button variant="outlined" color="secondary" disableElevation onClick={onClose}>
            {t(Keys.generic.cancel)}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            disabled={disabled}
            disableElevation
          >
            {submitting ? <Loading color="error" /> : t(Keys.page.account.remove_user.confirm)}
          </Button>
        </Flex>
      </form>
    </DialogContent>
  );
};
