import { useLocation } from 'react-router-dom';

import { useStoreState } from '#root/store';
import { idParamIsParty } from '#root/utils/idParamIsParty';

type PageType = 'new' | 'wizard' | 'admin' | 'party' | 'party-public' | 'unknown';

export const usePageType = (): PageType => {
  const location = useLocation();
  const canEdit = useStoreState(state => state.party.canEdit);
  const id = useStoreState(state => state.party.party?.id);

  const [, idOrLang, path] = location.pathname.split('/');

  if (idOrLang && idParamIsParty(idOrLang)) {
    if (path?.length && !['wishlist', 'photos', 'cohost'].includes(path)) {
      return 'admin';
    }
    return canEdit ? 'party' : 'party-public';
  }

  if (path === 'new') {
    return id === 'new' ? 'new' : 'wizard';
  }

  return 'unknown';
};
